import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from 'redux/store';
import { Provider } from 'react-redux';
import { fetchConfiguration } from 'redux/configurationSlice';
import { fetchLocations } from 'redux/locationsSlice';
import { authUser } from 'redux/userSlice';
import App from './App';
import { reportWebVitals, serviceWorkerRegistration } from 'utils';

// start loading initial auth then config/location list
store.dispatch(authUser()).then(({ type, payload }) => {
  let token = null;
  if (type === 'user/auth/fulfilled' && payload.token) {
    token = payload.token;
  }
  store.dispatch(fetchConfiguration(token));
  store.dispatch(fetchLocations(token));
});


const root = ReactDOM.createRoot(document.getElementById('root'));
document.title=process.env.REACT_APP_PROJECT_TITLE;
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
