import { FormattedMessage } from "react-intl";
import { CircularProgress, Stack, Typography } from "@mui/material";


export default function LoadingData(props) {
    const { noText, size, sx } = props;
    return <Stack direction="row" spacing={1} sx={sx}>
        <CircularProgress size={size ? size + 'rem' : '1.5rem'} />
        {!noText
            ? <Typography>
                <FormattedMessage id="LOADING_DATA" />
            </Typography>
            : null
        }
    </Stack>;
}