import { DefaultLayout, PublicLayout, EmptyState } from "layouts";
import { IncidentsFormView, SingleIncidentView, IncidentsTable, LoginRequired } from "components";
import { useSelector } from "react-redux";
import { userSelector, permissionSelector } from "redux/userSlice";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

function IncidentsPage({ details, edit, form }) {

    const incidentId = useParams().incidentId;
    const viewIncidentPermission = useSelector((state) => permissionSelector(state, 'view-report'));
    const createIncidentPermission = useSelector((state) => permissionSelector(state, 'create-report'));
    const updateIncidentPermission = useSelector((state) => permissionSelector(state, 'update-report'));


    if (!incidentId && !form && viewIncidentPermission) return <EmptyState component={<IncidentsTable />} />
    else if (!incidentId && form && createIncidentPermission) return <EmptyState component={<IncidentsFormView />} />
    else if (incidentId && edit && updateIncidentPermission) return <EmptyState component={<IncidentsFormView incidentId={incidentId} />} />
    else if (incidentId && details && viewIncidentPermission) return <EmptyState component={<SingleIncidentView incidentId={incidentId} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}


export default function IncidentsPageComponent({ details, edit, form }) {
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<IncidentsPage details={details} edit={edit} form={form} />} />;
    else return <PublicLayout page={<LoginRequired />} />;
};