import { Box, DialogTitle, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";


export default function DialogFormTitle({ mainTitle, subTitle, action, disableHelpText }) {
    return <DialogTitle>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {mainTitle}
                {subTitle && <Box sx={{
                    display: 'inline-block',
                    color: 'text.secondary',
                    verticalAlign: 'bottom',
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: 200
                }}>
                    {subTitle}
                </Box>}
                {!disableHelpText && <Typography variant='caption' sx={{ display: "block", color: "warning.main" }}><FormattedMessage id="REQUIRED.FIELDS" /></Typography>}
            </Box>
            {action}
        </Box>
    </DialogTitle>
}