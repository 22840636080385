import { Autocomplete, Stack, CardContent, Collapse, FormControl, FormHelperText, FormLabel, MenuItem, TextField, Typography, Select, Box, Chip, Checkbox, InputLabel, Divider, Card, CardActions, InputAdornment, IconButton, Badge, Avatar, Tooltip, Alert } from "@mui/material";
import { LocationInputField, FreeMultipleForm, DisplayResourceName, ThresholdDetailsView, LoadingData, CustomRadioGroup } from "components";
import { useSelector } from "react-redux";
import { selectGroup, selectSuperGroups, selectGroupsMeasurePoints } from "redux/locationsSlice";
import {
    selectIncidentCategoryTypesConf,
    selectRemedialActionTypesConf,
    selectPointOfComplianceTypeConf,
    selectIncidentExceedanceCauseConf,
    selectControlTypesConf,
    selectRestrictionTypesConf,
    selectRepeatedSamplingPlaceConf,
    selectIncidentNotificationTypes,
    selectResourcesTypesConf,
    selectImportResourceTypes
} from 'redux/configurationSlice';
import { FormattedMessage, useIntl } from "react-intl";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale";
import { useCallback, useRef } from "react";
import { AddOutlined, ArrowUpwardOutlined, DeleteOutline, ShuffleOutlined } from "@mui/icons-material";
import { intervalToDays } from "utils";
import { organizationAdminSelector } from "redux/userSlice";

const MeasurementCard = ({ disabled, secondary, inputRef, index, measurement, measurementUnit, measurementTimestamp, setMeasurementValue, setMeasurementTimestamp }) => {
    const intl = useIntl();
    return <Badge badgeContent={secondary && !disabled ? <span onClick={() => {
        setMeasurementValue(undefined, index)
        setMeasurementTimestamp(undefined, index)
    }}>
        <Tooltip title={<FormattedMessage id="FORM.DELETE_MEASUREMENT" />} placement="top-end" arrow>
            <Avatar component="span" sx={{ bgcolor: "error.main", width: 24, height: 24, cursor: 'pointer', '&:hover': { bgcolor: 'error.light' } }} size="small"><DeleteOutline fontSize="small" /></Avatar>
        </Tooltip>
    </span> : null}>
        <Card variant="outlined" sx={{ width: '100%' }}>
            <CardActions>
                <FormControl fullWidth>
                    <TextField inputRef={inputRef} disabled={disabled} label={<FormattedMessage id="VALUE" />} size="small" value={measurement} onChange={e => setMeasurementValue(e.target.value, index)} InputProps={{
                        endAdornment: <InputAdornment position="end">{measurementUnit}</InputAdornment>,
                    }} />
                </FormControl>
            </CardActions>
            <CardActions>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                        <DateTimePicker
                            disabled={disabled}
                            label={intl.formatMessage({ id: "SAMPLING_LOG_REPORT.DATE" })}
                            value={measurementTimestamp[index]}
                            onChange={(value) => setMeasurementTimestamp(value, index)}
                            renderInput={(params) => <TextField {...params} size='small' fullWidth variant="outlined" />}
                        />
                    </LocalizationProvider>
                </FormControl>
            </CardActions>
            {(measurement?.toString().trim() === '' || measurementTimestamp[index] === null || isNaN(new Date(measurementTimestamp[index]).getTime())) && <FormHelperText sx={{ pl: 2, color: "warning.main" }}><FormattedMessage id="MEASUREMENT_TOOLTIP" /></FormHelperText>}
        </Card>
    </Badge>
}

const DividerComponent = ({ children }) => <Divider variant="middle" sx={{ alignItems: 'center' }}>{children}</Divider>

export default function IncidentsFormContent(props) {
    const {
        incidentId,
        registryId,
        analysisId,
        incidentDescription,
        waterSupplier,
        zone,
        compliantZoneFlag,
        loading,
        settlements,
        selectedSettlements,
        objectsInvolved,
        selectedControlType,
        selectedIncidentCat,
        selectedIncidentCause,
        selectedRemedialAction,
        remedialActionDateRange,
        restrictionFlag,
        selectedRestrictionType,
        restrictionDateRange,
        selectedNotificationMethods,
        repeatedSamplingPlaceFlag,
        repeatedSamplePlace,
        populationInformed,
        notificationText,
        notificationLink,
        incidentEndDate,
        measurement,
        measurementValue,
        initialValuesLen,
        measurementTimestamp,
        derogation,
        assesmentFlag,
        samplingLocation,
        // samplesPerYear,
        samplingLocationType,
        locationToggle,
        location,
        locationType,
        samplingDate,
        setRegistryId,
        setAnalysisId,
        setIncidentDescription,
        setCompliantZoneFlag,
        setObjectsInvolved,
        setSelectedControlType,
        setSelectedIncidentCat,
        setSelectedIncidentCause,
        setSelectedRemedialAction,
        setSelectedRestrictionType,
        setRestrictionFlag,
        setSelectedNotificationMethods,
        setRepeatedSamplingPlaceFlag,
        setRepeatedSamplePlace,
        setPopulationInformed,
        setNotificationText,
        setNotificationLink,
        setIncidentEndDate,
        setMeasurement,
        addNewMeasurement,
        setMeasurementValue,
        setMeasurementTimestamp,
        setAssesmentFlag,
        setSamplingLocation,
        // setSamplesPerYear,
        setSamplingLocationType,
        setLocationToggle,
        setLocation,
        setSamplingDate,
        onWaterSupplyChange,
        onZoneChange,
        onSettlementChange,
        changeRemedialActionDateFrom,
        changeRemedialActionDateTo,
        changeRestrictionDateFrom,
        changeRestrictionDateTo,
        incidentYear,
    } = props;

    const intl = useIntl();
    const measurementInputRef = useRef(null);
    const measurementValueInputRef = useRef(null);

    const locationGroups = useSelector(selectSuperGroups);
    const zones = useSelector(selectGroup);
    const locations = useSelector((state) => selectGroupsMeasurePoints(state, zone?._id));
    const incidentCategories = useSelector(selectIncidentCategoryTypesConf);
    const remedialActions = useSelector(selectRemedialActionTypesConf);
    const pointOfComplianceTypes = useSelector(selectPointOfComplianceTypeConf);
    const incidentExceedanceCause = useSelector(selectIncidentExceedanceCauseConf);
    const controlTypes = useSelector(selectControlTypesConf);
    const restrictionTypes = useSelector(selectRestrictionTypesConf);
    const notificationMethods = useSelector(selectIncidentNotificationTypes);
    const repeatedSamplingPlaces = useSelector(selectRepeatedSamplingPlaceConf);
    const allResourceTypes = useSelector(selectResourcesTypesConf);
    const importResourceTypes = useSelector(selectImportResourceTypes);
    const organizationAdmin = useSelector(organizationAdminSelector);

    const resourceTypes = allResourceTypes.filter(el => importResourceTypes.includes(el.key));

    const renderDerogationDisplay = useCallback(() => {
        if (zone === null) return <Typography color="text.disabled"><FormattedMessage id="SELECT_ZONE_FOR_DEROGATION" /></Typography>
        if (derogation === null) return <Typography color="text.disabled"><FormattedMessage id="NONE_CONFIGURED" /></Typography>;
        else if (derogation.loading) return <LoadingData />
        else return <Card variant="outlined" sx={{ pt: 2 }} ><ThresholdDetailsView data={derogation} noParamName /></Card>
    }, [derogation, zone]);

    const restrictionFlagHandler = (stringValue) => {
        let value = stringValue === "true";
        setRestrictionFlag(value);
        if (!value) {
            setSelectedRestrictionType('');
            changeRestrictionDateTo();
        }
        changeRestrictionDateFrom(new Date().getTime());
    }

    const scrollToMeasurementInput = () => {
        if (measurementInputRef !== null) {
            measurementInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            measurementInputRef.current.focus({ preventScroll: true });
        }
    }
    const scrollToMeasurementValueInput = () => {
        if (measurementValueInputRef !== null) {
            measurementValueInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            measurementValueInputRef.current.focus({ preventScroll: true });
        }
    }

    const getRandomId = () => {
        let sequence = '';
        for (let i = 0; i < 10; i++) {
            const randomDigit = Math.floor(Math.random() * 10);
            sequence += randomDigit;
        }
        setRegistryId(sequence);
    }

    const getCountInfoText = useCallback(() => {

        if (settlements?.error) return <Alert sx={{ my: 0.5, mb: 1.5 }} severity="error"><FormattedMessage id="ERROR.FETCHING_SETTLEMENTS" /></Alert>;

        const notDefinedDeliveredWaterSettlements = settlements.every(el => !el.deliveredWaterQuantity?.populationEconomyDay);
        const totalPopulation = settlements.reduce((partialSum, settlement) => partialSum + settlement.population, 0);
        const partialPopulation = selectedSettlements.reduce((partialSum, settlement) => partialSum + settlement.population, 0);
        const totalWaterConsumption = notDefinedDeliveredWaterSettlements ? zone?.attributes.deliveredWater || 0 : settlements.reduce((partialSum, settlement) => partialSum + (settlement.deliveredWaterQuantity?.populationEconomyDay ?? 0), 0).toFixed(3);
        const partialWaterConsumption = selectedSettlements.reduce((partialSum, settlement) => partialSum + (settlement.deliveredWaterQuantity?.populationEconomyDay ?? 0), 0).toFixed(3);

        return selectedSettlements.length || settlements.length ? <Stack direction="column">
            <FormHelperText key={compliantZoneFlag + " - population"} sx={theme => ({ pb: 1.5, fontSize: theme.typography.fontSize * 1.5 })}>
                <FormattedMessage id="TOTAL_POPULATION_IMPACTED_HELPERTEXT" /><Typography display="inline" component="span" variant="inherit" color="primary">
                    {compliantZoneFlag === '1' ? partialPopulation : totalPopulation}
                </Typography>
            </FormHelperText>
            <FormHelperText key={compliantZoneFlag + " - water consumption"} sx={theme => ({ pb: 1.5, fontSize: theme.typography.fontSize * 1.5 })}>
                <FormattedMessage id="WATER_CONSUMPTION_HELPERTEXT" /><Typography display="inline" component="span" variant="inherit" color="primary">
                    {compliantZoneFlag === '1' ? partialWaterConsumption : totalWaterConsumption} <Typography component="span" color="text.secondary">m³</Typography>
                </Typography>
            </FormHelperText>
        </Stack> : <FormHelperText sx={{ pb: 1.5 }}><FormattedMessage id="TOTAL_POPULATION_IMPACTED_HELPERTEXT_NO_DATA" /></FormHelperText>

    }, [compliantZoneFlag, settlements, selectedSettlements, zone]);

    return <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        <FormControl fullWidth>
            <TextField
                size="small"
                inputProps={{ maxLength: 10 }}
                label={<FormattedMessage id="INCIDENT.REGISTRYID" />}
                value={registryId} onChange={(e) => setRegistryId(e.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{waterSupplier?.attributes?.OIB ? waterSupplier.attributes.OIB : "OIB"} / {incidentYear || new Date().getFullYear()} / </InputAdornment>,
                    endAdornment: <InputAdornment position="end"><Chip onClick={getRandomId} size="small" color="info" variant="outlined" icon={<ShuffleOutlined />} label={<FormattedMessage id="RANDOM" />} /></InputAdornment>
                }}
            />
        </FormControl>
        {/* *****GEOGRAPHIC_INFORMATION***** */}
        <DividerComponent><Typography color="primary"><FormattedMessage id="GEOGRAPHIC_INFORMATION" /></Typography></DividerComponent>
        <LocationInputField required={true} disabled={Boolean(incidentId)} key="water-suppliers" simpleLabels options={locationGroups} selectedLocation={waterSupplier} setSelectedLocation={onWaterSupplyChange} size="small" label={<FormattedMessage id="WATER_SUPPLIER" />} />
        <Collapse in={waterSupplier !== null}>

            <FormControl fullWidth>
                <LocationInputField simpleLabels required={true} disabled={Boolean(incidentId)} key="zones" options={zones.filter(location => waterSupplier !== null ? location.superGroupId === waterSupplier._id && location.type === 'zone' : true)} selectedLocation={zone} setSelectedLocation={onZoneChange} size="small" label={<FormattedMessage id="ZONE" />} />
                {zone?.attributes?.county && <FormHelperText><FormattedMessage id="COUNTY" />: <Typography variant="inherit" color="primary" component="span" display="inline">{zone?.attributes?.county || intl.formatMessage({ id: "CURRENTLY_NOT_AVAILABLE" })}</Typography></FormHelperText>}
            </FormControl>

            <Collapse in={zone !== null}>
                <FormControl fullWidth sx={{ pt: 1 }}>
                    <FormLabel><FormattedMessage id="INCIDENT_REACH.LABEL" /></FormLabel>
                    <CustomRadioGroup value={compliantZoneFlag} onChange={setCompliantZoneFlag} options={[
                        { value: "0", label: <FormattedMessage id="INCIDENT_REACH.TOTAL" /> },
                        { value: "1", label: <FormattedMessage id="INCIDENT_REACH.SELECTIVE" /> }
                    ]} />
                </FormControl>
                {!settlements.error && <Collapse in={compliantZoneFlag === '1'}>
                    <LocationInputField simpleLabels loading={loading} placeholder={intl.formatMessage({ id: "SELECT_SETTLEMENTS" })} key="settlements" options={settlements} multiple disableCloseOnSelect selectedLocation={selectedSettlements} setSelectedLocation={onSettlementChange} size="small" label="" />
                </Collapse>}
                {loading ? <LoadingData sx={{ py: 1 }} /> : getCountInfoText()}
                <FormControl fullWidth>
                    <FreeMultipleForm placeholder={intl.formatMessage({ id: "OBJECTS_INVOLVED.PLACEHOLDER" })} label={<FormattedMessage id="OBJECTS_INVOLVED" />} value={objectsInvolved} recommendedValues={[]} onChange={setObjectsInvolved} />
                </FormControl>
            </Collapse>
        </Collapse>

        {/* *****MEASUREMENT***** */}
        <DividerComponent><Typography color="primary"><FormattedMessage id="MEASUREMENT" /></Typography></DividerComponent>
        <FormControl fullWidth >
            <TextField size="small" label={<><FormattedMessage id="ANALYSIS_ID" /> *</>} value={analysisId} onChange={(e) => setAnalysisId(e.target.value)} />
        </FormControl>
        <FormControl fullWidth>
            <Autocomplete
                size="small"
                options={resourceTypes}
                disabled={Boolean(incidentId)}
                getOptionLabel={(resource) => `${intl.formatMessage({ id: resource.name })} (${intl.formatMessage({ id: "RESOURCE_TYPE.GROUP." + resource.resourceTypeGroupId.name })})`}
                value={measurement}
                onChange={(e, measurementType) => setMeasurement(measurementType)}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField inputRef={measurementInputRef} {...params} label={<FormattedMessage id="TYPE_OF_MEASUREMENTS" />} />
                )}
                renderOption={(props, option) => (<Box sx={{ display: 'flex', flexWrap: 'wrap' }}{...props}><DisplayResourceName resource={option} />&nbsp;{`(${intl.formatMessage({ id: "RESOURCE_TYPE.GROUP." + option.resourceTypeGroupId.name })})`}</Box>)}
                fullWidth
            />
            <FormHelperText></FormHelperText>
        </FormControl>
        <Collapse in={measurement !== null}>
            <FormLabel><FormattedMessage id="INCIDENT.VALUES" /></FormLabel>
            <Stack direction="column" gap={1.5}>
                <MeasurementCard
                    inputRef={measurementValueInputRef}
                    disabled={Boolean(incidentId && !organizationAdmin)}
                    measurement={measurementValue[0]} index={0}
                    measurementTimestamp={measurementTimestamp}
                    measurementUnit={measurement?.unit}
                    setMeasurementValue={setMeasurementValue} setMeasurementTimestamp={setMeasurementTimestamp}
                />
                <FormControl fullWidth>
                    <FormLabel><FormattedMessage id="DEROGATION" /></FormLabel>
                    {renderDerogationDisplay()}
                </FormControl>
            </Stack>
        </Collapse>
        <FormControl fullWidth>
            <FormLabel><FormattedMessage id="INCIDENT.LOCATION_TYPE" /></FormLabel>
            <CustomRadioGroup disabled={Boolean(incidentId)} value={locationToggle} onChange={setLocationToggle} options={[
                { value: "1", label: <FormattedMessage id="INCIDENT.LOCATION_TYPE.SUPPLIER" /> },
                { value: "0", label: <FormattedMessage id="INCIDENT.LOCATION_TYPE.CONSUMER" /> },
            ]} />
        </FormControl>
        <FormControl fullWidth>
            {locationToggle === "1"
                ? <LocationInputField
                    required simpleLabels
                    disabled={Boolean(incidentId)}
                    helpertext={measurement && location && !location.resourceTypes?.includes(measurement.key) ? <Box component="span"><FormattedMessage id="INCIDENT.PLACEHOLDER.P1" /><Box color="warning.main" textTransform="uppercase" component="span"><FormattedMessage id={measurement.name} /></Box><FormattedMessage id="INCIDENT.PLACEHOLDER.P2" /></Box> : null}
                    label={intl.formatMessage({ id: 'LOCATION' })}
                    noOptionsText={!zone ? intl.formatMessage({ id: "SELECT_ZONE_FOR_LOCATIONS" }) : undefined}
                    options={locations} selectedLocation={location}
                    setSelectedLocation={setLocation}
                    size="small"
                />
                : <TextField required disabled={Boolean(incidentId)} label={<FormattedMessage id="SAMPLING_LOCATION" />} size="small" value={samplingLocation} onChange={e => setSamplingLocation(e.target.value)} />
            }
        </FormControl>
        {locationToggle === "1"
            ? <FormControl fullWidth>
                <FormLabel><FormattedMessage id="LOCATION.TYPE" /></FormLabel>
                <Typography sx={theme => ({ fontSize: theme.typography.fontSize * 1.4 })} color="secondary.dark"><FormattedMessage id={locationType ? ("LOCATION_TYPE." + locationType.value) : 'UNDEFINED'} /></Typography>
            </FormControl>
            : <FormControl fullWidth>
                <InputLabel size="small"><FormattedMessage id="POINT_OF_COMPLIANCE_TYPE" /></InputLabel>
                <Select
                    size="small"
                    value={samplingLocationType}
                    label={intl.formatMessage({ id: "POINT_OF_COMPLIANCE_TYPE" })}
                    onChange={(e) => setSamplingLocationType(e.target.value)}
                >
                    {pointOfComplianceTypes.map(el => <MenuItem key={el.key} value={el.key}><FormattedMessage id={"REPORT.POINT_OF_COMPLIANCE_TYPE." + el.value} /></MenuItem>)}
                </Select>
            </FormControl>
        }
        <FormControl fullWidth>
            <FormLabel><FormattedMessage id="REGISTRY_ASSESMENT_FLAG" /></FormLabel>
            <CustomRadioGroup value={assesmentFlag} onChange={setAssesmentFlag} options={[
                { value: "1", label: <FormattedMessage id="REGISTRY_ASSESMENT_FLAG.YES" /> },
                { value: "0", label: <FormattedMessage id="REGISTRY_ASSESMENT_FLAG.NO" /> },
                { value: "", label: <FormattedMessage id="REGISTRY_ASSESMENT_FLAG.UNKNOWN" /> },
            ]} />
        </FormControl>
        {/* {!locationType && <FormControl fullWidth>
            <TextField label={<FormattedMessage id="SAMPLES_PER_YEAR" />} size="small" value={samplesPerYear} onChange={e => setSamplesPerYear(e.target.value)} />
        </FormControl>} */}
        <FormControl fullWidth>
            <TextField
                select
                size="small"
                label={<FormattedMessage id="CONTROL_TYPE" />}
                value={selectedControlType}
                onChange={(e) => setSelectedControlType(e.target.value)}
            >
                {controlTypes.map(el => <MenuItem key={el.key} value={el.key}><FormattedMessage id={el.value} /></MenuItem>)}
            </TextField>
        </FormControl>
        <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                <DateTimePicker
                    disabled={Boolean(incidentId)}
                    label={intl.formatMessage({ id: "TIMESTAMP.INCIDENT" })}
                    value={samplingDate}
                    onChange={setSamplingDate}
                    renderInput={(params) => <TextField {...params} size='small' fullWidth />}
                />
            </LocalizationProvider>
        </FormControl>

        {/* *****INCIDENT_INFORMATION***** */}
        <DividerComponent><Typography color="primary"><FormattedMessage id="INCIDENT_INFORMATION" /></Typography></DividerComponent>
        <FormControl fullWidth required={true}>
            <InputLabel size="small"><FormattedMessage id="INCIDENT_CATEGORY" /></InputLabel>
            <Select
                multiple
                size="small"
                value={selectedIncidentCat}
                label={intl.formatMessage({ id: "INCIDENT_CATEGORY" })}
                onChange={(e) => setSelectedIncidentCat(e.target.value)}
                renderValue={(selected) => {
                    return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip size="small" key={value} label={<FormattedMessage id={"REPORT.INCIDENT_CATEGORY." + incidentCategories.find(el => el.key === value)?.value} />} />
                        ))}
                    </Box>
                }}
            >
                {incidentCategories.map(el => <MenuItem key={el.key} value={el.key}><Checkbox size="small" checked={selectedIncidentCat.indexOf(el.key) > -1} /><FormattedMessage id={"REPORT.INCIDENT_CATEGORY." + el.value} /></MenuItem>)}
            </Select>
        </FormControl>

        <FormControl fullWidth>
            <TextField
                select
                size="small"
                label={`${intl.formatMessage({ id: "INCIDENT_EXCEEDANCE_CAUSE" })} *`}
                value={selectedIncidentCause}
                onChange={(e) => setSelectedIncidentCause(e.target.value)}
            >
                {incidentExceedanceCause.map(el => <MenuItem key={el.key} value={el.key}><FormattedMessage id={"REPORT.EXCEEDANCE_CAUSE." + el.value} /></MenuItem>)}
            </TextField>
        </FormControl>
        <FormControl fullWidth required={true}>
            <InputLabel size="small"><FormattedMessage id="REMEDIAL_ACTION.TYPE" /></InputLabel>
            <Select
                multiple
                size="small"
                value={selectedRemedialAction}
                onChange={(e) => setSelectedRemedialAction(e.target.value)}
                label={intl.formatMessage({ id: "REMEDIAL_ACTION.TYPE" })}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip size="small" key={value} label={<FormattedMessage id={"REPORT.REMEDIAL_ACTION." + remedialActions.find(el => el.key === value)?.value} />} />
                        ))}
                    </Box>
                )}
            >
                {remedialActions.map(el => <MenuItem key={el.key} value={el.key}><Checkbox size="small" checked={selectedRemedialAction.indexOf(el.key) > -1} /><FormattedMessage id={"REPORT.REMEDIAL_ACTION." + el.value} /></MenuItem>)}
            </Select>
        </FormControl>
        <Stack direction="row" gap={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                <DateTimePicker
                    label={selectedRemedialAction.includes(13) ? intl.formatMessage({ id: "REMEDIAL_ACTION.STARTED" }) : `${intl.formatMessage({ id: "REMEDIAL_ACTION.STARTED" })} *`}
                    value={remedialActionDateRange.from}
                    onChange={changeRemedialActionDateFrom}
                    renderInput={(params) => <TextField {...params} size='small' fullWidth />}
                />
                <DateTimePicker
                    label={intl.formatMessage({ id: "REMEDIAL_ACTION.ENDED" })}
                    value={remedialActionDateRange.to}
                    onChange={changeRemedialActionDateTo}
                    renderInput={(params) => <TextField {...params} size='small' fullWidth />}
                />
            </LocalizationProvider>
        </Stack>

        {/* *****NOTIFICATION_INFORMATION***** */}
        <DividerComponent><Typography color="primary"><FormattedMessage id="NOTIFICATION_INFORMATION" /></Typography></DividerComponent>
        <FormControl fullWidth>
            <FormLabel><FormattedMessage id="POPULATION_INFORMED.LABEL" /></FormLabel>
            <CustomRadioGroup value={populationInformed} onChange={setPopulationInformed} options={[
                { value: "1", label: <FormattedMessage id="POPULATION_INFORMED.YES" /> },
                { value: "0", label: <FormattedMessage id="POPULATION_INFORMED.NO" /> },
            ]} />
        </FormControl>
        <Collapse in={populationInformed === '1'}>
            <Stack direction="column" gap={1.5}>
                <FormControl fullWidth>
                    <Autocomplete
                        multiple
                        options={notificationMethods}
                        value={selectedNotificationMethods}
                        onChange={(e, val) => setSelectedNotificationMethods(val)}
                        getOptionLabel={option => intl.formatMessage({ id: "NOTIFICATION_OPTION." + option.value })}
                        renderOption={(props, option) => <li {...props} key={option.key}><FormattedMessage id={"NOTIFICATION_OPTION." + option.value} /></li>}
                        renderInput={(params) => <TextField {...params} fullWidth size="small" label={<FormattedMessage id="NOTIFICATION.METHODS" />} />}
                        size="small"
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField size="small" label={<FormattedMessage id="NOTIFICATION.TEXT" />} multiline maxRows={4} value={notificationText} onChange={(e) => setNotificationText(e.target.value)} />
                </FormControl>

                <FormControl fullWidth>
                    <TextField size="small" label={<FormattedMessage id="NOTIFICATION.LINK" />} value={notificationLink} onChange={(e) => setNotificationLink(e.target.value)} />
                </FormControl>

                <FormControl fullWidth>
                    <FormLabel><FormattedMessage id="RESTRICTION_FLAG" /></FormLabel>
                    <CustomRadioGroup value={restrictionFlag} onChange={restrictionFlagHandler} options={[
                        { value: true, label: <FormattedMessage id="RESTRICTION_FLAG.YES" /> },
                        { value: false, label: <FormattedMessage id="RESTRICTION_FLAG.NO" /> },
                    ]} />
                </FormControl>
                <Collapse in={restrictionFlag}>
                    <Stack direction="column" gap={1.5}>
                        <FormControl fullWidth>
                            <TextField
                                select
                                multiple
                                size="small"
                                label={<FormattedMessage id="RESTRICTION.TYPE" />}
                                value={selectedRestrictionType}
                                onChange={(e) => setSelectedRestrictionType(e.target.value)}
                            >
                                {restrictionTypes.map(el => <MenuItem key={el.key} value={el.key}><FormattedMessage id={el.value + ".SHORT"} /></MenuItem>)}
                            </TextField>
                        </FormControl>
                        <FormControl>
                            <Stack direction="row" gap={2}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                                    <DateTimePicker
                                        label={<FormattedMessage id="RESTRICTION.START" />}
                                        value={restrictionDateRange.from}
                                        onChange={changeRestrictionDateFrom}
                                        renderInput={(params) => <TextField {...params} size='small' fullWidth />}
                                    />
                                    <DateTimePicker
                                        label={<FormattedMessage id="RESTRICTION.END" />}
                                        value={restrictionDateRange.to}
                                        onChange={changeRestrictionDateTo}
                                        renderInput={(params) => <TextField {...params} size='small' fullWidth />}
                                    />
                                </LocalizationProvider>
                            </Stack>
                            <FormHelperText><FormattedMessage id="NUMBER_OF_DAYS_HELPERTEXT" /><Typography display="inline" component="span" variant="inherit" color={Boolean(restrictionDateRange.to) ? "primary" : "text.disabled"}>{Boolean(restrictionDateRange.from) && Boolean(restrictionDateRange.to) ? intervalToDays(restrictionDateRange) : <FormattedMessage id="UNDEFINED" />}</Typography></FormHelperText>
                        </FormControl>
                    </Stack>
                </Collapse>
            </Stack>
        </Collapse>
        <FormControl fullWidth>
            <FormLabel><FormattedMessage id="REPEATED_SAMPLE.LABEL" /></FormLabel>
            <CustomRadioGroup value={repeatedSamplingPlaceFlag} onChange={setRepeatedSamplingPlaceFlag} options={[
                { value: "1", label: <FormattedMessage id="REPEATED_SAMPLE.YES" /> },
                { value: "0", label: <FormattedMessage id="REPEATED_SAMPLE.NO" /> },
            ]} />
        </FormControl>
        <Collapse in={repeatedSamplingPlaceFlag === '1'}>
            <FormControl fullWidth sx={{ mb: 1.5 }}>
                <TextField
                    select
                    size="small"
                    label={<FormattedMessage id="REPEATED_SAMPLE.PLACE" />}
                    value={repeatedSamplePlace}
                    onChange={(e) => setRepeatedSamplePlace(e.target.value)}
                >
                    {repeatedSamplingPlaces.map(el => <MenuItem key={el.key} value={el.key}><FormattedMessage id={el.value} /></MenuItem>)}
                </TextField>
            </FormControl>
            <FormLabel><FormattedMessage id="REPEATED_SAMPLE" /></FormLabel>
            {measurement !== null
                ? (measurementValue[0] === '' && measurementValue.length <= 1
                    ? <Typography color="text.disabled" sx={{ mb: 1.5 }}><FormattedMessage id="ADD_INITIAL_MEASUREMENT" /> <IconButton onClick={scrollToMeasurementValueInput} size="small"><ArrowUpwardOutlined fontSize="small" /></IconButton></Typography>
                    : <Stack direction="column" gap={1.5} sx={{ mb: 1.5 }}>
                        {measurementValue.slice(1).map((measureValue, index) => <MeasurementCard
                            secondary
                            key={index + 1}
                            disabled={(measurementValue[0] === '' || ((index + 1) <= initialValuesLen)) && !organizationAdmin}
                            measurement={measureValue} index={index + 1}
                            measurementTimestamp={measurementTimestamp}
                            measurementUnit={measurement?.unit}
                            setMeasurementValue={setMeasurementValue} setMeasurementTimestamp={setMeasurementTimestamp}
                        />
                        )}
                        <Chip onClick={addNewMeasurement} label={<FormattedMessage id="FORM.ADD_MEASUREMENT" />} icon={<AddOutlined />} color="primary" variant="outlined" sx={{ alignSelf: 'flex-start' }} />
                    </Stack>)
                : <Typography color="text.disabled" sx={{ mb: 1.5 }}><FormattedMessage id="SELECT_MEASUREMENT_TYPE" /> <IconButton onClick={scrollToMeasurementInput} size="small"><ArrowUpwardOutlined fontSize="small" /></IconButton></Typography>}
        </Collapse>
        <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                <DateTimePicker
                    label={intl.formatMessage({ id: "INCIDENT_END_DATE" })}
                    value={incidentEndDate}
                    onChange={setIncidentEndDate}
                    renderInput={(params) => <TextField {...params} size='small' fullWidth />}
                />
            </LocalizationProvider>
        </FormControl>
        <FormControl fullWidth>
            <TextField size="small" label={<FormattedMessage id="DESCRIPTION" />} multiline rows={8} value={incidentDescription} onChange={(e) => setIncidentDescription(e.target.value)} />
        </FormControl>
    </CardContent>
}