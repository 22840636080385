import { EditOutlined, WaterDropOutlined } from "@mui/icons-material";
import { Avatar, Card, CardContent, CardHeader, CardMedia, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { permissionSelector } from "redux/userSlice";
import { LocationDetailsDialogForm } from 'components';
import { useState } from "react";

export default function LocationSuperGroupCard({ location }) {
    const groupAdmin = useSelector((state) => permissionSelector(state, 'update-supergroup'));
    const navigate = useNavigate();
    const { locale } = useIntl();
    const [openForm, setOpenForm] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    return <>
        <LocationDetailsDialogForm
            locationId={location._id}
            openFormDialog={openForm}
            handleCloseForm={() => setOpenForm(false)}
            successMsg={successMsg}
            setSuccessMsg={setSuccessMsg}
        />
        <Card sx={{ width: '100%' }}>
            <CardMedia
                component="img"
                height="250"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate("/locations/superGroup/" + location._id)}
                image={process.env.REACT_APP_API_BASE_URL + "/" + location.picture}
                alt="card-media"
            />
            <CardHeader
                title={<Typography variant={location.logo ? "h6" : "h5"}>{location.name}</Typography>}
                subheader={<Chip size="small" icon={<WaterDropOutlined />} label={<FormattedMessage id="WATER_SUPPLY_SYSTEM" />} />}
                avatar={location.logo && <Avatar src={process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + location.logo} />}
                action={
                    [
                        groupAdmin && groupAdmin.includes(location._id) ?
                            <Tooltip key="editLocationGroupTooltip" title={<FormattedMessage id="UPDATE" />} arrow>
                                <IconButton key="editLocationGroup" onClick={() => setOpenForm(true)} ><EditOutlined /></IconButton>
                            </Tooltip> : null
                    ]
                }
            />
            <CardContent>
                <Typography variant="body2">
                    {location.description[locale]}
                </Typography>
            </CardContent>
        </Card>
    </>
}