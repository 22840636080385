import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

const SimpleList = ({ data }) => {
    return (
        <List>
            {data.map((item, index) => (
                <ListItem
                    key={item._id ?? index}
                >
                    <ListItemText
                        primaryTypographyProps={{ variant: 'button' }}
                        primary={<span><Typography color="text.disabled" display="inline">{index + 1}.</Typography> {item.name}</span>}
                    />
                </ListItem>
            ))}
    </List>
    )
}

export default SimpleList;