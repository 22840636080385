import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { ThresholdsView, LoginRequired } from 'components';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { FormattedMessage } from 'react-intl';

function ThresholdsPage() {
    return <EmptyState component={<ThresholdsView />} />;
}

export default function ThresholdsPageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewThresholdPermission = useSelector((state) => permissionSelector(state, 'view-threshold'));


    if (loggedIn && viewThresholdPermission) return <DefaultLayout page={<ThresholdsPage />} />;
    else if (loggedIn) return <DefaultLayout page={<EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />} />;
    else return <PublicLayout page={<LoginRequired />} />;
};
