import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { selectLocations } from 'redux/locationsSlice'
import { userSelector, permissionSelector } from "redux/userSlice";
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { SettlementsView, LoginRequired } from "components";


function SettlementsPage(props) {
    const [searchparams] = useSearchParams();
    const groupId = searchparams.get("groupId");

    const group = useSelector((state) => selectLocations(state, groupId))

    if (!groupId) return <EmptyState component={<SettlementsView />} />;
    else if (group) return <EmptyState component={<SettlementsView group={group} superGroupId={group[0]?.superGroupId} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

export default function SettlementsPageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewSettlementPermission = useSelector((state) => permissionSelector(state, 'view-settlement'));


    if (loggedIn && viewSettlementPermission) return <DefaultLayout page={<SettlementsPage />} />;
    else if (loggedIn) return <DefaultLayout page={<EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />} />;
    else return <PublicLayout page={<LoginRequired />} />;
};