import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Card, CardHeader, CardMedia, CardContent, IconButton, Tooltip, Alert, AlertTitle, Stack } from "@mui/material";
import { MapOutlined, DescriptionOutlined, PhotoOutlined, InfoOutlined } from '@mui/icons-material';
import { LocationMapTab, DisplayBox } from 'components';
import { useSelector } from "react-redux";
import { selectLocations, selectSuperGroups } from "redux/locationsSlice";
import { selectAllWaterTypes, selectAllWaterIntakeTypes } from "redux/configurationSlice";

export default function LocationDetailsTabs(props) {
    const { location } = props;
    const { locale } = useIntl();
    const locationGroup = useSelector((state) => selectLocations(state, location?.locationGroupId))?.at(0);
    const waterType = useSelector(selectAllWaterTypes).find(type => type.key === location?.attributes?.waterType)?.value;
    const waterIntakeType = useSelector(selectAllWaterIntakeTypes).find(type => type.key === location?.attributes?.intakeType)?.value;
    const superGroup = useSelector((state) => selectSuperGroups(state, locationGroup?.superGroupId));
    const [content, setContent] = useState("map");
    const [poiInput, setPoiInput] = useState(false);

    const LocationInfo = () => <>
        { location?.attributes?.county && <DisplayBox label={<FormattedMessage id="COUNTY" />} value={location?.attributes?.county} /> }
        { waterIntakeType && <DisplayBox label={<FormattedMessage id="INTAKE_TYPE" />} value={<FormattedMessage id={"INTAKE_TYPE." + waterIntakeType} />} /> }
        { waterType && <DisplayBox label={<FormattedMessage id="WATER_TYPE" />} value={<FormattedMessage id={"WATER_TYPE." + waterType} />} /> }
        { location?.attributes?.depthIntake && <DisplayBox label={<FormattedMessage id="DEPTH_INTAKE" />} value={location?.attributes?.depthIntake} /> }
        { location?.attributes?.bounty && <DisplayBox label={<FormattedMessage id="BOUNTY" />} value={location?.attributes?.bounty} /> }
        { location?.attributes?.allowedQuantity && <DisplayBox label={<FormattedMessage id="ALLOWED_QUANTITY" />} value={location?.attributes?.allowedQuantity} /> }
        { location?.attributes?.realQuantity && <DisplayBox label={<FormattedMessage id="REAL_QUANTITY" />} value={location?.attributes?.realQuantity} /> }
        { location?.attributes?.permitNumber && <DisplayBox label={<FormattedMessage id="PERMIT_NUMBER" />} value={location?.attributes?.permitNumber} /> } 
    </>
    
    const renderContent = useCallback(() => {  
        switch (content) {
            case "desc":
                return <CardContent>{location.description[locale]}</CardContent>
            case "pic":
                return <CardMedia
                    component="img"
                    image={process.env.REACT_APP_API_BASE_URL + "/" + location.picture}
                    alt="location-picture"
                />
            case "map":
                return <Box>
                    <LocationMapTab location={location} poiInput={poiInput} setPoiInput={setPoiInput} />
                </Box>
            case "info":
                return <CardContent>
                    <Stack spacing={1}>
                        <LocationInfo />
                    </Stack>
                </CardContent>
            default:
                return null;
        }
    }, [content, location, locale, poiInput, setPoiInput]);


    return <Card sx={{ width: '100%' }}>
        {!poiInput ?
            <CardHeader title={location.name} subheader={<span>{superGroup?.name}{superGroup ? ": " : ""}{locationGroup?.name}</span>} action={[
                <Tooltip key="map" title={<FormattedMessage id="SHOW_POSITION" />} arrow>
                    <IconButton onClick={() => setContent("map")}>
                        <MapOutlined color={content === "map" ? "primary" : "default"} fontSize="small" />
                    </IconButton>
                </Tooltip>,
                <Tooltip key="picture" title={<FormattedMessage id="VIEW_PICTURE" />} arrow>
                    <IconButton onClick={() => setContent("pic")}>
                        <PhotoOutlined color={content === "pic" ? "primary" : "default"} fontSize="small" />
                    </IconButton>
                </Tooltip>,
                <Tooltip key="info" title={<FormattedMessage id="INFO" />} arrow>
                <IconButton onClick={() => setContent("info")}>
                    <InfoOutlined color={content === "info" ? "primary" : "default"} fontSize="small" />
                </IconButton>
                </Tooltip>,
                location.description[locale] && <Tooltip key="description" title={<FormattedMessage id="VIEW_DESCRIPTION" />} arrow>
                    <IconButton onClick={() => setContent("desc")}>
                        <DescriptionOutlined color={content === "desc" ? "primary" : "default"} fontSize="small" />
                    </IconButton>
                </Tooltip>
            ]} />
            : <Alert className="MuiCardHeader-root" severity="info" onClose={() => setPoiInput(false)}>
                <AlertTitle><FormattedMessage id="INFO" /></AlertTitle>
                <FormattedMessage id="ADD_POI" />
            </Alert>
        }
        {renderContent()}
    </Card>;
}