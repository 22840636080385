import { Typography, Stack, Divider, CardContent, Card } from "@mui/material";
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from "react-redux";
import { selectDerogationTypesConf, selectResourcesTypesConf } from "redux/configurationSlice";
import { formatDateLocale } from "utils";
import { selectLocationDetails, selectSuperGroups } from 'redux/locationsSlice';
import { DisplayBox, DisplayResourceName } from 'components';


const CustomText = (props) => <Stack direction="column">
    <Typography color='primary.main' variant='body2'><FormattedMessage id={props.messageId} /></Typography>
    {props.component ? props.component : <Typography variant='body1'>{props.text}</Typography>}
    </Stack>;


const ThresholdDetailsView = ({ data, noParamName }) => {
    const intl = useIntl();
    const location = useSelector((state) => selectLocationDetails(state, data.derogation?.locationId));
    const locationGroup = useSelector((state) => selectSuperGroups(state, location?.superGroupId));

    const resourceTypesConf = useSelector(selectResourcesTypesConf);
    const resourceType = resourceTypesConf.find(res => res.key === data.resourceTypeKey);

    const derogationTypesConf = useSelector(selectDerogationTypesConf);
    const derogationType = derogationTypesConf.find((derogation) => derogation.key === data?.derogation?.type)?.value;

    return (
        <CardContent sx={{ py: 0 }}>
            <Stack spacing={1} >
                {!noParamName && <DisplayBox
                    label={<FormattedMessage id="THRESHOLD.RESOURCE_TYPE" />}
                    value={<DisplayResourceName resource={resourceType} />}
                />}
                {(data?.min || data?.min === 0) && <DisplayBox label={<FormattedMessage id="THRESHOLD.MIN" />} value={`${data.min} ${resourceType.unit}`} />}
                {(data?.max || data?.max === 0) && <DisplayBox label={<FormattedMessage id="THRESHOLD.MAX" />} value={`${data.max} ${resourceType.unit}`} />}

                {data.derogation && <DisplayBox label={<FormattedMessage id="THRESHOLD.DEROGATION_GROUND" />} value={intl.formatMessage({ id: `DEROGATION.TYPE.${derogationType}` })} />}

                {data.derogation &&
                    <>
                        <Divider><Typography variant="body1" color="secondary.main"><FormattedMessage id="DETAILS" /></Typography></Divider>

                        <DisplayBox label={<FormattedMessage id="THRESHOLD.LEGAL_ENTITY" />} value={locationGroup?.name ?? data?.derogation?.legalEntity} />
                        <DisplayBox label={<FormattedMessage id="LOCATION" />} value={location?.name} />
                        <DisplayBox label={<FormattedMessage id="DESCRIPTION" />} value={data?.derogation?.description} />
                        
                        {data?.derogation?.note &&
                            <CustomText messageId="NOTE" component={
                                <Card variant='outlined' sx={{ p: 1, mt: 1, fontStyle: 'italic' }}>
                                    <Typography variant='body1'>{data.derogation.note}</Typography>
                                </Card>
                            } />
                        }
                        <Divider sx={{ mt: 1, mb: 1 }} />

                        <DisplayBox label={<FormattedMessage id="DATE_FROM" />} value={formatDateLocale(data?.derogation?.dateRange?.startDate)} />
                        <DisplayBox label={<FormattedMessage id="DATE_TO" />} value={formatDateLocale(data?.derogation?.dateRange?.endDate)} />
                    </>
                }
            </Stack>
        </CardContent>
    )
}

export default ThresholdDetailsView;