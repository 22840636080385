import { useState, useEffect, useCallback } from 'react'
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { selectAllLocations } from 'redux/locationsSlice';
import { selectResourcesTypesConf, selectStatusCodesConf, selectPermissionsByKey, selectDerogationTypesConf } from "redux/configurationSlice";
import { CustomDataGrid, LoadingData, AlertSnackbar, SideDrawer, ThresholdDetailsView, DisplayResourceName, ThresholdForm, ConfirmationDialog, ThresholdFilters } from 'components';
import { Typography, useMediaQuery, Alert, Stack, Card, CardContent, IconButton, Box, Chip, Grid, Tooltip } from "@mui/material";
import { useIntl, FormattedMessage } from 'react-intl';
import { AddOutlined, DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { formatDateLocale } from "utils";
import API from "api";

const ThresholdsView = (props) => {

    const resourceTypesConf = useSelector(selectResourcesTypesConf);
    const statusCodesConf = useSelector(selectStatusCodesConf).COMPLIANCE;
    const derogationTypesConf = useSelector(selectDerogationTypesConf);
    const locations = useSelector(selectAllLocations);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [thresholds, setThresholds] = useState([]);
    const [thresholdId, setThresholdId] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerDetails, setDrawerDetails] = useState({});
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [currentEditing, setCurrentEditing] = useState(null);
    const [reset, setReset] = useState(0);
    const [derogationFilter, setDerogationFilter] = useState(false);
    const [locationGroups, setSelectedLocationGroups] = useState([]);

    const { token } = useSelector(userSelector);
    const [loading, setLoading] = useState(false);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const intl = useIntl();
    const [alert, setAlert] = useState({ open: false });

    const permissionList = useSelector(selectPermissionsByKey);
    const createThreshold = permissionList && permissionList['create-threshold']?.length > 0;
    const updateThreshold = permissionList && permissionList['update-threshold']?.length > 0;
    const createDerogation = permissionList && permissionList['create-derogation']?.length > 0;
    const updateDerogation = permissionList && permissionList['update-derogation']?.length > 0;
    const deleteThreshold = permissionList && permissionList['delete-threshold']?.length > 0;
    const deleteDerogation = permissionList && permissionList['delete-derogation']?.length > 0;

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const handleDelete = () => {
        if (deleteThreshold && currentEditing) {
            API.thresholds.deleteThreshold(token, currentEditing?._id).then(response => {
                if (response.data) {
                    setAlert({ open: true, messageId: "APP.SUCCESS", severity: "success" });
                    setOpenDeleteDialog(false);
                    setCurrentEditing(null);
                    setReset(reset => reset + 1);
                }
            })
                .catch(error => {
                    setAlert({ open: true, messageId: error?.data?.id || "APP.ERROR", severity: "error" });
                    console.error(error);
                    setOpenDeleteDialog(false);
                    setCurrentEditing(null);
                })
        }
        else if (deleteDerogation && currentEditing) {
            API.thresholds.deleteDerogation(token, currentEditing?._id).then(response => {
                if (response.data) {
                    setAlert({ open: true, messageId: "APP.SUCCESS", severity: "success" });
                    setOpenDeleteDialog(false);
                    setCurrentEditing(null);
                    setReset(reset => reset + 1);
                }
            })
                .catch(error => {
                    setAlert({ open: true, messageId: error?.data?.id || "APP.ERROR", severity: "error" });
                    console.error(error);
                    setOpenDeleteDialog(false);
                    setCurrentEditing(null);
                })
        }
    }

    useEffect(() => {
        setLoading(true);
        API.thresholds.getAllThresholds(token, dateFrom, dateTo, derogationFilter, locationGroups.map(el => el._id)).then((response) => {
            const data = response.data.map((item) => {
                const resourceType = resourceTypesConf.find(res => res.key === item.resourceTypeKey);
                const derogationType = derogationTypesConf.find((derogation) => derogation.key === item?.derogation?.type)?.value;
                return {
                    ...item,
                    id: item._id,
                    resourceType: resourceType,
                    min: item.min || item.min === 0 ? `${item.min} ${resourceType?.unit}` : '',
                    max: item.max || item.max === 0 ? `${item.max} ${resourceType?.unit}` : '',
                    zone: locations.find(el => el._id === item.derogation?.locationId)?.name,
                    minimumAsNumber: item.min,
                    maximumAsNumber: item.max,
                    derogationGround: derogationType,
                    unit: resourceType?.unit,
                    description: item.derogation?.description,
                    note: item.derogation?.note,
                    derogationStart: formatDateLocale(item.derogation?.dateRange?.startDate),
                    derogationEnd: formatDateLocale(item.derogation?.dateRange?.endDate),
                    legalEntity: item.derogation?.legalEntity,
                }
            })
            setThresholds(data);
            setLoading(false);
        }).catch((error) => {
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            console.error('Thresholds error', error);
            setLoading(false);
        })
    }, [intl, token, resourceTypesConf, statusCodesConf, derogationTypesConf, locations, reset, dateFrom, dateTo, derogationFilter, locationGroups]);

    useEffect(() => {
        if (thresholdId) {
            setDrawerDetails({
                title: <Box ml={2}> <FormattedMessage id='THRESHOLDS' /> </Box>,
                display: <CardContent sx={{ py: 0 }}><LoadingData /></CardContent>
            })
            API.thresholds.getOneThreshold(token, thresholdId).then((response) => {
                setDrawerDetails({
                    title: <Box ml={2}> <FormattedMessage id='THRESHOLDS' /> </Box>,
                    subtitle: <Grid container justifyContent="flex-start" alignItems="flex-start">
                        <Chip
                            sx={{ width: 'auto', maxWidth: '100px', ml: 2, mt: 1 }}
                            size='small'
                            variant='filled'
                            color={response.data?.active === true ? 'success' : 'default'}
                            label={<Typography>{response.data?.active === true ? intl.formatMessage({ id: 'ACTIVE' }) : intl.formatMessage({ id: 'INACTIVE' })}</Typography>}
                        />
                    </Grid>,
                    display: <ThresholdDetailsView data={response.data} />
                })
            }).catch((error) => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                console.error('Threshold details error', error);
            })
        }

    }, [token, intl, thresholdId, resourceTypesConf]);

    const renderTable = useCallback(() => {
        const headers = [
            {
                field: 'resourceType',
                hideable: false,
                headerName: intl.formatMessage({ id: "THRESHOLD.RESOURCE_TYPE" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <DisplayResourceName resource={row.row.resourceType} />,
                valueGetter: (params) => `${intl.formatMessage({ id: params.value?.name })} [${params.value.unit}]`,
                minWidth: 300
            },
            {
                field: 'min',
                headerName: intl.formatMessage({ id: "THRESHOLD.MIN" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography color={row.value ? "default" : "text.disabled"}> {row.value || <FormattedMessage id="UNDEFINED" />} </Typography>,
                minWidth: 200
            },
            {
                field: 'max',
                headerName: intl.formatMessage({ id: "THRESHOLD.MAX" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography color={row.value ? "default" : "text.disabled"}> {row.value || <FormattedMessage id="UNDEFINED" />} </Typography>,
                minWidth: 200
            },
            {
                field: 'actions',
                headerName: intl.formatMessage({ id: 'ACTIONS' }),
                type: 'actions',
                minWidth: 200,
                getActions: (el) => [
                    <Box>
                        <Tooltip title={<FormattedMessage id="EDIT" />} placement="bottom" arrow>
                            <span><IconButton disabled={!updateThreshold && !updateDerogation} color="primary" onClick={() => { setOpenForm(true); setCurrentEditing(el.row) }}>
                                <EditOutlined />
                            </IconButton></span>
                        </Tooltip>
                    </Box>,
                    <Box>
                        <Tooltip title={<FormattedMessage id="DELETE" />} placement="bottom" arrow>
                            <span><IconButton disabled={!deleteThreshold && !deleteDerogation} color="error" onClick={() => { setOpenDeleteDialog(true); setCurrentEditing(el.row) }}>
                                <DeleteOutlined />
                            </IconButton></span>
                        </Tooltip>
                    </Box>,
                ]
            }
        ];
        if (derogationFilter) {
            headers.splice(0, 0, {
                field: 'zone',
                hideable: false,
                headerName: intl.formatMessage({ id: "ZONE" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.value} </Typography>,
                minWidth: 200
            });
            headers.splice(-1, 0, ...[{
                field: 'derogationGround',
                headerName: intl.formatMessage({ id: "DEROGATION.TYPE" }),
                ...(!smallScreen && { flex: 1 }),
                valueFormatter: (params) => params?.value,
                valueGetter: (row) => row.row.derogationGround !== undefined ? intl.formatMessage({ id: `DEROGATION.TYPE.${row.row.derogationGround}` }) : null,
                renderCell: (row) => { <Typography> {row.row.derogationGround !== undefined ? intl.formatMessage({ id: `DEROGATION.TYPE.${row.row.derogationGround}` }) : intl.formatMessage({ id: "NO_DATA" })} </Typography> },
                minWidth: 200
            },
            {
                field: 'description',
                sortable: false,
                headerName: intl.formatMessage({ id: "DESCRIPTION" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Tooltip title={row.row.description} arrow followCursor>
                    <Typography component="span" variant="caption" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontStyle: 'italic' }}> {row.row.description} </Typography>
                </Tooltip>,
                minWidth: 200
            },
            {
                field: 'note',
                sortable: false,
                headerName: intl.formatMessage({ id: "NOTE" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Tooltip title={row.row.note} arrow followCursor>
                    <Typography component="span" variant="caption" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontStyle: 'italic' }}> {row.row.note} </Typography>
                </Tooltip>,
                minWidth: 200
            },
            {
                field: 'derogationStart',
                headerName: intl.formatMessage({ id: "DATE_FROM" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.value} </Typography>,
                minWidth: 200
            },
            {
                field: 'derogationEnd',
                headerName: intl.formatMessage({ id: "DATE_TO" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.value} </Typography>,
                minWidth: 200
            },
            {
                field: 'legalEntity',
                headerName: intl.formatMessage({ id: "THRESHOLD.LEGAL_ENTITY" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.legalEntity} </Typography>,
                minWidth: 200
            }])
        }

        if (!loading && thresholds.length > 0) {
            return <CustomDataGrid
                rows={thresholds}
                columns={headers}
                enableExport
                handleData={(row) => { setOpenDrawer(true); setThresholdId(row.id) }}
            />
        } else if (!loading && thresholds.length < 0) {
            return <Alert severity="warning"><FormattedMessage id='NO_DATA' /></Alert>
        } else if (loading) return <LoadingData />
        else return <Alert severity="warning"><FormattedMessage id='NO_DATA_SELECTED_FILTER' /></Alert>
    }, [intl, thresholds, smallScreen, loading, derogationFilter, updateDerogation, updateThreshold, deleteDerogation, deleteThreshold])

    return (
        <>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <SideDrawer state={drawerDetails} open={openDrawer} toggleDrawer={() => { setOpenDrawer(false); setDrawerDetails({ title: '', display: null }); setThresholdId(null) }} />
            <ConfirmationDialog
                open={openDeleteDialog}
                title={!currentEditing ? null : <span><FormattedMessage id="CONFIRM_DELETE_FOR" /> <DisplayResourceName resource={currentEditing.resourceType} /></span>}
                content={<></>}
                handleCancel={() => { setOpenDeleteDialog(false); setCurrentEditing(null); }}
                handleCustomButton={() => handleDelete()}
                customButtonTitle={<FormattedMessage id="DELETE" />}
                customButtonColor='error'
            />

            <ThresholdForm open={openForm} onClose={() => setOpenForm(false)} existingThreshold={currentEditing} setAlert={setAlert} setReset={setReset} />
            <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                <ThresholdFilters derogation={derogationFilter} setDerogation={setDerogationFilter}
                    selectedLocationGroups={locationGroups} setSelectedLocationGroups={setSelectedLocationGroups}
                    dateFrom={dateFrom} setDateFrom={setDateFrom}
                    dateTo={dateTo} setDateTo={setDateTo}
                    action={<Chip disabled={!createThreshold && !createDerogation} variant='outlined' color='primary' onClick={() => { setOpenForm(true); setCurrentEditing(null) }}
                        icon={<AddOutlined fontSize="small" color="primary" />} label={<FormattedMessage id={smallScreen ? "ADD" : "THRESHOLD.ADD"} />} />}
                />
                <Card>
                    <CardContent>
                        {renderTable()}
                    </CardContent>
                </Card>
            </Stack>
        </>
    )
}

export default ThresholdsView;
