import configuration from './configurationAPI';
import auth from './authAPI';
import locations from './locationsAPI';
import measurements from './measurementsAPI';
import samplingLogs from './samplingLogsAPI';
import thresholds from './thresholdsAPI';
import keycloak from './keycloakAPI';
import alarm from './alarmAPI';
import alarmProfiles from './alarmProfilesAPI';
import mail from './mailAPI'
import poi from './poiAPI';
import alarms from './alarmsAPI';
import notes from './notesAPI';
import settlements from './settlementsAPI';
import incidents from './incidentsAPI';

const API = {
    configuration,
    auth,
    locations,
    measurements,
    samplingLogs,
    thresholds,
    keycloak,
    alarm,
    alarmProfiles,
    mail,
    poi,
    notes,
    alarms,
    settlements,
    incidents,
}

export default API;
