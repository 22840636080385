import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { fetchCount } from './counterAPI';
import API from 'api';

const initialState = {
  data: [],
  status: 'idle',
  error: null
};
const GROUPS = ["SERVICE", "SENSOR"];

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchConfiguration = createAsyncThunk('configuration/fetchConfiguration', async (token) => {
  const { getAllConfigurationData, getAllConfiguration } = API.configuration;
  const getConfiguration = token === null ? getAllConfiguration : getAllConfigurationData;
  const response = await getConfiguration(token);
  // The value we return becomes the `fulfilled` action payload
  return response.data;
}
);

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfiguration.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchConfiguration.rejected, (state) => {
        console.error("Failed to load configuration...");
        state.status = 'failed';
      })
      .addCase(fetchConfiguration.fulfilled, (state, action) => {
        state.status = 'complete';
        state.data = action.payload;
      });
  },
});

const reorderItems = (items) => {
  let newArray = [...items];
  if (items[0].name === "SERVICE") {
    const moveItem = newArray.shift();
    return newArray.concat(moveItem)
  }
  return newArray
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectResourcesTypesConf = (state) => state.configuration.data.resourceTypes;
export const selectResourceTypeGroupsConf = (state) => reorderItems(state.configuration.data.resourceTypeGroups);
export const selectImportResourceTypes = (state) => state.configuration.data.resourceTypeGroups.filter(el => !GROUPS.includes(el.name)).flatMap(el => el.resourceTypes);
export const selectResourceTypeTagsConf = (state) => state.configuration.data.resourceTypeTags;
export const selectTypologyConf = (state) => state.configuration.data.typology;
export const selectSamplingTypesConf = (state) => state.configuration.data.samplingTypes;
export const selectStatusCodesConf = (state) => {
  const statusCodeObjects = state.configuration.data.statusCodes.map(type => {
    const entries = Object.entries(type.key);
    return [type.value, entries.map(([key, value]) => ({ key: value, value: key }))];
  });
  return Object.fromEntries(new Map(statusCodeObjects));
};
export const selectStatusCodesTypesConf = (state) => state.configuration.data.statusCodesTypes;
export const selectSeverityTypesConf = (state) => state.configuration.data.severityTypes;
export const selectLocalizationConf = (state) => state.configuration.data.localization;
export const selectLocationNotesConf = (state) => state.configuration.data.locationNoteTypes;
export const selectNotificationMethodConf = (state) => state.configuration.data.notificationMethodTypes;
export const selectPoIConf = (state) => state.configuration.data.poiTypes;
export const selectPermissionsById = (state) => state.configuration.data.locationPermissionsById;
export const selectPermissionsByKey = (state) => state.configuration.data.locationPermissionsByKey;
export const selectStagingSamplesFeature = (state) => state.configuration.data.features ? state.configuration.data.features.stagingSamples : false;
export const selectDerogationTypesConf = (state) => state.configuration.data.derogationTypes;
export const selectPointOfComplianceTypeConf = (state) => state.configuration.data.pointOfComplianceType;
export const selectControlTypesConf = (state) => state.configuration.data.controlTypes;
export const selectIncidentCategoryTypesConf = (state) => state.configuration.data.incidentCategory;
export const selectIncidentExceedanceCauseConf = (state) => state.configuration.data.incidentExceedanceCause;
export const selectRemedialActionTypesConf = (state) => state.configuration.data.remedialAction;
export const selectRestrictionTypesConf = (state) => state.configuration.data.restrictionTypes;
export const selectRepeatedSamplingPlaceConf = (state) => state.configuration.data.repeatedSamplingPlace;
export const selectIncidentNotificationTypes = (state) => state.configuration.data.notificationMethodOptions;
export const selectAllWaterTypes = (state) => state.configuration.data.waterTypes;
export const selectAllWaterIntakeTypes = (state) => state.configuration.data.waterIntakeTypes;
export const selectAllCounties = (state) => state.configuration.data.counties

export default configurationSlice.reducer;