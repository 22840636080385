import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { Alert, Snackbar, SpeedDial, SpeedDialAction, SpeedDialIcon, Tooltip } from '@mui/material';
import { DomainDisabledOutlined, DomainOutlined, LayersClearOutlined, LayersOutlined, Settings, SettingsOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { LoadingData } from 'components';
import GeoJSON from 'ol/format/GeoJSON.js';
import API from 'api';


function MapGeoJSON(props) {
    const { geoJSONLayer, disabled, customIcons } = props;
    const user = useSelector(userSelector);
    const [settlements, setSettlements] = useState(null);
    const [disableSettlements, setDisableSettlements] = useState(true);
    const [supplyZones, setSupplyZones] = useState(null);
    const [disableSupplyZones, setDisableSupplyZones] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        geoJSONLayer.getSource().clear();
        if (!disabled) {
            setLoading(true);
            const geoFeatureApi = user.token ? API.locations.getAllGeoFeaturesData : API.locations.getAllGeoFeatures;
            geoFeatureApi(user.token)
                .then(({ data }) => {
                    setSettlements(data.filter(el => el.name === "settlements").map(el => new GeoJSON({ geometryName: 'settlements' }).readFeatures(el.geoFeature, { featureProjection: "EPSG:3857" })).flat());
                    setSupplyZones(data.filter(el => el.name === "supplyZone").map(el => new GeoJSON({ geometryName: 'supplyZones' }).readFeatures(el.geoFeature, { featureProjection: "EPSG:3857" })).flat());
                    setLoading(false);
                    setError(false);
                })
                .catch(error => {
                    console.error("Error fetching data for GeoJSON, reason: ", error);
                    setLoading(false);
                    setError(true);
                });
        }
    }, [disabled, user.token, geoJSONLayer]);

    useEffect(() => {
        geoJSONLayer.getSource().clear();
        if (!disableSettlements && settlements && !disableSupplyZones && supplyZones) {
            geoJSONLayer.getSource().addFeatures(settlements);
            geoJSONLayer.getSource().addFeatures(supplyZones);
            return;
        }

        if (!disableSettlements && settlements) {
            geoJSONLayer.getSource().addFeatures(settlements);
        }
        if (!disableSupplyZones && supplyZones) {
            geoJSONLayer.getSource().addFeatures(supplyZones);
        }

    }, [settlements, supplyZones, geoJSONLayer, disabled, disableSettlements, disableSupplyZones]);


    if (disabled) return <></>;
    else return <div className="map-control" style={{ display: 'inherit', position: 'relative' }}>
        {loading
            ? <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'right' }} sx={{ mt: 10, mr: 2, zIndex: 1 }}>
                <Alert icon={false} color='info'><LoadingData /></Alert>
            </Snackbar>
            : <Tooltip title={!error ? <FormattedMessage id="SLOW_DOWN_WARNING" /> : ""} placement="left-start">
                <SpeedDial hidden={loading} sx={{ position: 'absolute', top: 8, right: 8, '& .MuiFab-primary': { width: 50, height: 50 } }} ariaLabel="MapControls" direction="down" icon={<SpeedDialIcon openIcon={<Settings />} icon={<SettingsOutlined />} />}>
                    {!error && [<SpeedDialAction
                        onClick={() => setDisableSettlements(!disableSettlements)}
                        key={"toggle-settlements-geofeature"}
                        icon={!disableSettlements ? <DomainOutlined color="primary" /> : <DomainDisabledOutlined />}
                        tooltipTitle={<FormattedMessage id={"GEO_DATA.SETTLEMENTS." + (!disableSettlements ? "OFF" : "ON")} />}
                        tooltipPlacement="left"
                    />,
                    <SpeedDialAction
                        onClick={() => setDisableSupplyZones(!disableSupplyZones)}
                        key={"toggle-supplyZones-geofeature"}
                        icon={!disableSupplyZones ? <LayersOutlined color="primary" /> : <LayersClearOutlined />}
                        tooltipTitle={<FormattedMessage id={"GEO_DATA.SUPPLY_ZONES." + (!disableSupplyZones ? "OFF" : "ON")} />}
                        tooltipPlacement="left"
                    />]}
                    {customIcons}
                </SpeedDial>
            </Tooltip>
        }
    </div >;
}

export default React.memo(MapGeoJSON);