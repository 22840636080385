import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DateRangeSelector, LocationInputField } from 'components';
import { useSelector } from 'react-redux';
import { selectMeasurePoints } from 'redux/locationsSlice';

import { FormControlLabel, Checkbox, Card, CardHeader, CardContent, Stack, Grid, Typography, useMediaQuery } from '@mui/material';

function StagingSampleReportingFilters(props) {
    const {
        selectedLocations,
        filterHandler,
        pictureSample,
    } = props;
    const locations = useSelector(selectMeasurePoints);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    return <Card>
        <CardHeader title={<FormattedMessage id="STAGING_SAMPLES" />} />
        <CardContent>
            <Stack direction="column" spacing={2}>
                <DateRangeSelector column={smallScreen} />
                <Grid container direction="row">
                    <Grid item xl={4} lg={5} md={6} sm={12} xs={12}>
                        <LocationInputField
                            selectedLocation={selectedLocations}
                            setSelectedLocation={(location) => filterHandler(location.filter(mapedLocation => locations.map(filteredLocation => filteredLocation === mapedLocation)), 'locations')}
                            limitTags={3}
                            disableCloseOnSelect
                            filterSelectedOptions
                            multiple

                        />
                    </Grid>
                    <Grid item xl={4} lg={5} md={6} sm={12} xs={12}>
                        <FormControlLabel
                            sx={smallScreen ? { mt: 1 } : { ml: 1 }}
                            control={<Checkbox checked={pictureSample} onChange={() => filterHandler(!pictureSample, 'picture')} />}
                            label={<Typography variant="subtitle1" ><FormattedMessage id="CONTAINS_PICTURE" /></Typography>}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </CardContent>
    </Card >;
}

export default StagingSampleReportingFilters;