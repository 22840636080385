import { Autocomplete, Box, Checkbox, Chip, Collapse, Divider, FormControl, FormControlLabel, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { DisplayResourceName } from 'components';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectResourcesTypesConf, selectDerogationTypesConf, selectPermissionsByKey } from 'redux/configurationSlice';
import { selectLocations, selectSuperGroups } from 'redux/locationsSlice';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from "date-fns/locale";

const ThresholdFormContent = ({ selectedMeasurementType, setSelectedMeasurementType, minimum, setMinimum, maximum, setMaximum, description, setDescription, waterSupplier, setWaterSupplier, derogation, setDerogation, dateFrom, setDateFrom, dateTo, setDateTo, derogationType, setDerogationType, note, setNote }) => {
    const intl = useIntl();
    const resourceTypes = useSelector(selectResourcesTypesConf);
    const locationGroups = useSelector(selectLocations);
    const derogationTypes = useSelector(selectDerogationTypesConf);
    const superGroups = useSelector(selectSuperGroups);

    const permissionList = useSelector(selectPermissionsByKey);
    const createThreshold = permissionList && permissionList['create-threshold']?.length > 0;
    const updateThreshold = permissionList && permissionList['update-threshold']?.length > 0;

    return <Stack direction="column" spacing={2}>
        <FormControl>
            <Autocomplete
                size="small"
                limitTags={3}
                id="multiple-limit-tags"
                options={resourceTypes.filter(type => type.resourceTypeGroupId.name !== "SERVICE" && type.resourceTypeGroupId.name !== "SENSOR")}
                getOptionLabel={(resource) => `${intl.formatMessage({ id: resource.name })} (${intl.formatMessage({ id: "RESOURCE_TYPE.GROUP." + resource.resourceTypeGroupId.name })})`}
                value={selectedMeasurementType}
                onChange={(e, measurementType) => setSelectedMeasurementType(measurementType)}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField {...params} key={resourceTypes.map(type => type.name + type._id)} label={<FormattedMessage id="TYPE_OF_MEASUREMENTS" />} required />
                )}
                renderTags={(options, getTagProps) => options.map((opt, index) => (<Chip key={index} {...getTagProps({ index })} size="small" label={<Box><DisplayResourceName resource={opt} />{` (${intl.formatMessage({ id: "RESOURCE_TYPE.GROUP." + opt.resourceTypeGroupId.name })})`}</Box>} />))}
                renderOption={(props, option) => (<Box sx={{ display: 'flex', flexWrap: 'wrap' }}{...props}><DisplayResourceName resource={option} />&nbsp;{`(${intl.formatMessage({ id: "RESOURCE_TYPE.GROUP." + option.resourceTypeGroupId.name })})`}</Box>)}
                fullWidth
            />
        </FormControl>
        <FormControl>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="MINIMUM"
                type="number"
                size="small"
                value={minimum}
                onChange={(e) => setMinimum(e.target.value)}
                label={<FormattedMessage id="THRESHOLD.MIN" />}
            />
        </FormControl>
        <FormControl>
            <TextField
                required
                fullWidth
                variant="outlined"
                id="MAXIMUM"
                type="number"
                size="small"
                value={maximum}
                onChange={(e) => setMaximum(e.target.value)}
                label={<FormattedMessage id="THRESHOLD.MAX" />}
            />
        </FormControl>

        {(createThreshold || updateThreshold) && <FormControlLabel
            control={<Checkbox size="small" checked={derogation} onChange={() => setDerogation(!derogation)} />}
            label={<FormattedMessage id="THRESHOLD.DEROGATION_INPUT" />}
        />}
        
        {derogation && <Divider>
            <Typography color="primary"><FormattedMessage id="THRESHOLD.DEROGATION" /></Typography>
        </Divider>}
        <Collapse in={derogation}>
            <Stack direction="column" spacing={2}>
                <FormControl>
                    <Autocomplete
                        size="small"
                        id="derogationType"
                        options={derogationTypes}
                        getOptionLabel={(derogationType) => intl.formatMessage({ id: "DEROGATION.TYPE." + derogationType.value })}
                        renderInput={(params) => <TextField key={derogationTypes.map(derogationType => derogationType.key)} {...params} label={<FormattedMessage id="DEROGATION.TYPE" />} />}
                        value={derogationType}
                        onChange={(e, value) => setDerogationType(value)}
                        fullWidth
                    />
                    {derogationType && <FormHelperText>
                        <FormattedMessage id={"DEROGATION.TYPE.HELPER_TEXT." + derogationType.value} />
                    </FormHelperText>}
                </FormControl>
                <FormControl>
                    <Autocomplete
                        size="small"
                        id="zone"
                        options={locationGroups}
                        getOptionLabel={(locationGroup) => `${locationGroup.name} (${superGroups.find(el => el._id === locationGroup.superGroupId)?.name}- ${superGroups.find(el => el._id === locationGroup.superGroupId)?.typeId} ) `}
                        renderInput={(params) => <TextField key={locationGroups.map(locationGroup => locationGroup._id)} {...params} label={<FormattedMessage id="ZONE" />} />}
                        value={waterSupplier}
                        onChange={(e, value) => setWaterSupplier(value)}
                        fullWidth
                    />
                </FormControl>
                <FormControl >
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                        <DatePicker
                            value={dateFrom ? new Date(dateFrom) : null}
                            onChange={(date) => setDateFrom(date)}
                            renderInput={(params) => <TextField {...params} size='small' label={<FormattedMessage id="DATE_FROM" />} />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl >
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                        <DatePicker
                            value={dateTo ? new Date(dateTo) : null}
                            onChange={(date) => setDateTo(date)}
                            renderInput={(params) => <TextField {...params} size='small' label={<FormattedMessage id="DATE_TO" />} />}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl>
                    <TextField
                        fullWidth
                        multiline
                        rows="2"
                        id="DESCRIPTION"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        size="small"
                        label={<FormattedMessage id="DESCRIPTION" />}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        fullWidth
                        multiline
                        rows="2"
                        id="NOTE"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        size="small"
                        label={<FormattedMessage id="NOTE" />}
                    />
                </FormControl>
            </Stack>
        </Collapse>
    </Stack>
}

export default ThresholdFormContent;