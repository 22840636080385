import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { NewNoteDialog, NoteDetails, CustomDataGrid, SideDrawer, AlertSnackbar, LocationInputField, LoadingData } from 'components';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { useSelector } from 'react-redux';
import { formatDateLocale } from 'utils';
import { selectMeasurePoints } from 'redux/locationsSlice';
import { selectLocationNotesConf, selectNotificationMethodConf } from 'redux/configurationSlice';
import API from 'api';

import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Stack, useMediaQuery } from '@mui/material';
import { AddOutlined } from "@mui/icons-material";

export default function NotesView(props) {
    const { locationId } = props;
    const intl = useIntl();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
    const user = useSelector(userSelector);
    const noteTypesConfig = useSelector(selectLocationNotesConf);
    const notificationTypesConfig = useSelector(selectNotificationMethodConf);
    const locations = useSelector(selectMeasurePoints);
    const noteCreatorRights = useSelector((state) => permissionSelector(state, 'create-notes'));
    const noteArchiverRights = useSelector((state) => permissionSelector(state, 'update-notes'));
    const [open, setOpen] = useState(false);
    const [openDrawer, setDrawer] = useState(false);
    const [details, setDetails] = useState({});
    const [detailsNote, setDetailsNote] = useState(false);
    const { token } = useSelector(userSelector);
    const [notesData, setNotesData] = useState([]);
    const [noteObject, setNoteObject] = useState(null);
    const [noteUnarchiveObject, setNoteUnarchiveObject] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(locationId ? locations.find(loc => loc._id === locationId) : null);
    const [inputValue, setInputValue] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [archivedView, setArchivedView] = useState(false);
    const [archiveDisabled, setArchiveDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reset, setReset] = useState(0);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const archiveNote = () => {
        setArchiveDisabled(true)
        setNoteObject({
            noteIdArray: selectedRows.map(el => { return el.id })
        });
    }
    const unarchiveNote = () => {
        setArchiveDisabled(true);
        setNoteUnarchiveObject({
            noteIdArray: selectedRows.map(el => { return el.id })
        });
    }

    useEffect(() => {
        if (noteObject) API.notes.archiveNote(user.token, noteObject).then(() => {
            setAlert({ open: true, messageId: "APP.SUCCESS.ARCHIVE", severity: "success" });
            setArchiveDisabled(false);
        }).catch(error => {
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            console.error(`handleArchive, archive note error`, error);
            setArchiveDisabled(false);
        });
    }, [noteObject, user.token]);

    useEffect(() => {
        if (noteUnarchiveObject) API.notes.unarchiveNote(user.token, noteUnarchiveObject).then(() => {
            setAlert({ open: true, messageId: "APP.SUCCESS.UNARCHIVE", severity: "success" });
            setArchiveDisabled(false);
        }).catch(error => {
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            console.error(`handleArchive, archive note error`, error);
            setArchiveDisabled(false);
        });
    }, [noteUnarchiveObject, user.token]);

    useEffect(() => {
        setLoading(true);
        API.notes.getAllNotes(token, selectedLocation ? [selectedLocation._id] : undefined).then(items => {
            if (items.data) {
                const data = items.data.map(collection => {
                    const notificationTypeValues = collection.notificationMethod.map(el => notificationTypesConfig.find(type => type.key === el).value);
                    const noteTypeValue = noteTypesConfig.find(type => type.key === collection.type).value;
                    return {
                        id: collection._id,
                        username: collection.user,
                        location: locations.find(el => el._id === collection.locationId).name,
                        locationId: collection.locationId,
                        date: formatDateLocale(collection.timestamp),
                        notificationTypes: notificationTypeValues,
                        notificationTypesLocale: notificationTypeValues.map(el => intl.formatMessage({ id: "NOTIFICATION_OPTION." + el })),
                        noteType: noteTypeValue,
                        noteTypeLocale: intl.formatMessage({ id: "NOTE_OPTION." + noteTypeValue }),
                        title: collection.title,
                        text: collection.content,
                        groupLevel: collection.groupLevel,
                        archived: collection.archived,
                        sendFailed: collection.sendFailed
                    }
                })
                setNotesData(data);
            }
            else setNotesData([]);
            setLoading(false);
        }).catch(error => {
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            setNotesData([]);
            setLoading(false);
        })
    }, [token, selectedLocation, locations, notificationTypesConfig, noteTypesConfig, noteObject, noteUnarchiveObject, intl, reset]);

    const handleData = ({ row }, event) => {
        setDetailsNote(row)
        setDrawer(true);
    }

    const handleDialogClose = (alertData) => {
        setOpen(false);
        setDrawer(false);
        if (alertData) {
            setAlert(alertData);
        }
    }

    useEffect(() => {
        if (detailsNote) {
            const note = notesData.find(note => note.id === detailsNote.id);
            try {
                setDetails({
                    title: <FormattedMessage id='NOTE_DETAILS' />,
                    subtitle: note.date,
                    display: <NoteDetails data={note} reloadData={() => setReset(reset => reset + 1)} handleDialogClose={handleDialogClose}/>
                })
            } catch (err) {
                console.error(err);
                setAlert({ open: true, messageId: "ERROR.ERROR" });
                setDetails({
                    title: <FormattedMessage id='SAMPLING_LOG_REPORT.ERROR' />,
                    subtitle: '',
                    display: <div />
                });
            }
        }
    }, [notesData, detailsNote])

    const renderTable = useCallback(() => {
        const columns = [
            {
                field: 'username',
                headerName: intl.formatMessage({ id: 'USERNAME' }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            },
            {
                field: 'location',
                headerName: intl.formatMessage({ id: 'LOCATION' }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'title',
                headerName: intl.formatMessage({ id: 'NOTE_TITLE' }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'date',
                headerName: intl.formatMessage({ id: 'NOTE_DATE' }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'noteTypeLocale',
                headerName: intl.formatMessage({ id: 'NOTE_TYPE' }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            }
        ];
        if (loading) return <LoadingData />;
        else if (notesData?.length < 0) {
            return <Alert severity="warning"><FormattedMessage id='NO_DATA' /></Alert>
        }
        else return <CustomDataGrid
            enableExport
            columns={columns}
            setSelectedRows={setSelectedRows}
            handleData={handleData}
            rows={notesData.filter(el => archivedView ? el.archived === true : el.archived === false)}
        />;

    }, [intl, notesData, archivedView, smallScreen, loading]);

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <Card sx={{ width: '100%' }}>
                <CardHeader
                    title={<FormattedMessage id="NOTES" />}
                    subheader={archivedView ? <FormattedMessage id="ARCHIVED_NOTES" /> : null}
                    action={
                        <Stack direction={smallScreen ? "column" : "row"} spacing={1} sx={{ mt: smallScreen ? 2 : 0 }} alignItems="center" justifyContent="center">
                            <LocationInputField selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                freeSolo
                                sx={{ width: smallScreen ? '100%' : 300 }}
                            />
                            <div>
                                {noteCreatorRights && !archivedView ? <Chip onClick={() => setOpen(true)} label={<FormattedMessage id="ADD_NOTE" />} icon={<AddOutlined />} variant="outlined" color="primary" /> : null}
                                <Button sx={{ ml: 1 }} size="small" onClick={() => setArchivedView(!archivedView)}> <FormattedMessage id={archivedView ? "SHOW_UNARCHIVED" : "SHOW_ARCHIVED"} /> </Button>
                            </div>
                        </Stack>
                    }
                    sx={{ flexWrap: "wrap" }}
                />
                <CardContent>
                    {renderTable()}
                </CardContent>

                <CardActions sx={{ pl: 2 }}>
                    <Box>
                        {noteArchiverRights ? <Button disabled={selectedRows.length < 1 || archiveDisabled} onClick={() => archivedView ? unarchiveNote() : archiveNote()} variant="text"><FormattedMessage id={archivedView ? "UNARCHIVE" : "ARCHIVE"} /></Button> : null}
                    </Box>
                </CardActions>
            </Card>
        </Stack>
        <SideDrawer state={details} open={openDrawer} toggleDrawer={() => setDrawer(false)} />
        <NewNoteDialog key={selectedLocation} open={open} reloadData={() => setReset(reset => reset + 1)} handleDialogClose={handleDialogClose} locationId={selectedLocation && selectedLocation._id} />
    </>;
}