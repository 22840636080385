import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Container, Divider, Typography } from "@mui/material";
import { AlertSnackbar, LoadingData, ConfirmationDialog } from "components";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { FormattedMessage } from "react-intl";
import { intervalToDuration } from 'date-fns';
import { EditOutlined, WarningOutlined } from "@mui/icons-material";
import { selectSuperGroups, selectGroup, selectMeasurePoints } from "redux/locationsSlice";
import { selectResourcesTypesConf, selectIncidentNotificationTypes, selectTypologyConf } from "redux/configurationSlice";
import IncidentsFormContent from "./IncidentsFormContent";
import API from 'api';

const defaultObject = {
    registryId: '',
    analysisId: '',
    incidentDescription: '',
    waterSupplier: null,
    zone: null,
    compliantZoneFlag: "0",
    loading: false,
    settlements: [],
    selectedSettlements: [],
    objectsInvolved: [],
    selectedControlType: '',
    selectedIncidentCat: [],
    selectedIncidentCause: '',
    selectedRemedialAction: [],
    remedialActionDateRange: { from: null, to: null },
    restrictionFlag: false,
    selectedRestrictionType: '',
    restrictionDateRange: { from: new Date().getTime(), to: null },
    selectedNotificationMethods: [],
    repeatedSamplingPlaceFlag: "0",
    repeatedSamplePlace: '',
    populationInformed: "0",
    notificationText: '',
    notificationLink: '',
    incidentEndDate: null,
    measurement: null,
    measurementValue: [''],
    measurementTimestamp: [new Date().getTime()],
    assesmentFlag: "",
    samplingLocation: '',
    samplesPerYear: '',
    samplingLocationType: '',
    locationToggle: "0",
    location: null,
    locationType: null,
    samplingDate: null,
    incidentYear: '',
}

const formatDateObject = (dateObject) => {
    let from = new Date().getTime();
    let to = null;

    if (dateObject.dateStart) from = new Date(dateObject.dateStart).getTime();
    if (dateObject.dateEnd) to = new Date(dateObject.dateEnd).getTime();
    return { from: from, to: to };
}

export default function IncidentsFormView(props) {
    const navigate = useNavigate();
    // SELECTORS
    const { token } = useSelector(userSelector);
    const locationGroups = useSelector(selectSuperGroups);
    const zones = useSelector(selectGroup);
    const locations = useSelector(selectMeasurePoints);
    const allResourceTypes = useSelector(selectResourcesTypesConf);
    const notificationMethods = useSelector(selectIncidentNotificationTypes);
    const locationTypes = useSelector(selectTypologyConf);

    // STATE VARIABLES
    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [registryId, setRegistryId] = useState('');
    const [analysisId, setAnalysisId] = useState('');
    const [incidentDescription, setIncidentDescription] = useState('')
    const [waterSupplier, setWaterSupplier] = useState(null);
    const [zone, setZone] = useState(null);
    const [compliantZoneFlag, setCompliantZoneFlag] = useState("0");
    const [loading, setLoading] = useState(false);
    const [settlements, setSettlements] = useState([]);
    const [selectedSettlements, setSelectedSettlements] = useState([]);
    const [objectsInvolved, setObjectsInvolved] = useState([]);
    const [selectedControlType, setSelectedControlType] = useState('');
    const [selectedIncidentCat, setSelectedIncidentCat] = useState([]);
    const [selectedIncidentCause, setSelectedIncidentCause] = useState('');
    const [selectedRemedialAction, setSelectedRemedialAction] = useState([]);
    const [remedialActionDateRange, setRemedialActionDateRange] = useState({ from: null, to: null });
    const [restrictionFlag, setRestrictionFlag] = useState(false);
    const [selectedRestrictionType, setSelectedRestrictionType] = useState('');
    const [restrictionDateRange, setRestrictionDateRange] = useState({ from: new Date().getTime(), to: null });
    const [selectedNotificationMethods, setSelectedNotificationMethods] = useState([]);
    const [repeatedSamplingPlaceFlag, setRepeatedSamplingPlaceFlag] = useState("0");
    const [repeatedSamplePlace, setRepeatedSamplePlace] = useState('');
    const [populationInformed, setPopulationInformed] = useState("0");
    const [notificationText, setNotificationText] = useState('');
    const [notificationLink, setNotificationLink] = useState('');
    const [incidentEndDate, setIncidentEndDate] = useState(null);
    const [measurement, setMeasurement] = useState(null);
    const [measurementValue, setMeasurementValue] = useState(['']);
    const [initialValuesLen, setInitialValuesLen] = useState(0);
    const [measurementTimestamp, setMeasurementTimestamp] = useState([new Date().getTime()]);
    const [derogation, setDerogation] = useState(null);
    const [assesmentFlag, setAssesmentFlag] = useState('');
    const [samplingLocation, setSamplingLocation] = useState('');
    const [samplingLocationType, setSamplingLocationType] = useState('');
    const [locationToggle, setLocationToggle] = useState('0'); // 0 = samplingLocation, 1 = location
    const [location, setLocation] = useState(null);
    const [locationType, setLocationType] = useState(null);
    const [samplesPerYear, setSamplesPerYear] = useState('');
    const [samplingDate, setSamplingDate] = useState(null);
    const [incidentYear, setIncidentYear] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [globalLoading, setGlobalLoading] = useState(false);
    const [fetchError, setFetchError] = useState(null);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);

    useEffect(() => {
        if (locationToggle === "0") {
            setLocationType(null);
            setLocation(null);
        }
        else {
            setSamplingLocationType('');
            setSamplingLocation('');
        }
    }, [locationToggle]);

    const setValues = (obj) => {
        setRegistryId(obj.registryId)
        setAnalysisId(obj.analysisId);
        setIncidentDescription(obj.incidentDescription);
        setWaterSupplier(obj.waterSupplier);
        setZone(obj.zone);
        setCompliantZoneFlag(obj.compliantZoneFlag);
        setSelectedSettlements(obj.selectedSettlements);
        setObjectsInvolved(obj.objectsInvolved);
        setSelectedControlType(obj.selectedControlType);
        setSelectedIncidentCat(obj.selectedIncidentCat);
        setSelectedIncidentCause(obj.selectedIncidentCause);
        setSelectedRemedialAction(obj.selectedRemedialAction);
        setRemedialActionDateRange(obj.remedialActionDateRange);
        setRestrictionFlag(obj.restrictionFlag);
        setSelectedRestrictionType(obj.selectedRestrictionType);
        setRestrictionDateRange(obj.restrictionDateRange);
        setSelectedNotificationMethods(obj.selectedNotificationMethods);
        setRepeatedSamplingPlaceFlag(obj.repeatedSamplingPlaceFlag);
        setRepeatedSamplePlace(obj.repeatedSamplePlace);
        setPopulationInformed(obj.populationInformed);
        setNotificationText(obj.notificationText);
        setNotificationLink(obj.notificationLink);
        setIncidentEndDate(obj.incidentEndDate);
        setMeasurement(obj.measurement);
        setMeasurementValue(obj.measurementValue);
        setInitialValuesLen(obj.measurementValue.length - 1);
        setMeasurementTimestamp(obj.measurementTimestamp);
        setAssesmentFlag(obj.assesmentFlag);
        setSamplesPerYear(obj.samplesPerYear);
        setSamplingLocation(obj.samplingLocation);
        setSamplingLocationType(obj.samplingLocationType);
        setLocationToggle(obj.locationToggle);
        setLocation(obj.location);
        setLocationType(obj.locationType);
        setSamplingDate(obj.samplingDate);
        setIncidentYear(obj.incidentYear);
    };

    useEffect(() => {

        const getLocationTypeObject = (location, zoneId) => {
            let typeObject = { locationToggle: "", samplingLocation: '', location: null, samplingLocationType: '', locationType: null };

            if (location?.locationType !== undefined && location?.locationType !== null) {
                typeObject.location = locations.filter(el => el.locationGroupId === zoneId).find(el => el.name === location.name) || location.name;
                typeObject.locationType = locationTypes.find(el => el.key === location.locationType) || null;
                typeObject.locationToggle = "1";
            }
            else {
                typeObject.samplingLocation = location.name;
                typeObject.samplingLocationType = location.pointOfCompliance || '';
                typeObject.locationToggle = "0";
            }

            return typeObject;
        }

        const incidentId = props.incidentId;
        setFetchError(false);
        if (incidentId) {
            setGlobalLoading(true);
            API.incidents.getSingleIncident(token, incidentId).then(({ data }) => {
                const incident = data;
                const resource = allResourceTypes.find(el => el.key === incident.exceedance.resourceType);
                const zone = zones.find(el => el._id === incident.zone.id._id);
                const existingRegistryId = incident.registryId;
                setValues({
                    ...defaultObject,
                    ...incident,
                    registryId: existingRegistryId ? existingRegistryId.indexOf('/') !== -1 ? existingRegistryId.slice(existingRegistryId.lastIndexOf('/') + 1) : existingRegistryId : '',
                    analysisId: incident.exceedance.sampleMark || '',
                    incidentDescription: incident.nonComplianceReport.incidentDescription || '',
                    waterSupplier: locationGroups.find(el => el._id === incident.jivu._id),
                    zone: zone,
                    compliantZoneFlag: incident.zone.nonCompliantZone ? "1" : "0",
                    selectedSettlements: incident.zone.settlements || [],
                    objectsInvolved: incident.zone.objectsInvolved || [],
                    selectedControlType: incident.exceedance.controlType || '',
                    selectedIncidentCat: incident.nonComplianceReport.incidentCategory || '',
                    selectedIncidentCause: incident.nonComplianceReport.incidentCause || '',
                    selectedRemedialAction: incident.nonComplianceReport.remedialAction.remedialActionType || [],
                    ...(incident.nonComplianceReport.remedialAction.dateStart && { remedialActionDateRange: formatDateObject(incident.nonComplianceReport.remedialAction) }),
                    populationInformed: incident.nonComplianceReport.populationNotified ? "1" : "0",
                    restrictionFlag: Boolean(incident.nonComplianceReport.populationNotified?.restriction),
                    repeatedSamplingPlaceFlag: incident.nonComplianceReport.repeatedSampling ? "1" : "0",
                    repeatedSamplePlace: incident.nonComplianceReport.repeatedSamplingPlace || '',
                    ...(incident.nonComplianceReport.populationNotified && {
                        selectedRestrictionType: incident.nonComplianceReport.populationNotified.restriction?.description || '',
                        restrictionDateRange: incident.nonComplianceReport.populationNotified.restriction ? formatDateObject(incident.nonComplianceReport.populationNotified.restriction) : defaultObject.restrictionDateRange,
                        selectedNotificationMethods: incident.nonComplianceReport.populationNotified.notificationMethod?.map(no => notificationMethods.find(el => el.key === no)) || [],
                        notificationText: incident.nonComplianceReport.populationNotified.notification?.text || '',
                        notificationLink: incident.nonComplianceReport.populationNotified.notification?.link || ''
                    }),
                    incidentEndDate: incident.nonComplianceReport.nonComplianceDateEnd || null,
                    measurement: resource ? resource : null,
                    measurementValue: incident.exceedance.data?.length ? incident.exceedance.data.map(el => el.value) : defaultObject.measurementValue,
                    measurementTimestamp: incident.exceedance.data?.length ? incident.exceedance.data.map(el => el.timestamp) : defaultObject.measurementTimestamp,
                    assesmentFlag: incident.exceedance.healthAffected === undefined || incident.exceedance.healthAffected === null ? '' : (incident.exceedance.healthAffected ? "1" : "0"),
                    samplesPerYear: incident.exceedance.location?.sampleNumberPerYear || '',
                    ...getLocationTypeObject(incident.exceedance.location, zone._id),
                    samplingDate: !incident.exceedance?.timestamp ? null : new Date(incident.exceedance?.timestamp).getTime(),
                    incidentYear: existingRegistryId ? existingRegistryId.indexOf('/') !== -1 ? locationGroups.find(el => el._id === incident.jivu._id)?.attributes?.OIB ? existingRegistryId.slice(existingRegistryId.indexOf('/') + 1, existingRegistryId.lastIndexOf('/')) : existingRegistryId.slice(0, existingRegistryId.indexOf('/')) : '' : '',
                });
                setGlobalLoading(false);
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                console.error('Fetching Incident data resulted in an error:', error);
                setGlobalLoading(false);
                setFetchError(true);
            });
        }
        else setValues(defaultObject);

    }, [props.incidentId, token, locationGroups, zones, locations, locationTypes, allResourceTypes, notificationMethods]);

    // useEffect(() => {
    //     setMeasurementValue(defaultObject.measurementValue);
    //     setMeasurementTimestamp(defaultObject.measurementTimestamp);
    //     setDisableSubmit(false);
    // }, [measurement]);

    useEffect(() => {
        if (zone && measurement) {
            setDerogation({ loading: true });
            API.thresholds.getDerogation(token, zone._id, measurement.key)
                .then(res => {
                    setDerogation(res.data);
                })
                .catch(error => {
                    if (error.status !== 404) {
                        setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                        console.error('Fetching Derogation data resulted in an error:', error);
                    }
                    setDerogation(null);
                })
        }
        else setDerogation(null);
    }, [zone, measurement, token])

    useEffect(() => {
        if (zone !== null) {
            setLoading(true);
            setSettlements([]);
            API.settlements.getAllSettlements(token, [zone._id]).then((response) => {
                if (response.data) {
                    const data = response.data.map((item) => {
                        return {
                            ...item,
                            id: item._id,
                            name: item.name,
                            population: item.population,
                            municipality: item.cityMunicipality?.name,
                            county: item.county?.name,
                        }
                    });
                    setSettlements(data);
                    setLoading(false);
                } else {
                    setSettlements([])
                }
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                console.error('Fetching Settlements resulted in an error:', error);
                setLoading(false);
                setSettlements({ error: error });
            })
        } else setSettlements([]);

    }, [token, zone]);

    const resetForm = () => {
        setValues(defaultObject);
        if (props.incidentId) navigate('/incidents/details/' + props.incidentId)
        else setAlert({ open: true, messageId: "APP.SUCCESS.RESET", severity: "info" });
    }

    const submitForm = useCallback((flag) => {
        setSubmitted(true);
        const editMode = Boolean(props.incidentId);
        const submitObject = {
            "id": props.incidentId,
            "county": zone?.attributes?.county,
            "registryId": registryId ? `${waterSupplier?.attributes?.OIB ? (waterSupplier.attributes.OIB + "/") : ""}${incidentYear || new Date().getFullYear()}/${registryId}` : null,
            "sampleMark": analysisId,
            "jivu": waterSupplier?._id,
            "zoneId": zone?._id,
            "nonCompliantZone": compliantZoneFlag === '1' ? 1 : 0,
            "settlements": compliantZoneFlag === '1' ? selectedSettlements.map(el => el._id) : null,
            ...(!settlements.error && (selectedSettlements.length || settlements.length) ? {
                "populationSum": compliantZoneFlag === '1'
                    ? selectedSettlements.reduce((partialSum, settlement) => partialSum + settlement.population, 0)
                    : settlements.reduce((partialSum, settlement) => partialSum + settlement.population, 0),
                "waterSuppliedSum": compliantZoneFlag === '1'
                    ? selectedSettlements.reduce((partialSum, settlement) => partialSum + settlement.deliveredWaterQuantity?.populationEconomyDay, 0)
                    : settlements.every(el => !el.deliveredWaterQuantity?.populationEconomyDay) ? zone?.attributes?.deliveredWater || 0 : settlements.reduce((partialSum, settlement) => partialSum + settlement.deliveredWaterQuantity?.populationEconomyDay, 0),
            } : {}),
            "objectsInvolved": objectsInvolved,
            "controlType": selectedControlType.length ? selectedControlType : undefined,
            "resourceType": measurement?.key,
            "healthAffected": assesmentFlag !== '' ? (assesmentFlag === "1" ? 1 : 0) : null,
            "timestamp": samplingDate ?? undefined,
            "data": measurementValue.map((value, i) => {
                if (value === '' || measurementTimestamp[i] === null || isNaN(new Date(measurementTimestamp[i]).getTime())) return null;
                else return {
                    "unit": measurement?.unit,
                    "value": value,
                    "timestamp": measurementTimestamp[i]
                }
            }).filter(el => el !== null),
            "derogation": derogation ? derogation._id : undefined,
            "location": locationToggle === "1"
                ? {
                    "name": location?.name,
                    "locationType": locationType?.key
                } : {
                    "name": samplingLocation,
                    "pointOfCompliance": samplingLocationType,
                    "sampleNumberPerYear": samplesPerYear
                },
            "incidentCategory": selectedIncidentCat,
            "incidentCause": selectedIncidentCause !== '' ? selectedIncidentCause : undefined,
            "remedialAction": {
                "remedialActionType": selectedRemedialAction,
                "dateStart": remedialActionDateRange.from,
                "dateEnd": remedialActionDateRange.to
            },
            "populationNotified": populationInformed === '1' ? {
                "notificationMethod": selectedNotificationMethods.map(el => el.key),
                "notification": {
                    "text": notificationText,
                    "link": notificationLink
                },
                "restriction": restrictionFlag ? {
                    "description": selectedRestrictionType !== '' ? selectedRestrictionType : undefined,
                    "dateStart": restrictionDateRange.from,
                    "dateEnd": restrictionDateRange.to,
                    "numberOfDays": restrictionDateRange.to ? intervalToDuration({ start: restrictionDateRange.from, end: restrictionDateRange.to }).days : undefined,
                } : null
            } : null,
            "repeatedSampling": repeatedSamplingPlaceFlag,
            "repeatedSamplingPlace": repeatedSamplePlace,
            "nonComplianceDateEnd": incidentEndDate,
            "incidentDescription": incidentDescription
        };
        const apiCall = editMode ? API.incidents.putIncident : API.incidents.postIncident;

        apiCall(token, submitObject).then(({ data }) => {
            setSubmitted(false);
            if (!flag) {
                setValues(defaultObject);
                navigate('/incidents/details/' + data._id, { state: { open: true, messageId: "APP.SUCCESS." + (editMode ? "UPDATE" : "CREATE"), severity: "success" } });
            } else {
                setMeasurement(null);
                setMeasurementValue(defaultObject.measurementValue);
                setMeasurementTimestamp(defaultObject.measurementTimestamp);
                setAlert({ open: true, messageId: "APP.SUCCESS.CREATE", severity: "success" });
            }
        }).catch(error => {
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            console.error('Failed to updating the incident:', error);
            setSubmitted(false);
        });

    }, [token, analysisId,
        registryId,
        incidentDescription,
        waterSupplier,
        zone,
        compliantZoneFlag,
        settlements,
        selectedSettlements,
        objectsInvolved,
        selectedControlType,
        selectedIncidentCat,
        selectedIncidentCause,
        selectedRemedialAction,
        remedialActionDateRange,
        restrictionFlag,
        selectedRestrictionType,
        restrictionDateRange,
        selectedNotificationMethods,
        repeatedSamplingPlaceFlag,
        repeatedSamplePlace,
        populationInformed,
        notificationText,
        notificationLink,
        incidentEndDate,
        measurement,
        derogation,
        measurementValue,
        measurementTimestamp,
        assesmentFlag,
        samplingLocation,
        samplesPerYear,
        samplingLocationType,
        locationToggle,
        location,
        locationType,
        samplingDate,
        navigate,
        props.incidentId,
        incidentYear,
    ]);

    const handleCopy = () => setOpenCopyDialog(true)

    const onWaterSupplyChange = (newValue) => {
        setSelectedSettlements([]);
        setZone(null);
        setWaterSupplier(newValue);
    };
    const onZoneChange = (newValue) => {
        setSelectedSettlements([]);
        setZone(newValue);
    };
    const onSettlementChange = (newValue) => {
        setSelectedSettlements(newValue);
    };

    const onLocationChange = (newValue) => {
        setLocation(newValue);
        setLocationType(locationTypes.find(el => el.key === newValue?.measurePointType) || null);
    }

    const addNewMeasurement = () => {
        setMeasurementValue(old => old.concat(''));
        setMeasurementTimestamp(old => old.concat(null));
    };

    const onMeasurementsChange = (value, index, cb) => {
        if (value !== undefined) cb(oldValue => oldValue.map((v, i) => i === index ? value : v));
        else cb(oldValue => oldValue.filter((el, i) => index !== i));
    };

    const changeRemedialActionDateFrom = (value) => {
        const startDate = new Date(value);
        setRemedialActionDateRange(oldDateRange => {
            if (value && (startDate.getTime() < oldDateRange.to)) {
                return {
                    from: startDate.getTime(),
                    to: oldDateRange.to
                }
            }
            else if (value && (startDate.getTime() >= oldDateRange.to)) {
                return {
                    from: startDate.getTime(),
                    to: startDate.getTime() + 1
                };
            }
            else return oldDateRange;
        })
    };

    const changeRemedialActionDateTo = (value) => {
        const endDate = new Date(value);
        setRemedialActionDateRange(oldDateRange => {
            if (value && (endDate.getTime() > oldDateRange.from)) {
                return {
                    from: oldDateRange.from,
                    to: endDate.getTime()
                }
            }
            else if (value && (endDate.getTime() <= oldDateRange.from)) {
                return {
                    from: endDate.getTime() - 1,
                    to: endDate.getTime()
                };
            }
            else return oldDateRange;
        })
    };

    const changeRestrictionDateFrom = (value) => {
        const startDate = new Date(value);
        setRestrictionDateRange(oldDateRange => {
            if (value && oldDateRange.to) {
                if (value && (startDate.getTime() < oldDateRange.to)) {
                    return {
                        from: startDate.getTime(),
                        to: oldDateRange.to
                    }
                }
                else if (value && (startDate.getTime() >= oldDateRange.to)) {
                    return {
                        from: startDate.getTime(),
                        to: startDate.getTime() + 1
                    };
                }
            }
            else if (value) return { from: startDate.getTime(), to: null }
            else return { from: null, to: oldDateRange.to };;
        })
    };

    const changeRestrictionDateTo = (value) => {
        const endDate = new Date(value);
        setRestrictionDateRange(oldDateRange => {
            if (value && oldDateRange.from) {
                if (endDate.getTime() > oldDateRange.from) {
                    return {
                        from: oldDateRange.from,
                        to: endDate.getTime()
                    }
                }
                else if (endDate.getTime() <= oldDateRange.from) {
                    return {
                        from: endDate.getTime() - 1,
                        to: endDate.getTime()
                    };
                }
            }
            else if (value) return { from: null, to: endDate.getTime() }
            else return { from: oldDateRange.from, to: null };
        })
    };

    useEffect(() => {
        if (!!waterSupplier && !!zone && !!selectedIncidentCat?.length && ((locationToggle === "0" && samplingLocation?.trim() !== '') || (locationToggle === "1" && !!location)) &&
            selectedIncidentCause !== '' && !!selectedRemedialAction?.length && (selectedRemedialAction?.includes(13) || remedialActionDateRange.from)
            && analysisId?.toString().trim() !== '') setDisabledSubmitButton(false);
        else setDisabledSubmitButton(true);
    }, [analysisId, location, locationToggle, remedialActionDateRange.from, samplingLocation, selectedIncidentCat, selectedIncidentCause, selectedRemedialAction, waterSupplier, zone])

    return <Container>
        <ConfirmationDialog
            open={openCopyDialog}
            content={<FormattedMessage id="INCIDENT.COPY.CONTENT" />}
            title={<span>
                <FormattedMessage id="INCIDENT.COPY" /> {measurement
                    ? <Box pl={0.5} color="info.dark" component="span"><FormattedMessage id={measurement.name} /></Box>
                    : ""}
            </span>}
            customButtonTitle={<FormattedMessage id="SUBMIT_ADDITIONAL.CONFIRM" />}
            customButtonColor="primary"
            handleCancel={() => setOpenCopyDialog(false)}
            handleCustomButton={() => { setOpenCopyDialog(false); submitForm(true); }}
        />
        <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
            <AlertSnackbar open={alert.open} onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} messageId={alert.messageId} autoHide={alert.autoHide} />
            <CardHeader avatar={props.incidentId ? <span><WarningOutlined color="info" /><EditOutlined color="info" /></span> : <WarningOutlined color="info" />} title={<Typography component="span" variant="h5"><FormattedMessage id={"REGISTRY_INCIDENT_FORM" + (props.incidentId ? ".EDIT" : "")} /></Typography>} subheader={<Typography variant='caption' sx={{ display: "block", color: "warning.main" }}><FormattedMessage id="REQUIRED.FIELDS" /></Typography>} />
            {fetchError
                ? <CardContent sx={{ mb: 'auto' }}><Alert severity="error"><Typography variant='body2'><FormattedMessage id="NO_DATA" /></Typography></Alert></CardContent>
                : (!globalLoading
                    ? <>
                        <IncidentsFormContent
                            loading={loading}
                            incidentId={props.incidentId}
                            registryId={registryId} setRegistryId={setRegistryId}
                            analysisId={analysisId} setAnalysisId={setAnalysisId}
                            incidentDescription={incidentDescription} setIncidentDescription={setIncidentDescription}
                            waterSupplier={waterSupplier} zone={zone}
                            compliantZoneFlag={compliantZoneFlag} setCompliantZoneFlag={setCompliantZoneFlag}
                            settlements={settlements} selectedSettlements={selectedSettlements}
                            objectsInvolved={objectsInvolved} setObjectsInvolved={setObjectsInvolved}
                            selectedControlType={selectedControlType} setSelectedControlType={setSelectedControlType}
                            selectedIncidentCat={selectedIncidentCat} setSelectedIncidentCat={setSelectedIncidentCat}
                            selectedIncidentCause={selectedIncidentCause} setSelectedIncidentCause={setSelectedIncidentCause}
                            selectedRemedialAction={selectedRemedialAction} setSelectedRemedialAction={setSelectedRemedialAction}
                            remedialActionDateRange={remedialActionDateRange}
                            selectedRestrictionType={selectedRestrictionType} setSelectedRestrictionType={setSelectedRestrictionType}
                            restrictionFlag={restrictionFlag} setRestrictionFlag={setRestrictionFlag}
                            restrictionDateRange={restrictionDateRange}
                            selectedNotificationMethods={selectedNotificationMethods} setSelectedNotificationMethods={setSelectedNotificationMethods}
                            repeatedSamplingPlaceFlag={repeatedSamplingPlaceFlag} setRepeatedSamplingPlaceFlag={setRepeatedSamplingPlaceFlag}
                            repeatedSamplePlace={repeatedSamplePlace} setRepeatedSamplePlace={setRepeatedSamplePlace}
                            populationInformed={populationInformed} setPopulationInformed={setPopulationInformed}
                            notificationText={notificationText} setNotificationText={setNotificationText}
                            notificationLink={notificationLink} setNotificationLink={setNotificationLink}
                            incidentEndDate={incidentEndDate} setIncidentEndDate={setIncidentEndDate}
                            measurement={measurement} setMeasurement={setMeasurement}
                            measurementValue={measurementValue} setMeasurementValue={(val, i) => onMeasurementsChange(val, i, setMeasurementValue)} initialValuesLen={initialValuesLen}
                            measurementTimestamp={measurementTimestamp} setMeasurementTimestamp={(val, i) => onMeasurementsChange(val, i, setMeasurementTimestamp)}
                            addNewMeasurement={addNewMeasurement}
                            derogation={derogation}
                            assesmentFlag={assesmentFlag} setAssesmentFlag={setAssesmentFlag}
                            samplingLocation={samplingLocation} setSamplingLocation={setSamplingLocation}
                            samplesPerYear={samplesPerYear} setSamplesPerYear={setSamplesPerYear}
                            locationToggle={locationToggle} setLocationToggle={setLocationToggle}
                            location={location} setLocation={onLocationChange} locationType={locationType}
                            samplingLocationType={samplingLocationType} setSamplingLocationType={setSamplingLocationType}
                            samplingDate={samplingDate} setSamplingDate={setSamplingDate}
                            onWaterSupplyChange={onWaterSupplyChange}
                            onZoneChange={onZoneChange}
                            onSettlementChange={onSettlementChange}
                            changeRemedialActionDateFrom={changeRemedialActionDateFrom}
                            changeRemedialActionDateTo={changeRemedialActionDateTo}
                            changeRestrictionDateFrom={changeRestrictionDateFrom}
                            changeRestrictionDateTo={changeRestrictionDateTo}
                            incidentYear={incidentYear}
                        />
                        <Divider />
                        <CardActions>
                            <Button disabled={submitted} color="warning" onClick={resetForm}><FormattedMessage id={props.incidentId ? "CANCEL" : "RESET"} /></Button>
                            <Button disabled={submitted || disabledSubmitButton} onClick={() => submitForm()}><FormattedMessage id={props.incidentId ? "EDIT" : "SUBMIT"} /></Button>
                            {!props.incidentId && <Chip disabled={!measurement || submitted || disabledSubmitButton} onClick={handleCopy} label={<FormattedMessage id="SUBMIT_ADDITIONAL" />} variant="outlined" sx={{ ml: 1, textTransform: 'uppercase', fontWeight: 'bold', color: "primary.dark" }} />}
                        </CardActions>
                    </>
                    : <CardContent sx={{ mb: 'auto' }}>
                        <LoadingData />
                    </CardContent>)
            }
        </Card >
    </Container>;
}