import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DateRangeSelector, LocationInputField } from 'components';
import { useSelector } from 'react-redux';
import { selectMeasurePoints } from 'redux/locationsSlice';

import { Card, CardContent, Grid, Typography } from '@mui/material';
import AdditionalFilters from './AdditionalFilters';

function SampleReportingFilters(props) {
    const {
        selectedLocations,
        filterHandler,
        sampleTypesSelected,
        qualityTypesSelected,
        pictureSample,
    } = props;
    const locations = useSelector(selectMeasurePoints);

    return <Card>
        <CardContent>
            <Grid container className="filter-tile" justifyContent="space-between" spacing={2}>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                    <Typography sx={{ pb: 1 }} variant="h6"><FormattedMessage id="DATE_SELECTED" /></Typography>
                    <DateRangeSelector column />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={12} className='filter-item location-select' sx={{ alignItems: 'space-between' }}>
                    <Typography sx={{ pb: 2 }} variant="h6"><FormattedMessage id="LOCATIONS" />:</Typography>

                    <LocationInputField
                        selectedLocation={selectedLocations}
                        setSelectedLocation={(location) => filterHandler(location.filter(mapedLocation => locations.map(filteredLocation => filteredLocation === mapedLocation)), 'locations')}
                        
                        limitTags={3}
                        disableCloseOnSelect
                        filterSelectedOptions
                        multiple
                    />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                    <Typography sx={{ pb: 2 }} variant="h6"><FormattedMessage id="FILTERS" />:</Typography>
                    <AdditionalFilters
                        filterHandler={filterHandler}
                        sampleTypesSelected={sampleTypesSelected} selectedLocations
                        qualityTypesSelected={qualityTypesSelected}
                        pictureSample={pictureSample} />
                </Grid>

            </Grid>
        </CardContent>
    </Card>;
}

export default SampleReportingFilters;