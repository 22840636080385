import { useState } from "react";
import { useNavigate } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { LocationDetailsDialogForm } from "components";
import { useSelector } from "react-redux";
import { permissionSelector } from "redux/userSlice";
import { ComplianceTag } from "components";
import { Avatar, Chip, Card, CardContent, CardHeader, CardMedia, IconButton, Tooltip, Typography, Grid } from "@mui/material";
import { EditOutlined, LocationOnOutlined, WaterOutlined } from "@mui/icons-material";
import { selectTypologyConf } from "redux/configurationSlice";

export default function LocationMeasurePointCard(props) {
    const { location } = props;
    const groupAdmin = useSelector((state) => permissionSelector(state, 'update-location'))
    const measurePointType = useSelector(selectTypologyConf).find(type => type.key === location.measurePointType)
    const navigate = useNavigate();
    const locale = useIntl().locale;
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    const handleClickOpenForm = () => {
        setOpenFormDialog(true);
        setSuccessMsg("");
    };

    const handleCloseForm = () => {
        setOpenFormDialog(false);
    };

    return (
        <Card sx={{ width: '100%' }}>
            <CardMedia
                component="img"
                height="250"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate("/locations/details/" + location._id)}
                image={process.env.REACT_APP_API_BASE_URL + "/" + location.picture}
                alt="card-media"
            />
            <CardHeader
                title={<Typography variant={location.logo ? "h6" : "h5"}>{location.name}</Typography>}
                subheader={<Grid container spacing={0.5} justifyContent="flex-start" alignItems="flex-start">
                    <Grid item>
                        <ComplianceTag locationId={location._id} />
                    </Grid>
                    <Grid item>
                        <Chip size="small" icon={<LocationOnOutlined color="primary" />} label={<FormattedMessage id="MEASURE_POINT" />} />
                    </Grid>
                    <Grid item>
                        <Chip size="small" icon={<WaterOutlined color="primary" />} label={<FormattedMessage id={"LOCATION_TYPE." + measurePointType.value} />} />
                    </Grid>
                </Grid>}
                avatar={location.logo && <Avatar src={process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + location.logo} />}
                action={
                    [
                        groupAdmin && groupAdmin.includes(location._id) ?
                            <Tooltip key="editLocationGroupTooltip" title={<FormattedMessage id="UPDATE" />} placement="top-start" arrow>
                                <IconButton key="editLocationGroup" onClick={() => handleClickOpenForm(location._id)} ><EditOutlined /></IconButton>
                            </Tooltip> : null
                    ]
                }
            />
            <CardContent>
                <Typography variant="body2">
                    {location.description[locale]}
                </Typography>
            </CardContent>
            <LocationDetailsDialogForm
                openFormDialog={openFormDialog}
                handleCloseForm={handleCloseForm}
                successMsg={successMsg}
                setSuccessMsg={setSuccessMsg}
                locationId={location._id}
                locationGroupId={location.locationGroupId}
            />
        </Card >

    );
}