import React from 'react';
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { selectPointOfComplianceTypeConf, selectControlTypesConf, selectIncidentCategoryTypesConf, selectIncidentExceedanceCauseConf, selectRemedialActionTypesConf, selectResourcesTypesConf, selectTypologyConf, selectIncidentNotificationTypes, selectRestrictionTypesConf, selectRepeatedSamplingPlaceConf } from 'redux/configurationSlice'
import { DisplayBox, ThresholdDetailsView, DisplayResourceName } from 'components'
import { Box, Card, CardActions, CardContent, Chip, Divider, Stack, Typography, Link } from '@mui/material'
import { formatDateLocale } from 'utils'


const IncidentDetails = ({ incident, mini }) => {
    const pointComplianceType = useSelector(selectPointOfComplianceTypeConf);
    const controlTypes = useSelector(selectControlTypesConf);
    const categoryTypes = useSelector(selectIncidentCategoryTypesConf);
    const exceedanceCauseConf = useSelector(selectIncidentExceedanceCauseConf);
    const remedialActionTypes = useSelector(selectRemedialActionTypesConf);
    const resourceTypes = useSelector(selectResourcesTypesConf);
    const typology = useSelector(selectTypologyConf);
    const notificationMethods = useSelector(selectIncidentNotificationTypes);
    const restrictionTypes = useSelector(selectRestrictionTypesConf);
    const repeatedSamplingPlaceConf = useSelector(selectRepeatedSamplingPlaceConf);

    if (!incident) return;

    const healthAffectedLabel = (value) => {
        if (value === undefined || value === null) return <FormattedMessage id="REGISTRY_ASSESMENT_FLAG.UNKNOWN" />
        else if (value !== undefined && value) return <FormattedMessage id="REGISTRY_ASSESMENT_FLAG.YES" />;
        else return <FormattedMessage id="REGISTRY_ASSESMENT_FLAG.NO" />;
    }

    const incidentComplexProps = {
        parameter: resourceTypes.find(type => type.key === incident.exceedance?.resourceType),
        locationType: typology.find(typ => typ.key === incident.exceedance?.location?.locationType),
        pointComplianceType: pointComplianceType.find(mpType => mpType.key === incident.exceedance?.location?.pointOfCompliance),
        controlType: controlTypes.find(mpType => mpType.key === incident.exceedance?.controlType),
        exceedanceCause: exceedanceCauseConf.find(cause => cause.key === incident.nonComplianceReport?.incidentCause),
        populationNotified: Boolean(incident.nonComplianceReport?.populationNotified),
        notificationMethods: incident.nonComplianceReport?.populationNotified?.notificationMethod,
        link: incident.nonComplianceReport?.populationNotified?.notification?.link,
        restrictions: Boolean(incident.nonComplianceReport?.populationNotified?.restriction),
        restrictionDesc: restrictionTypes.find(type => type.key.toString() === incident.nonComplianceReport?.populationNotified?.restriction?.description),
        repeatedSamplingPlace: repeatedSamplingPlaceConf.find(place => place.key === incident.nonComplianceReport?.repeatedSamplingPlace),
    }

    return <Stack direction="column" spacing={2}>
        <DisplayBox label={<FormattedMessage id="INCIDENT.REGISTRYID" />} value={incident.registryId} />
        <Divider>
            <Typography variant='h6' color="primary"><FormattedMessage id="INCIDENT.MEASUREMENTS" /></Typography>
        </Divider>
        <DisplayBox label={<FormattedMessage id="ANALYSIS_ID" />} value={incident.exceedance.sampleMark} />
        <DisplayBox label={<FormattedMessage id="TIMESTAMP.INCIDENT" />} value={incident.timestamp ? incident.timestamp : formatDateLocale(incident.exceedance?.timestamp)} />
        <DisplayBox label={<FormattedMessage id="INCIDENT.PARAMETER" />} value={incidentComplexProps.parameter ? <DisplayResourceName resource={incidentComplexProps.parameter} /> : null} />
        <DisplayBox label={<FormattedMessage id="INCIDENT.VALUES" />}
            value={Boolean(incident.exceedance.data[0]?.value) && incident.exceedance.data?.map((el, index) => (
                <Card key={el._id} sx={{ display: 'inline-block', mr: 1, mb: 1 }} variant="outlined" >
                    <CardContent sx={{ pb: 0 }}>
                        <Typography variant='h5' color="error" display='inline' >{el.value}</Typography>
                        <Typography variant='body2' display='inline' sx={{ ml: 0.5 }} >{el.unit}</Typography>
                    </CardContent>
                    <CardActions>
                        <Typography color="text.secondary" variant="body2" display="block">{formatDateLocale(el.timestamp)}</Typography>
                    </CardActions>
                </Card>
            ))} />
        <DisplayBox label={<FormattedMessage id="THRESHOLD.DEROGATION" />} value={Boolean(incident.exceedance.derogation) && <ThresholdDetailsView data={incident.exceedance.derogation} noParamName />} />
        {incident.exceedance?.location && <> <DisplayBox label={<FormattedMessage id="SAMPLING_LOCATION" />} value={incident.exceedance.location?.name} />
            {incidentComplexProps.locationType ? <DisplayBox label={<FormattedMessage id="LOCATION.TYPE" />} value={incidentComplexProps.locationType ? <FormattedMessage id={"LOCATION_TYPE." + incidentComplexProps.locationType.value} /> : null} />
                : <DisplayBox label={<FormattedMessage id="POINT_OF_COMPLIANCE_TYPE" />} value={incidentComplexProps.pointComplianceType ? <FormattedMessage id={"POINT_OF_COMPLIANCE_TYPE." + incidentComplexProps.pointComplianceType.value} /> : null} />}</>}
        {Boolean(incident.nonComplianceReport?.repeatedSampling) && <DisplayBox label={<FormattedMessage id="REPEATED_SAMPLE.PLACE" />} value={incidentComplexProps.repeatedSamplingPlace && <FormattedMessage id={incidentComplexProps.repeatedSamplingPlace?.value} />} />}
        <DisplayBox label={<FormattedMessage id="REGISTRY_ASSESMENT_FLAG" />} value={healthAffectedLabel(incident.exceedance.healthAffected)} />
        <DisplayBox label={<FormattedMessage id="CONTROL_TYPE" />} value={incidentComplexProps.controlType ? <FormattedMessage id={"CONTROL_TYPE." + incidentComplexProps.controlType.value} /> : null} />

        <Divider>
            <Typography variant='h6' color="primary"><FormattedMessage id="INCIDENT.GEOGRAPHIC_INFO" /></Typography>
        </Divider>
        <DisplayBox label={<FormattedMessage id="INCIDENT.COUNTY" />} value={incident.county} />
        <DisplayBox label={<FormattedMessage id="WATER_SUPPLIER" />} value={incident.jivu?.name} />
        <DisplayBox label={<FormattedMessage id="ZONE" />} value={incident.zone?.id?.name} />
        {(!incident.zone?.nonCompliantZone || incident.zone?.settlements?.length > 0) && <DisplayBox label={<FormattedMessage id="SETTLEMENTS" />}
            value={!incident.zone?.nonCompliantZone ? <FormattedMessage id="INCIDENT.NON_COMPLIANT_ZONE" /> : incident.zone?.settlements.map((settlement, index) => (
                <Box sx={{ ml: 2 }} key={index}>{`${index + 1}. ${settlement.name} (${settlement.population})`}</Box>
            ))} />}
        <DisplayBox label={<FormattedMessage id="INCIDENT.POPULATION_SUM" />} value={incident.zone?.populationSum} />
        <DisplayBox label={<FormattedMessage id="INCIDENT.WATER_CONSUMPTION_PER_DAY" />} value={incident.zone?.waterSuppliedSum ? (incident.zone.waterSuppliedSum.toFixed(3) + " m³") : null} />
        {incident.zone?.objectsInvolved?.length > 0 && <DisplayBox label={<FormattedMessage id="OBJECTS_INVOLVED" />}
            value={incident.zone?.objectsInvolved?.map((item, index) => (
                <Box sx={{ ml: 2 }} key={index}>{index + 1}. {item}</Box>
            ))} />}

        <Divider>
            <Typography variant='h6' color="primary"><FormattedMessage id="INCIDENT_INFORMATION" /></Typography>
        </Divider>

        <DisplayBox label={<FormattedMessage id="INCIDENT_CATEGORY" />}
            value={!incident.nonComplianceReport?.incidentCategory?.length ? undefined : incident.nonComplianceReport.incidentCategory.map((category, index) => {
                const catType = categoryTypes.find(type => type.key === category);
                return <Chip key={index} sx={{ mr: 1, mb: 1 }} label={catType ? <FormattedMessage id={"INCIDENT_CATEGORY." + catType.value} /> : null} />
            })} />
        <DisplayBox label={<FormattedMessage id="INCIDENT_EXCEEDANCE_CAUSE" />} value={incidentComplexProps.exceedanceCause ? <FormattedMessage id={"EXCEEDANCE_CAUSE." + incidentComplexProps.exceedanceCause.value} /> : null} />
        <DisplayBox label={<FormattedMessage id="REMEDIAL_ACTION.TYPE" />}
            value={!incident.nonComplianceReport.remedialAction?.remedialActionType?.length ? undefined : incident.nonComplianceReport.remedialAction?.remedialActionType?.map((action, index) => (
                <Chip key={index} sx={{ mr: 1, mb: 1 }} label={<FormattedMessage id={"REMEDIAL_ACTION." + remedialActionTypes.find(type => type.key === action)?.value} />} />
            ))} />
        <DisplayBox label={<FormattedMessage id="REMEDIAL_ACTION.STARTED" />} value={formatDateLocale(incident.nonComplianceReport.remedialAction?.dateStart)} />
        <DisplayBox label={<FormattedMessage id="REMEDIAL_ACTION.ENDED" />} value={formatDateLocale(incident.nonComplianceReport.remedialAction?.dateEnd)} />


        <Divider>
            <Typography variant='h6' color="primary"><FormattedMessage id="NOTIFICATION_INFORMATION" /></Typography>
        </Divider>

        <DisplayBox label={<FormattedMessage id="INCIDENT.INFORMED" />} value={<FormattedMessage id={incidentComplexProps.populationNotified ? "YES" : "NO"} />} />
        {incidentComplexProps.populationNotified && <>
            <DisplayBox label={<FormattedMessage id="INCIDENT.NOTIFICATION_METHODS" />}
                value={incidentComplexProps.notificationMethods?.length ? incidentComplexProps.notificationMethods.map((method, index) => {
                    const notification = notificationMethods.find(item => item.key === method);
                    return <Chip key={index} sx={{ mr: 1, mb: 1 }} label={notification ? <FormattedMessage id={notification.value} /> : null} />
                }) : null} />

            <DisplayBox textStyle={{ fontStyle: 'italic' }} label={<FormattedMessage id="NOTE_TEXT" />} value={incident.nonComplianceReport?.populationNotified?.notification?.text} />
            <DisplayBox label={<FormattedMessage id="LINK" />} value={incidentComplexProps.link ? <Link target='blank' href={`${incidentComplexProps.link}`}>{incidentComplexProps.link}</Link> : null} />
            {incidentComplexProps.restrictions ? <>
                <DisplayBox label={<FormattedMessage id="RESTRICTION_FLAG" />} value={<FormattedMessage id="RESTRICTION_FLAG.YES" />} />
                <DisplayBox label={<FormattedMessage id="RESTRICTION.TYPE" />} value={incidentComplexProps.restrictionDesc ? <FormattedMessage id={"RESTRICTION.HELPER_TEXT." + incidentComplexProps.restrictionDesc.value} /> : null} />
                <DisplayBox label={<FormattedMessage id="RESTRICTION.STARTED" />} value={formatDateLocale(incident.nonComplianceReport.populationNotified.restriction.dateStart)} />
                <DisplayBox label={<FormattedMessage id="RESTRICTION.ENDED" />} value={formatDateLocale(incident.nonComplianceReport.populationNotified.restriction.dateEnd)} />
            </> : <DisplayBox label={<FormattedMessage id="RESTRICTION_FLAG" />} value={<FormattedMessage id="RESTRICTION_FLAG.NO" />} />}
        </>}
        <DisplayBox label={<FormattedMessage id="REPEATED_SAMPLE.LABEL" />} value={<FormattedMessage id={"REPEATED_SAMPLE." + (incident.nonComplianceReport?.repeatedSampling ? "YES" : "NO")} />} />
        {incident.nonComplianceReport?.nonComplianceDateEnd && <DisplayBox label={<FormattedMessage id="INCIDENT_END_DATE" />} value={formatDateLocale(incident.nonComplianceReport?.nonComplianceDateEnd)} />}
        <DisplayBox textStyle={{ fontStyle: 'italic' }} label={<FormattedMessage id="DESCRIPTION" />} value={incident.nonComplianceReport?.incidentDescription} />
    </Stack>
}

export default IncidentDetails;