import { useState, useEffect, useCallback } from 'react'
import { CustomDataGrid, LoadingData, AlertSnackbar, ConfirmationDialog, SideDrawer, DisplayBox, DisplayResourceName } from 'components';
import { Typography, useMediaQuery, Alert, Stack, Card, CardHeader, CardContent, IconButton, Chip, Tooltip, CardActions, Button, ButtonGroup } from "@mui/material";
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { userSelector, permissionSelector } from "redux/userSlice";
import { selectResourcesTypesConf, selectIncidentCategoryTypesConf, selectIncidentNotificationTypes, selectIncidentExceedanceCauseConf, selectRemedialActionTypesConf, selectPointOfComplianceTypeConf, selectRepeatedSamplingPlaceConf, selectControlTypesConf, selectDerogationTypesConf, selectTypologyConf } from "redux/configurationSlice";
import { useIntl, FormattedMessage } from 'react-intl';
import { EditOutlined, DeleteOutline, AddOutlined, PreviewOutlined } from "@mui/icons-material";
import { formatDateLocale } from 'utils';
import IncidentCard from './IncidentCard';
import { selectLocations } from 'redux/locationsSlice';
import API from "api";
import { selectIncidentsTablePage, selectIncidentsTablePageSize, setIncidentsTablePage, setIncidentsTablePageSize } from 'redux/appSlice';
import { intervalToDays } from 'utils';


const NoDataCell = ({ none }) => <Typography component="span" color="text.disabled"><FormattedMessage id={none ? "NONE" : "NO_DATA"} /></Typography>


const IncidentsTable = (props) => {

    const [incidents, setIncidents] = useState([]);
    const [incidentId, setIncidentId] = useState('');
    const [reset, setReset] = useState(0);
    const [open, setOpen] = useState(false);
    const [archiveDisabled, setArchiveDisabled] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showArchived, setShowArchived] = useState(false);
    const [details, setDetails] = useState({});

    const { token } = useSelector(userSelector);
    const [loading, setLoading] = useState(false);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [alert, setAlert] = useState({ open: false });
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const createPermission = useSelector(state => permissionSelector(state, 'create-report'));
    const archivePermission = useSelector(state => permissionSelector(state, 'archive-report'));
    const deletePermission = useSelector(state => permissionSelector(state, 'delete-report'));
    const resourceTypesConf = useSelector(selectResourcesTypesConf);
    const incidentCategories = useSelector(selectIncidentCategoryTypesConf);
    const notificationMethodTypes = useSelector(selectIncidentNotificationTypes);
    const incidentExceedanceCause = useSelector(selectIncidentExceedanceCauseConf);
    const remedialActionTypes = useSelector(selectRemedialActionTypesConf);
    const pointComplianceType = useSelector(selectPointOfComplianceTypeConf);
    const repeatedSamplingPlaceTypes = useSelector(selectRepeatedSamplingPlaceConf);
    const controlTypes = useSelector(selectControlTypesConf);
    const derogationTypesConf = useSelector(selectDerogationTypesConf);
    const typology = useSelector(selectTypologyConf);
    const locationGroups = useSelector(selectLocations);
    const incidentsTablePage = useSelector(selectIncidentsTablePage);
    const incidentsTablePageSize = useSelector(selectIncidentsTablePageSize);
    const [page, setPage] = useState(incidentsTablePage || 0);
    const [pageSize, setPageSize] = useState(incidentsTablePageSize || 25);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (selectedRows.length < 1 || incidents.length < 1) setArchiveDisabled(true);
        else setArchiveDisabled(false);
    }, [selectedRows, incidents]);

    useEffect(() => {
        setLoading(true);
        setIncidents([]);
        API.incidents.getAllIncidents(token, showArchived).then((response) => {
            const data = response.data.map((item) => {

                const resourceType = resourceTypesConf.find(type => type.key === item.exceedance?.resourceType);
                const value = item.exceedance?.data?.map((item) => (item.value) ? `${item.value} ${item.unit}` : null).filter(el => el);

                const incidentCategory = item.nonComplianceReport?.incidentCategory.map((category) => incidentCategories.find((el) => el.key === category).value)
                const notificationMethod = item.nonComplianceReport?.populationNotified?.notificationMethod?.map((method) => notificationMethodTypes.find(type => type.key === method)?.value)
                return {
                    ...item,
                    id: item._id,
                    county: item.county,
                    zoneName: item.zone?.id?.name,
                    settlements: item.zone?.settlements?.map((settlement) => settlement.name) || [],
                    populationSum: item.zone?.populationSum,
                    incidentCategory: incidentCategory,
                    resourceType: resourceType,
                    values: value,
                    healthAffected: item.exceedance?.healthAffected,
                    notificationMethod: notificationMethod,
                    timestamp: formatDateLocale(item.exceedance?.timestamp),
                }
            });
            setIncidents(data);
            setLoading(false);
        }).catch((error) => {
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            console.error('Thresholds error', error);
            setLoading(false);
        })

    }, [intl, token, incidentCategories, notificationMethodTypes, resourceTypesConf, showArchived, reset]);

    const handleArchive = (mode) => {
        const apiCall = mode === 'archive' ? API.incidents.archiveIncidents : API.incidents.unarchiveIncidents;
        const selectedIds = selectedRows.map(el => el._id);

        apiCall(token, selectedIds).then(() => {
            setIncidents(incidents => incidents.filter(el => !selectedIds.includes(el._id)));
            setAlert({ open: true, messageId: "APP.SUCCESS." + mode?.toUpperCase(), severity: "success" });
            setArchiveDisabled(false);
        }).catch(error => {
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            console.error(`handleArchive, ${mode} error`, error);
            setArchiveDisabled(false);
        })
    }

    const handleDeleteIncident = () => {
        API.incidents.deleteIncident(token, incidentId).then((response) => {
            if (response?.data) {
                setAlert({ open: true, messageId: "INCIDENT.DELETE_SUCCESS", severity: "success" });
                setOpenConfirmDialog(false);
                setReset(reset => reset + 1);
                setIncidentId('');
            }
        }).catch(error => {
            setAlert({ open: true, messageId: error?.data?.id || "ERROR", severity: "error" });
            setOpenConfirmDialog(false);
            console.error(error);
            setIncidentId('');
        });
    }

    const renderTable = useCallback(() => {
        const setDrawerDetails = (incident) => {
            setDetails({
                title: <FormattedMessage id="INCIDENTS" />,
                display: <IncidentCard incident={incident} mini noEdit />,
            });
            setOpen(true);
        };
        const handlePageChange = (newPage) => {
            setPage(newPage);
            dispatch(setIncidentsTablePage(newPage));
        };
        const handlePageSizeChange = (newPageSize) => {
            setPageSize(newPageSize);
            dispatch(setIncidentsTablePageSize(newPageSize));
        };
        const headers = [
            {
                field: 'timestamp',
                type: 'dateTime',
                headerName: intl.formatMessage({ id: "DATE_START" }),
                renderCell: ({ value }) => <Typography> {value ?? <NoDataCell />} </Typography>,
                minWidth: 150,
                flex: 2
            },

            {
                field: 'registryId',
                headerName: intl.formatMessage({ id: "INCIDENT.REGISTRYID" }),
                renderCell: ({ value }) => <Typography> {value ?? <NoDataCell />} </Typography>,
                minWidth: 300,
                flex: 2
            },
            {
                field: "analysisId",
                headerName: intl.formatMessage({ id: "ANALYSIS_ID" }),
                hide: true,
                valueGetter: (row) => row.row.exceedance?.sampleMark,
            },
            {
                field: 'county',
                headerName: intl.formatMessage({ id: "COUNTY" }),
                minWidth: 150,
                flex: 2
            },
            {
                field: "jivu",
                headerName: intl.formatMessage({ id: "WATER_SUPPLIER" }),
                hide: true,
                valueGetter: (row) => row.row.jivu?.name,
                minWidth: 250
            },
            {
                field: 'zoneName',
                headerName: intl.formatMessage({ id: "ZONE" }),
                minWidth: 250,
                flex: 2
            },
            {
                field: 'settlements',
                headerName: intl.formatMessage({ id: "SETTLEMENTS" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => row.row.settlements.join(","),
                valueFormatter: (params) => params?.value !== "" ? params.value : intl.formatMessage({ id: "INCIDENT.NON_COMPLIANT_ZONE.SHORT" }),
                renderCell: (row) => <>
                    {row.row.settlements.length > 1 ?
                        <> {row.row.settlements[0]}
                            <span>
                                <Tooltip title={row.row.settlements.slice(1).join(", ")} arrow>
                                    <Chip sx={{ ml: 0.5 }} size="small" variant="outlined" label={<span style={{ textTransform: 'lowercase' }}>{row.row.settlements.length - 1} <FormattedMessage id="MORE" /></span>} />
                                </Tooltip>
                            </span>
                        </>
                        : (row.row.settlements.length ? row.row.settlements : <Typography component="span" variant="caption"><FormattedMessage id="INCIDENT.NON_COMPLIANT_ZONE.SHORT" /></Typography>)
                    }
                </>,
                minWidth: 150
            },
            {
                field: 'populationSum',
                type: 'number',
                headerName: intl.formatMessage({ id: "POPULATION_NUMBER" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: ({ value }) => <Typography> {value >= 0 ? value : <NoDataCell none />} </Typography>,
                minWidth: 150
            },
            {
                field: "waterSuppliedSum",
                headerName: intl.formatMessage({ id: "INCIDENT.WATER_CONSUMPTION_PER_DAY" }),
                hide: true,
                valueGetter: (row) => row.row.zone?.waterSuppliedSum === null || row.row.zone?.waterSuppliedSum === undefined ? undefined : row.row.zone.waterSuppliedSum.toFixed(3) + " m³"
            },
            {
                field: "objectsInvolved",
                headerName: intl.formatMessage({ id: "OBJECTS_INVOLVED" }),
                hide: true,
                valueGetter: (row) => !row.row.zone?.objectsInvolved?.length ? undefined : row.row.zone.objectsInvolved.join(",")
            },
            {
                field: 'incidentCategory',
                headerName: intl.formatMessage({ id: "INCIDENT_CATEGORY" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => row.row.incidentCategory.join(","),
                valueFormatter: (params) => params?.value !== "" ? params?.value?.split(",").map((category) => intl.formatMessage({ id: `REPORT.INCIDENT_CATEGORY.${category}` })) : null,
                renderCell: (row) => <>
                    {row.row.incidentCategory?.length > 0 ?
                        row.row.incidentCategory?.length > 1 ?
                            <>
                                <Typography>
                                    {intl.formatMessage({ id: `REPORT.INCIDENT_CATEGORY.${row.row.incidentCategory[0]}` })}
                                </Typography>
                                <span>
                                    <Tooltip title={row.row.incidentCategory.slice(1).map(el => intl.formatMessage({ id: `REPORT.INCIDENT_CATEGORY.${el}` })).join(", ")} arrow>
                                        <Chip sx={{ ml: 0.5 }} size="small" variant="outlined" label={<span style={{ textTransform: 'lowercase' }}>{row.row.incidentCategory.length - 1} <FormattedMessage id="MORE" /></span>} />
                                    </Tooltip>
                                </span>
                            </>
                            : <Typography>
                                {intl.formatMessage({ id: `REPORT.INCIDENT_CATEGORY.${row.row.incidentCategory}` })}
                            </Typography>
                        : <NoDataCell />
                    }
                </>,
                minWidth: 200
            },
            {
                field: "incidentExceedanceCause",
                headerName: intl.formatMessage({ id: "INCIDENT_EXCEEDANCE_CAUSE" }),
                hide: true,
                valueGetter: (row) => !row.row.nonComplianceReport?.incidentCause ? undefined : intl.formatMessage({ id: "REPORT.EXCEEDANCE_CAUSE." + incidentExceedanceCause.find(el => el.key === row.row.nonComplianceReport.incidentCause).value }),
            },
            {
                field: "remedialAction",
                headerName: intl.formatMessage({ id: "REMEDIAL_ACTION.TYPE" }),
                hide: true,
                valueGetter: (row) => !row.row.nonComplianceReport?.remedialAction?.remedialActionType?.length ? undefined : row.row.nonComplianceReport.remedialAction?.remedialActionType?.map((action) => intl.formatMessage({ id: "REMEDIAL_ACTION." + remedialActionTypes.find(type => type.key === action)?.value })).join(",")
            },
            {
                field: "remedialActionStarted",
                headerName: intl.formatMessage({ id: "REMEDIAL_ACTION.STARTED" }),
                hide: true,
                valueGetter: (row) => !row.row.nonComplianceReport?.remedialAction?.dateStart ? undefined : formatDateLocale(row.row.nonComplianceReport?.remedialAction?.dateStart)
            },
            {
                field: "remedialActionEnded",
                headerName: intl.formatMessage({ id: "REMEDIAL_ACTION.ENDED" }),
                hide: true,
                valueGetter: (row) => !row.row.nonComplianceReport?.remedialAction?.dateEnd ? undefined : formatDateLocale(row.row.nonComplianceReport?.remedialAction?.dateEnd)
            },
            {
                field: 'resourceType',
                headerName: intl.formatMessage({ id: "INCIDENT.PARAMETER" }),
                renderCell: (row) => row.row.resourceType !== undefined ? <DisplayResourceName resource={row.row.resourceType} /> : <NoDataCell />,
                valueGetter: (params) => params.value?.name ? intl.formatMessage({ id: params.value?.name }) : null,
                minWidth: 200,
                flex: 1
            },
            {
                field: "exceedanceTimestamp",
                headerName: intl.formatMessage({ id: "TIMESTAMP.INCIDENT" }),
                hide: true,
                valueGetter: (row) => !row.row.exceedance?.timestamp ? undefined : formatDateLocale(row.row.exceedance.timestamp),
            },
            {
                field: 'values',
                headerName: intl.formatMessage({ id: "INCIDENT_EXCEEDANCE" }),
                ...(!smallScreen && { flex: 1 }),
                valueFormatter: (params) => (params.value.join(",")),
                renderCell: (row) => <>
                    {row.row.values.length > 1 ?
                        <>
                            {row.row.values[0]}
                            <span>
                                <Tooltip title={row.row.values.slice(1).join(", ")} arrow>
                                    <Chip sx={{ ml: 0.5 }} size="small" variant="outlined" label={<span style={{ textTransform: 'lowercase' }}>{row.row.values.length - 1} <FormattedMessage id="MORE" /></span>} />
                                </Tooltip>
                            </span>
                        </>
                        : (row.row.values.length ? row.row.values : <NoDataCell />)
                    }
                </>,
                minWidth: 200
            },
            {
                field: "exceedanceLocation",
                headerName: intl.formatMessage({ id: "SAMPLING_LOCATION" }),
                hide: true,
                valueGetter: (row) => row.row.exceedance?.location?.name,
            },
            {
                field: "pointOfComplianceType",
                headerName: intl.formatMessage({ id: "POINT_OF_COMPLIANCE_TYPE" }),
                hide: true,
                valueGetter: (row) => !row.row.exceedance?.location?.pointOfCompliance ? undefined : intl.formatMessage({ id: "POINT_OF_COMPLIANCE_TYPE." + pointComplianceType.find(mpType => mpType.key === row.row.exceedance.location.pointOfCompliance).value })
            },
            {
                field: "waterSupplier",
                headerName: intl.formatMessage({ id: "LOCATION.TYPE" }),
                hide: true,
                valueGetter: (row) => !row.row.exceedance?.location?.locationType ? undefined : intl.formatMessage({ id: "LOCATION_TYPE." + typology.find(typology => typology.key === row.row.exceedance.location.locationType).value })
            },
            {
                field: "repeatedSamplePlace",
                headerName: intl.formatMessage({ id: "REPEATED_SAMPLE.PLACE" }),
                hide: true,
                valueGetter: (row) => !row.row.nonComplianceReport?.repeatedSamplingPlace ? undefined : intl.formatMessage({ id: repeatedSamplingPlaceTypes.find(place => place.key === row.row.nonComplianceReport.repeatedSamplingPlace).value }),
            },
            {
                field: "controlType",
                headerName: intl.formatMessage({ id: "CONTROL_TYPE" }),
                hide: true,
                valueGetter: (row) => !row.row.exceedance?.controlType ? undefined : intl.formatMessage({ id: controlTypes.find(type => type.key === row.row.exceedance.controlType).value }),
            },
            {
                field: "derogation",
                headerName: intl.formatMessage({ id: "DEROGATION" }),
                hide: true,
                valueGetter: (row) => intl.formatMessage({ id: row.row.exceedance?.derogation ? "YES" : "NO" }),
            },
            {
                field: "derogationMin",
                headerName: intl.formatMessage({ id: "THRESHOLD.MIN" }),
                hide: true,
                valueGetter: (row) => row.row.exceedance?.derogation?.min
            },
            {
                field: "derogationMax",
                headerName: intl.formatMessage({ id: "THRESHOLD.MAX" }),
                hide: true,
                valueGetter: (row) => row.row.exceedance?.derogation?.max
            },
            {
                field: "derogationType",
                headerName: intl.formatMessage({ id: "DEROGATION.TYPE" }),
                hide: true,
                valueGetter: (row) => !row.row.exceedance?.derogation?.derogation?.type ? undefined : intl.formatMessage({ id: "DEROGATION.TYPE." + derogationTypesConf.find((derogation) => derogation.key === row.row.exceedance.derogation.derogation.type).value })
            },
            {
                field: "derogationDescription",
                headerName: `${intl.formatMessage({ id: "DESCRIPTION" })} (${intl.formatMessage({ id: "DEROGATION" })})`,
                hide: true,
                valueGetter: (row) => row.row.exceedance?.derogation?.derogation?.description
            },
            {
                field: "legalEntity",
                headerName: intl.formatMessage({ id: "THRESHOLD.LEGAL_ENTITY" }),
                hide: true,
                valueGetter: (row) => row.row.exceedance?.derogation?.derogation?.legalEntity
            },
            {
                field: "derogationLocation",
                headerName: `${intl.formatMessage({ id: "LOCATION" })} (${intl.formatMessage({ id: "DEROGATION" })})`,
                hide: true,
                valueGetter: (row) => !row.row.exceedance?.derogation?.derogation?.locationId ? undefined : locationGroups.find(loc => loc._id === row.row.exceedance.derogation.derogation.locationId)?.name
            },
            {
                field: "derogationNote",
                headerName: `${intl.formatMessage({ id: "NOTE" })} (${intl.formatMessage({ id: "DEROGATION" })})`,
                hide: true,
                valueGetter: (row) => row.row.exceedance?.derogation?.derogation?.note
            },
            {
                field: "derogationStartDate",
                headerName: `${intl.formatMessage({ id: "DATE_START" })} (${intl.formatMessage({ id: "DEROGATION" })})`,
                hide: true,
                valueGetter: (row) => !row.row.exceedance?.derogation?.derogation?.dateRange?.startDate ? undefined : formatDateLocale(row.row.exceedance?.derogation?.derogation?.dateRange?.startDate)
            },
            {
                field: "derogationEndDate",
                headerName: `${intl.formatMessage({ id: "DATE_END" })} (${intl.formatMessage({ id: "DEROGATION" })})`,
                hide: true,
                valueGetter: (row) => !row.row.exceedance?.derogation?.derogation?.dateRange?.endDate ? undefined : formatDateLocale(row.row.exceedance?.derogation?.derogation?.dateRange?.endDate)
            },
            {
                field: 'healthAffected',
                type: 'boolean',
                headerName: intl.formatMessage({ id: "REGISTRY_ASSESMENT_FLAG.TABLE" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => row.row.healthAffected,
                valueFormatter: (params) => params?.value === undefined || params?.value === null ? intl.formatMessage({ id: "REGISTRY_ASSESMENT_FLAG.UNKNOWN" })
                    : params?.value === 1 ? intl.formatMessage({ id: "REGISTRY_ASSESMENT_FLAG.YES" })
                        : params?.value === 0 ? intl.formatMessage({ id: "REGISTRY_ASSESMENT_FLAG.NO" })
                            : null,
                renderCell: ({ value }) => {
                    if (value === undefined || value === null) return <FormattedMessage id="REGISTRY_ASSESMENT_FLAG.UNKNOWN" />
                    else if (value !== undefined && value) return <FormattedMessage id="REGISTRY_ASSESMENT_FLAG.YES" />;
                    else return <FormattedMessage id="REGISTRY_ASSESMENT_FLAG.NO" />;
                },
                minWidth: 150
            },
            {
                field: 'notificationMethod',
                headerName: intl.formatMessage({ id: "NOTIFICATION_TYPE" }),
                ...(!smallScreen && { flex: 1 }),
                valueGetter: (row) => row.row.notificationMethod?.join(","),
                valueFormatter: (params) => params?.value !== '' ? params?.value?.split(",").map((notificationMethod) => intl.formatMessage({ id: `NOTIFICATION_OPTION.${notificationMethod}` })) : null,
                renderCell: (row) => <>
                    {row.row.notificationMethod?.length > 0 ?
                        row.row.notificationMethod.length > 1 ?
                            <>
                                <Typography>
                                    {intl.formatMessage({ id: `NOTIFICATION_OPTION.${row.row.notificationMethod[0]}` })}
                                </Typography>
                                <span>
                                    <Tooltip title={row.row.notificationMethod.slice(1).map(el => intl.formatMessage({ id: `NOTIFICATION_OPTION.${el}` })).join(", ")} arrow>
                                        <Chip sx={{ ml: 0.5 }} size="small" variant="outlined" label={<span style={{ textTransform: 'lowercase' }}>{row.row.notificationMethod.length - 1} <FormattedMessage id="MORE" /></span>} />
                                    </Tooltip>
                                </span>
                            </>
                            :
                            <Typography>
                                {intl.formatMessage({ id: `NOTIFICATION_OPTION.${row.row.notificationMethod}` })}
                            </Typography>
                        : <Typography component="span" color="error"><FormattedMessage id="POPULATION_INFORMED.NOT" /></Typography>
                    }
                </>,
                minWidth: 240
            },
            {
                field: "informed",
                headerName: intl.formatMessage({ id: "INCIDENT.INFORMED" }),
                hide: true,
                valueGetter: (row) => intl.formatMessage({ id: !!row.row.nonComplianceReport?.populationNotified ? "YES" : "NO" })
            },
            {
                field: "noteText",
                headerName: intl.formatMessage({ id: "NOTE_TEXT" }),
                hide: true,
                valueGetter: (row) => row.row.nonComplianceReport?.populationNotified?.notification?.text
            },
            {
                field: "noteLink",
                headerName: intl.formatMessage({ id: "LINK" }),
                hide: true,
                valueGetter: (row) => row.row.nonComplianceReport?.populationNotified?.notification?.link
            },
            {
                field: "restrictionFlag",
                headerName: intl.formatMessage({ id: "RESTRICTION_FLAG" }),
                hide: true,
                valueGetter: (row) => intl.formatMessage({ id: !!row.row.nonComplianceReport?.populationNotified?.restriction ? "YES" : "NO" })
            },
            {
                field: "restrictionType",
                headerName: intl.formatMessage({ id: "RESTRICTION.TYPE" }),
                hide: true,
                valueGetter: (row) => !row.row.nonComplianceReport?.populationNotified?.restriction?.description ? undefined : intl.formatMessage({ id: "E" + row.row.nonComplianceReport.populationNotified.restriction.description })
            },
            {
                field: "restrictionStart",
                headerName: intl.formatMessage({ id: "RESTRICTION.STARTED" }),
                hide: true,
                valueGetter: (row) => !row.row.nonComplianceReport?.populationNotified?.restriction?.dateStart ? undefined : formatDateLocale(row.row.nonComplianceReport.populationNotified.restriction.dateStart)
            },
            {
                field: "restrictionEnd",
                headerName: intl.formatMessage({ id: "RESTRICTION.ENDED" }),
                hide: true,
                valueGetter: (row) => !row.row.nonComplianceReport?.populationNotified?.restriction?.dateEnd ? undefined : formatDateLocale(row.row.nonComplianceReport.populationNotified.restriction.dateEnd)
            },
            {
                field: "restrictionNumberOfDays",
                headerName: intl.formatMessage({ id: "NUMBER_OF_DAYS_RESTRICTION" }),
                hide: true,
                valueGetter: (row) => !(row.row.nonComplianceReport?.populationNotified?.restriction?.dateStart && row.row.nonComplianceReport?.populationNotified?.restriction?.dateEnd) ? undefined : intervalToDays({ from: row.row.nonComplianceReport.populationNotified.restriction.dateStart, to: row.row.nonComplianceReport.populationNotified.restriction.dateEnd }),
                minWidth: 220,
            },
            {
                field: "repeatedSampling",
                headerName: intl.formatMessage({ id: "REPEATED_SAMPLE.LABEL" }),
                hide: true,
                valueGetter: (row) => intl.formatMessage({ id: !!row.row.nonComplianceReport?.repeatedSampling ? "YES" : "NO" })
            },
            {
                field: "nonComplianceDateEnd",
                headerName: intl.formatMessage({ id: "INCIDENT_END_DATE" }),
                hide: true,
                valueGetter: (row) => !row.row.nonComplianceReport?.nonComplianceDateEnd ? undefined : formatDateLocale(row.row.nonComplianceReport.nonComplianceDateEnd)
            },
            {
                field: "description",
                headerName: intl.formatMessage({ id: "DESCRIPTION" }),
                hide: true,
                valueGetter: (row) => row.row.nonComplianceReport?.incidentDescription
            },
            {
                field: 'actions',
                headerName: intl.formatMessage({ id: 'ACTIONS' }),
                type: 'actions',
                getActions: (el) => {
                    const actions = [
                        <IconButton color="primary" key="view" onClick={() => navigate('/incidents/details/' + el.id)}><PreviewOutlined fontSize='small' /></IconButton>,
                    ]
                    if (!showArchived) actions.push(<IconButton color="primary" key="edit" onClick={() => navigate('/incidents/edit/' + el.id)} ><EditOutlined fontSize="small" /></IconButton>);
                    if (showArchived && deletePermission) actions.push(<IconButton color="error" key="delete" onClick={() => { setOpenConfirmDialog(true); setIncidentId(el.row.id) }}><DeleteOutline fontSize="small" /></IconButton>);
                    return actions;
                },
                minWidth: 150
            }
        ]

        if (loading && reset === 0) return <LoadingData />;
        else if (incidents.length >= 0) {
            return <CustomDataGrid enableExport handleData={(row) => setDrawerDetails(row.row)} rows={incidents} columns={headers} sortModel={{ field: 'timestamp', sort: 'desc' }} setSelectedRows={archivePermission ? setSelectedRows : undefined}
                page={page} rowsPerPage={pageSize} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} />
        } else if (incidents.length < 0) {
            return <Alert severity="warning"><FormattedMessage id='NO_DATA' /></Alert>
        }
    }, [intl, incidents, smallScreen, loading, reset, showArchived, deletePermission, archivePermission, incidentExceedanceCause, remedialActionTypes, pointComplianceType, repeatedSamplingPlaceTypes, controlTypes, derogationTypesConf, locationGroups, typology, navigate, dispatch, page, pageSize])

    const incidentForDelete = incidents.find(el => el._id === incidentId);

    return (
        <>
            <SideDrawer open={open} state={details} toggleDrawer={() => setOpen(false)} />
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <ConfirmationDialog
                open={openConfirmDialog}
                content={incidentForDelete && <Stack direction="column" gap={1}>
                    <DisplayBox label={<FormattedMessage id="INCIDENT.REGISTRYID" />} value={incidentForDelete.registryId} />
                    <DisplayBox label={<FormattedMessage id="INCIDENT.ZONE" />} value={incidentForDelete.zoneName} />
                    <DisplayBox label={<FormattedMessage id="TIMESTAMP" />} value={incidentForDelete.timestamp} />
                </Stack>}
                title={<FormattedMessage id="INCIDENT.CONFIRM.DELETE" />}
                customButtonTitle={<FormattedMessage id="DELETE" />}
                customButtonColor="error"
                handleCancel={() => setOpenConfirmDialog(false)}
                handleCustomButton={() => handleDeleteIncident()}
            />

            <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                <Card>
                    <CardHeader
                        title={<FormattedMessage id="INCIDENTS" />}
                        subheader={showArchived ? <FormattedMessage id="ARCHIVED_INCIDENTS" /> : null}
                        action={
                            <ButtonGroup variant="text" size="small">
                                {createPermission ? <Chip
                                    variant='outlined'
                                    color='primary'
                                    onClick={() => { navigate('/incidents/new') }}
                                    icon={<AddOutlined fontSize="small" color="primary" />} label={<FormattedMessage id="INCIDENT.ADD" />}
                                /> : null}
                                <Button sx={{ ml: 1 }} onClick={() => setShowArchived(!showArchived)}><FormattedMessage id={showArchived ? "SHOW_UNARCHIVED" : "SHOW_ARCHIVED"} /></Button>
                            </ButtonGroup>
                        }
                    />
                    <CardContent>
                        {renderTable()}
                    </CardContent>
                    {archivePermission ? <CardActions>
                        <Button onClick={() => { setArchiveDisabled(true); handleArchive(showArchived ? 'unarchive' : 'archive') }} disabled={archiveDisabled}><FormattedMessage id={showArchived ? "UNARCHIVE" : "ARCHIVE"} /></Button>
                    </CardActions> : null}
                </Card>
            </Stack>
        </>
    )
}

export default IncidentsTable;