import React, { useEffect, useRef, useState, cloneElement, Children, isValidElement, useCallback } from 'react';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { Cluster, OSM, Vector as VectorSource } from 'ol/source.js';
import {
    Circle as CircleStyle,
    Fill,
    Stroke,
    Style,
    Text,
} from 'ol/style.js';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { defaults } from 'ol/control/defaults';
import { SpeedDialAction, useTheme } from '@mui/material';
import { LocationOffOutlined, LocationOnOutlined } from '@mui/icons-material';
import { MapPopup, MARKERS } from 'components';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { selectFontSize, selectFontWeight } from 'redux/appSlice';
import { selectMeasurePoints } from 'redux/locationsSlice';
import { selectMapProps } from 'redux/mapSlice';
import MapGeoJSON from 'components/mapGeoJSON/MapGeoJSON';
import './mapStyle.css';

// SHAPE FILE LAYERS
const geoJSONLayer = new VectorLayer({
    source: new VectorSource({}),
    style: function (feature) {
        const S1 = new Style({
            fill: new Fill({
                color: '#42a5f533'
            }),
            stroke: new Stroke({
                color: '#1565c07f'
            })
        });
        const S2 = new Style({
            fill: new Fill({
                color: '#ba68c833'
            }),
            stroke: new Stroke({
                color: '#7b1fa27f'
            })
        });

        const NS = new Style({
            fill: new Fill({
                color: '#03a9f433'
            }),
            stroke: new Stroke({
                color: '#01579b7f'
            })
        });

        if (feature.geometryName === "settlements") {
            return [S1];
        } else if (feature.geometryName === "supplyZones") {
            return [S2];
        }
        else {
            return [NS];
        }
    }
});
const vectorSource = new VectorSource();
const vectorLayer = new VectorLayer();
const clusters = new VectorLayer();

export default function MapComponent(props) {
    const { position, zoom, className, children, customFeatures, disableInteraction, disableShapes, onClick, onUpdate } = props;
    const mapProps = useSelector(selectMapProps);
    const [coord] = useState([fromLonLat(mapProps.maxBounds[0]), fromLonLat(mapProps.maxBounds[1])])
    const [customClass, setCustomClass] = useState('map-main-container');
    const ref = useRef();
    const measurePoints = useSelector(selectMeasurePoints);
    const theme = useTheme();
    const fontSize = useSelector(selectFontSize);
    const fontWeight = useSelector(selectFontWeight);
    const [mapZoom, setMapZoom] = useState(zoom ? zoom : mapProps.zoomLevel);
    const [pinsFlag, setPinsFlag] = useState(false);

    useEffect(() => {
        const clusterFontWeight = fontWeight < 100 ? 'normal' : 'bold';
        if (vectorSource.getFeatures().length === 0) {
            vectorSource.addFeatures(measurePoints.map((loc) => {
                const feature = new Feature({
                    geometry: new Point(fromLonLat(loc.position)),
                    name: loc.name,
                    type: "LOCATION",
                    object: loc
                });
                feature.setStyle(MARKERS.measurePoint(theme.palette.mode));
                return feature;
            }))

            if (customFeatures && customFeatures.length) {
                vectorSource.addFeatures(customFeatures);
            }
            vectorLayer.setSource(vectorSource);
            clusters.setSource(new Cluster({
                distance: 25,
                minDistance: 100,
                source: vectorSource,
            }));
            const styleCache = {};
            clusters.setStyle(function (feature) {
                const size = feature.get('features').length;
                let style = styleCache[size];
                if (!style) {
                    style = new Style({
                        image: new CircleStyle({
                            radius: 12,
                            stroke: new Stroke({
                                color: '#fff',
                            }),
                            fill: new Fill({
                                color: '#1976d2',
                            }),
                        }),
                        text: new Text({
                            text: size.toString(),
                            fill: new Fill({
                                color: '#fff',
                            }),
                            justify: 'center',
                            textAlign: 'center',
                            offsetY: 1,
                            font: `${fontSize}px ${clusterFontWeight} sans-serif`
                        }),
                    });
                    styleCache[size] = style;
                }
                return style;
            });
        }
    }, [measurePoints, fontSize, theme, customFeatures, fontWeight]);

    //MAP
    const [theMap] = useState(new Map({
        showFullExtent: true,
        layers: [
            // Base Layer - OpenStreetMap
            new TileLayer({
                source: new OSM(),
            }),
            geoJSONLayer,
            // clusters/vectorLayer with location pins has to be last in array
            new VectorLayer({ source: new VectorSource() })
        ],
        view: new View({
            center: position ? fromLonLat(position) : fromLonLat([mapProps.defaultY, mapProps.defaultX]),
            zoom: mapZoom,
            minZoom: parseInt(mapProps.minZoom),
            maxZoom: parseInt(mapProps.maxZoom),
            extent: coord.flat()
        }),
        controls: defaults({
            zoom: !disableInteraction
        })
    }));

    const setShowPins = () => setPinsFlag(flag => {
        if (flag) {
            theMap.removeLayer(theMap.getAllLayers().pop());
        }
        else {
            theMap.addLayer(zoom ? vectorLayer : clusters);
        }
        return !flag;
    });

    theMap.getInteractions().forEach(i => {
        i.setActive(!disableInteraction)
    });

    const listener = useCallback((event) => {
        const newZoomLevel = (theMap.getView().getZoom());
        setMapZoom(newZoomLevel);

        if (newZoomLevel > mapProps.zoomThreshold) {
            // remove Clusters Layer
            theMap.removeLayer(theMap.getAllLayers().pop());
            // add Vector Layer with location pins
            theMap.addLayer(vectorLayer);

        }

        if (newZoomLevel < mapProps.zoomThreshold) {
            // remove Vector Layer
            theMap.removeLayer(theMap.getAllLayers().pop());
            // add Clusters Layers
            theMap.addLayer(clusters);
        }
    }, [theMap, mapProps.zoomThreshold]);

    useEffect(() => {
        if (pinsFlag) theMap.on('moveend', listener);
        else theMap.removeEventListener('moveend', listener);
    }, [theMap, pinsFlag, listener]);

    theMap.on("pointermove", function (evt) {
        let hit = theMap.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            return feature.get('name') !== 'Connections';
        });
        if (hit) theMap.getTargetElement().style.cursor = 'pointer';
        else if (theMap.getTargetElement().style.cursor !== 'grab') theMap.getTargetElement().style.cursor = '';
    });
    theMap.on("pointerup", function (evt) {
        theMap.getTargetElement().style.cursor = 'default';
    });
    theMap.on("pointerdown", function (evt) {
        let hit = theMap.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            return feature && feature.get('name') !== 'Connections';
        });
        if (hit) theMap.getTargetElement().style.cursor = 'pointer';
        else theMap.getTargetElement().style.cursor = 'grab';
    });
    theMap.on("pointerdrag", function (evt) {
        theMap.getTargetElement().style.cursor = 'grabbing';
    });

    useEffect(() => {
        const clickCB = (event) => onClick(event);
        if (typeof onClick === 'function') theMap.on('singleclick', clickCB);
        return () => theMap.un('singleclick', clickCB);
    }, [onClick, theMap])

    useEffect(() => {
        if (ref.current) {
            theMap.setTarget(ref.current)
        }

        return () => theMap.setTarget(undefined);
    }, [ref, theMap, zoom]);

    useEffect(() => {
        const mapClass = "map-main-container";
        if (className !== mapClass && !!className) setCustomClass(mapClass + ' ' + className);
        else setCustomClass(mapClass);
    }, [className, setCustomClass]);

    const childrenWithProps = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { map: theMap });
        }
        return child;
    });

    const removeFeature = (id) => {
        const feature = customFeatures.find(feature => feature.get('object')._id === id);
        vectorLayer.getSource().removeFeature(feature)
    }

    return (<div ref={ref} className={customClass}>
        <MapGeoJSON disabled={disableShapes} theMap={theMap} geoJSONLayer={geoJSONLayer} mapZoom={mapZoom} customIcons={[
            <SpeedDialAction
                onClick={setShowPins}
                key={"toggle-location-pins"}
                icon={!pinsFlag ? <LocationOffOutlined /> : <LocationOnOutlined color="primary" />}
                tooltipTitle={<FormattedMessage id={(!pinsFlag ? "SHOW" : "HIDE") + "_PINS"} />}
                tooltipPlacement="left"
            />
        ]} />
        <MapPopup disabled={disableInteraction} theMap={theMap} onUpdate={onUpdate} onDelete={removeFeature} />
        {children ? childrenWithProps : null}
    </div >);

}