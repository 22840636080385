import React, { useState } from 'react';
import MenuItems from './MenuItems';
import { Toolbar, Drawer, FormControlLabel, Switch, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

const CustomMenuDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(40),
            },
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                [theme.breakpoints.down('sm')]: {
                    width: 0,
                },
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(8),
                },
            }),
        },
    }),
);

export default function MenuDrawer(props) {
    const { open, selectedItem, admin } = props;

    const [keepOpen, setKeepOpen] = useState(localStorage.getItem('openDrawer') === 'true');

    const toggledrawerHandler = () => {
        localStorage.setItem('openDrawer', !keepOpen)
        setKeepOpen(!keepOpen);
    }

    return (
        <CustomMenuDrawer variant="permanent" open={open}>
            <Toolbar />
            <MenuItems open={open} selectedItem={selectedItem} secondaryItems={admin} />
            <FormControlLabel control={<Switch size='small' checked={keepOpen} onChange={toggledrawerHandler}/>} label={<Typography variant="caption" component="span"><FormattedMessage id={keepOpen ? "KEEP_OPEN" : "KEEP_CLOSED"} /></Typography>} sx={{ position: 'absolute', bottom: 0, m: 1, display: open ? 'inherit' : 'none' }} />
        </CustomMenuDrawer>
    );
};