import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { selectLocationDetails } from 'redux/locationsSlice'
import { userSelector, permissionSelector } from "redux/userSlice";
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { SamplesView, LoginRequired } from "components";

function SamplesPage(props) {
    const [searchparams] = useSearchParams();
    const locationId = searchparams.get("locationId");
    const location = useSelector((state) => selectLocationDetails(state, locationId));

    if (!locationId) return <EmptyState component={<SamplesView />} />;
    else if (location) return <EmptyState component={<SamplesView location={location} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

export default function SamplesPageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewSamplePermission = useSelector((state) => permissionSelector(state, 'view-sample'));

    if (loggedIn && viewSamplePermission) return <DefaultLayout page={<SamplesPage />} />;
    else if (loggedIn) return <DefaultLayout page={<EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />} />;
    else return <PublicLayout page={<LoginRequired />} />;
};


