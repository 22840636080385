import React from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { permissionSelector } from 'redux/userSlice';
import { useSelector } from 'react-redux';
import { selectStagingSamplesFeature } from 'redux/configurationSlice';
import { ListItemButton, ListItemIcon, ListItemText, Divider, List, Tooltip } from '@mui/material';
import { AddchartOutlined, BarChartOutlined, ContentPasteOutlined, LocationOnOutlined, LocationCityOutlined, MapOutlined, NotificationImportantOutlined, ShowChartOutlined, NoteAddOutlined, GavelOutlined, DataThresholdingOutlined, ReportProblemOutlined } from '@mui/icons-material';


const navArray = [
  {
    item: 'LocationsPage',
    onClick: (navigateCallback) => navigateCallback("/locations"),
    iconElement: <LocationOnOutlined />,
    textElement: <FormattedMessage id="LOCATIONS" />
  },
  {
    item: 'MapPage',
    onClick: (navigateCallback) => navigateCallback("/map"),
    iconElement: <MapOutlined />,
    textElement: <FormattedMessage id="MAP" />
  },

];

const settlements = {
  item: 'SettlementsPage',
  onClick: (navigateCallback) => navigateCallback("/settlements"),
  iconElement: <LocationCityOutlined />,
  textElement: <FormattedMessage id="SETTLEMENTS" />
}

const thresholds = {
  item: 'ThresholdsPage',
  onClick: (navigateCallback) => navigateCallback("/thresholds"),
  iconElement: <DataThresholdingOutlined />,
  textElement: <FormattedMessage id="THRESHOLDS" />
}

const measurements = {
  item: 'MeasurementsPage',
  onClick: (navigateCallback) => navigateCallback("/measurements"),
  iconElement: <ShowChartOutlined />,
  textElement: <FormattedMessage id="MEASUREMENTS" />
}

const samples = {
  item: 'SamplesPage',
  onClick: (navigateCallback) => navigateCallback("/samples"),
  iconElement: <BarChartOutlined />,
  textElement: <FormattedMessage id="SAMPLES" />
}

const stagingSamples = {
  item: 'StagingSamplesPage',
  onClick: (navigateCallback) => navigateCallback("/staging-samples"),
  iconElement: <GavelOutlined />,
  textElement: <FormattedMessage id="STAGING_SAMPLES" />
}

const formsArray = [
  {
    item: 'AddSamplePage',
    onClick: (navigateCallback) => navigateCallback("/sampleEntry"),
    iconElement: <AddchartOutlined />,
    textElement: <FormattedMessage id="ADD_SAMPLE" />
  },
  {
    item: 'AddSampleHistoryPage',
    onClick: (navigateCallback) => navigateCallback("/sampleEntry/history"),
    iconElement: <NoteAddOutlined />,
    textElement: <FormattedMessage id="ADD_SAMPLE_FILE" />
  },
]

const alarms = {
  item: 'AlarmsPage',
  onClick: (navigateCallback) => navigateCallback("/alarms"),
  iconElement: <NotificationImportantOutlined />,
  textElement: <FormattedMessage id="ALARMS" />
}

const notes = {
  item: 'Notes',
  onClick: (navigateCallback) => navigateCallback("/notes"),
  iconElement: <ContentPasteOutlined />,
  textElement: <FormattedMessage id="NOTES" />
}

const incidents = {
  item: 'IncidentsPage',
  onClick: (navigateCallback) => navigateCallback("/incidents"),
  iconElement: <ReportProblemOutlined />,
  textElement: <FormattedMessage id="REGISTRY_INCIDENT" />
}

export default function MenuItems(props) {
  const { secondaryItems, selectedItem, open } = props;
  const createSampleRights = useSelector((state) => permissionSelector(state, 'create-sample'));
  const viewStagingSampleRights = useSelector((state) => permissionSelector(state, 'view-staging-sample'));
  const viewSettlementRights = useSelector((state) => permissionSelector(state, 'view-settlement'));
  const viewThresholdRights = useSelector((state) => permissionSelector(state, 'view-threshold'));
  const viewAlarmRights = useSelector((state) => permissionSelector(state, 'view-alarm'));
  const viewNotesRights = useSelector((state) => permissionSelector(state, 'view-notes'));
  const viewIncidentsRights = useSelector((state) => permissionSelector(state, 'view-report'));
  const viewMeasurementsRights = useSelector((state) => permissionSelector(state, 'view-measurements'));
  const viewSampleRights = useSelector((state) => permissionSelector(state, 'view-sample'));

  const stagingFeature = useSelector((state) => selectStagingSamplesFeature(state));
  const navigate = useNavigate();

  const CustomListItem = ({ item }) => <ListItemButton selected={item.item === selectedItem} onClick={() => item.onClick(navigate)}>
    <ListItemIcon>
      <Tooltip title={open ? "" : item.textElement} placement="right" arrow>
        {item.iconElement}
      </Tooltip>
    </ListItemIcon>
    <ListItemText primary={item.textElement} primaryTypographyProps={{ variant: "caption" }} />
  </ListItemButton>

  return <List component="nav" style={{ height: 1 }}>
    {navArray.map((item, i) => <CustomListItem key={item.item} item={item} />)}
    {viewSettlementRights && <CustomListItem key={settlements.item} item={settlements} />}
    {secondaryItems ?
      <>
        <Divider sx={{ my: 1 }} />
        {viewMeasurementsRights && <CustomListItem key={measurements.item} item={measurements} />}
        {viewSampleRights && <CustomListItem key={samples.item} item={samples} />}
        {viewThresholdRights && <CustomListItem key={thresholds.item} item={thresholds} />
        }
        {stagingFeature && viewStagingSampleRights && <CustomListItem key={stagingSamples.item} item={stagingSamples} />}
        {(!!createSampleRights || stagingFeature) &&
          <>
            <Divider sx={{ my: 1 }} />
            {formsArray.map((item, i) => <CustomListItem key={item.item} item={item} />)}
          </>
        }
        <Divider sx={{ my: 1 }} />
        {viewAlarmRights && <CustomListItem key={alarms.item} item={alarms} />}
        {viewIncidentsRights && <CustomListItem key={incidents.item} item={incidents} />}
        {viewNotesRights && <CustomListItem key={notes.item} item={notes} />}

      </>
      : null}
  </List>;
};