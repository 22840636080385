import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Typography, useMediaQuery, useTheme, Tooltip, IconButton } from "@mui/material";
import LocationDetailsForm from "./LocationDetailsForm";
import { useSelector, useDispatch } from "react-redux";
import { selectLocationDetails, updateLocationState, addLocation, selectZoneOrWaterPumpGroups, deleteLocation } from "redux/locationsSlice";
import { selectAllCounties, selectAllWaterTypes, selectPermissionsByKey } from "redux/configurationSlice";
import { selectMapProps } from "redux/mapSlice";
import { userSelector } from "redux/userSlice";
import { FormattedMessage, useIntl } from "react-intl";
import { DeleteOutlined } from "@mui/icons-material";
import { ConfirmationDialog } from 'components';

import API from "api";

export default function LocationDetailsDialogForm(props) {
    const { openFormDialog, handleCloseForm, successMsg, setSuccessMsg, locationId, locationGroupId, superGroupId, addSuperGroup } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const { token } = useSelector(userSelector);
    const mapProps = useSelector(selectMapProps);
    const location = useSelector(state => selectLocationDetails(state, locationId));
    const counties = useSelector(state => selectAllCounties(state));
    const waterTypes = useSelector(state => selectAllWaterTypes(state));
    const waterPumps = useSelector(state => selectZoneOrWaterPumpGroups(state, "waterPump"));
    const zones = useSelector(state => selectZoneOrWaterPumpGroups(state, "zone"));
    const locationGroup = locationGroupId && !superGroupId ? false : true;
    const superGroup = !locationGroupId && !superGroupId;
    const [errorMsg, setErrorMsg] = useState("");
    const [updatedLocation, setUpdatedLocation] = useState({});
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [enableDelete, setEnableDelete] = useState(false);
    const [deletedLocationName, setDeletedLocationName] = useState("");
    const intl = useIntl();

    const permissionList = useSelector(selectPermissionsByKey);
    const deleteLocationPermission = permissionList['delete-location'] ? true : false;
    const deleteGroupPermission = permissionList['delete-group'] ? true : false;
    const deleteSuperGroupPermission = permissionList['delete-supergroup'] ? true : false;

    useEffect(() => {
        if (locationGroup && deleteGroupPermission) setEnableDelete(true);
        else if (superGroup && deleteSuperGroupPermission) setEnableDelete(true)
        else if (!locationGroup && !superGroup && deleteLocationPermission) setEnableDelete(true);
        else setEnableDelete(false);

        return () => {
            setEnableDelete(false);
            setDeletedLocationName("");
        }
    }, [locationGroup, superGroup, deleteGroupPermission, deleteSuperGroupPermission, deleteLocationPermission]);


    const hasAnythingChanged = (obj1, obj2) => {
        for (const key in obj1) {
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
                return true
            }
        }
        return false
    }

    const handleSubmit = () => {
        const locationGroupID = locationGroupId ? locationGroupId : null;
        const image = updatedLocation.picture ? updatedLocation.picture : null

        const emptyLocation = {
            name: "",
            position: [mapProps.defaultY, mapProps.defaultX],
            description: { en: "", hr: "" },
            ...(superGroupId) && { superGroupId: superGroupId },
            ...(!locationGroup) && { measurePointType: 0 },
            ...(!locationGroup) && { resourceTypes: [] },
            ...(!locationGroup) && { serviceResourceTypes: [] },
            ...(!locationGroup) && { locationGroupId: locationGroupID }
        }

        if ((!locationId && !hasAnythingChanged(updatedLocation, emptyLocation)) || (location && !hasAnythingChanged(updatedLocation, location))) {
            setErrorMsg(<FormattedMessage id="NO_UPDATE_RESPONSE" />);
            setSuccessMsg("");
        }
        else if (updatedLocation.name === "" || updatedLocation.position.length === 0) {
            setErrorMsg(<FormattedMessage id="MISSING_REQUIRED_FIELDS" />);
            setSuccessMsg("");
        }
        else {
            // location changed
            const newLocation = {
                name: updatedLocation.name,
                longitude: updatedLocation.position[0],
                latitude: updatedLocation.position[1],
                description: updatedLocation.description,
                ...(superGroupId) && { superGroupId: superGroupId },
                ...(!locationGroup) && { measurePointType: updatedLocation.measurePointType },
                ...(!locationGroup) && { resourceTypes: updatedLocation.resourceTypes.join(',') },
                ...(!locationGroup) && { serviceResourceTypes: updatedLocation.serviceResourceTypes.join(',') },
                ...(!locationGroup) && { locationGroupId: locationGroupID },

                ...updatedLocation.typeId !== undefined && ({ typeId: updatedLocation.typeId }),
                ...(superGroupId) && ({ selectType: updatedLocation.selectType }),
                attributes: {
                    ...updatedLocation.county !== undefined && ({ county: updatedLocation.county }),
                    ...(superGroupId) && updatedLocation.zone && ({ zones: updatedLocation.zone }),
                    ...(superGroupId) && updatedLocation.waterPump && ({ waterPumps: updatedLocation.waterPump }),
                    ...(superGroupId) && updatedLocation.waterType && ({ waterType: updatedLocation.waterType }),
                    ...(superGroupId) && ({ note: updatedLocation.note }),
                    ...(superGroupId) && updatedLocation.settlement && ({ settlement: updatedLocation.settlement }),

                }
            }

            let type = superGroup ? "superGroup" : (locationGroup ? "locationGroup" : "location");
            
            if (locationId) {
                const updateFunction = superGroup ? API.locations.updateSuperGroup : (locationGroup ? API.locations.updateLocationGroup : API.locations.updateLocation);
                // call API to update location/locationGroup
                updateFunction(token, locationId, newLocation, image).then(({ data }) => {
                    // set success message and dispatch update
                    setSuccessMsg(<FormattedMessage id="SUBMIT_LOCATION_RESPONSE" />);
                    dispatch(updateLocationState(data));
                    // after timeout close dialog
                    setTimeout(() => {
                        handleCloseForm();
                        setSuccessMsg("");
                    }, 1500);
                }).catch(e => {
                    if (e.response?.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
                    else setErrorMsg(intl.formatMessage({ id: (e.data && e.data.id) || "APP.ERROR" }));
                });
            }
            else {
                const createFunction = superGroup ? API.locations.createSuperGroup : (locationGroup ? API.locations.createLocationGroup : API.locations.createLocation);
                // call API to create location/locationGroup
                createFunction(token, newLocation, image).then(({ data }) => {
                    // set success message and dispatch update
                    setSuccessMsg(<FormattedMessage id="SUBMIT_LOCATION_RESPONSE" />);
                    dispatch(addLocation({ data, type }));
                    // after timeout close dialog
                    setTimeout(() => {
                        handleCloseForm();
                        setSuccessMsg("");
                    }, 1500);
                }).catch(e => {
                    if (e.response && e.response.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
                    else setErrorMsg(intl.formatMessage({ id: (e.data && e.data.id) || "APP.ERROR" }));
                });
            }
        }
    }

    const handleDeleteLocation = () => {
        const deleteFunction = superGroup ? API.locations.deleteSupergroup : (locationGroup ? API.locations.deleteGroup : API.locations.deleteLocation);
        let type = superGroup ? "superGroup" : (locationGroup ? "locationGroup" : "location");

        deleteFunction(token, locationId).then(({ data }) => {
            // set success message and dispatch update
            setSuccessMsg(<FormattedMessage id="DELETE_LOCATION_RESPONSE" />);
            dispatch(deleteLocation({ data, type }));
            setOpenConfirmDialog(false);
            setDeletedLocationName(data.name);
            setTimeout(() => {   
                handleCloseForm();
                setSuccessMsg("");
                setDeletedLocationName("");
            }, 1500);
        }).catch(e => {
            if (e.response && e.response.status === 403) setErrorMsg(<FormattedMessage id="ACCESS_DENIED" />);
            else setErrorMsg(intl.formatMessage({ id: (e.data && e.data.id) || "APP.ERROR" }));
        });
    }

    return (
        <>
            <ConfirmationDialog
                open={openConfirmDialog}
                title={<FormattedMessage id={superGroup ? "DELETE_SUPER_GROUP" : (locationGroup ? "DELETE_LOCATION_GROUP" : "DELETE_LOCATION")} />} 
                customButtonTitle={<FormattedMessage id="DELETE" />}
                customButtonColor="error"
                handleCancel={() => setOpenConfirmDialog(false)}
                handleCustomButton={() => handleDeleteLocation()}
            />
            
            <Dialog
                open={openFormDialog}
                fullScreen={fullScreen}
                fullWidth
                maxWidth='md'
                aria-labelledby="location-details-form-title"
                scroll="paper"
            >
                <DialogTitle id="location-details-form-title">
                    <FormattedMessage id={deletedLocationName !== '' ? "DELETE" : addSuperGroup ? "ADD_SUPER_GROUP" : locationGroup ?
                        locationId ? "UPDATE" : "ADD_LOCATION_GROUP" :
                        locationId ? "UPDATE" : "ADD_LOCATION"}
                    />
                    {deletedLocationName !== "" ? deletedLocationName : location ? location?.name : ''}
                    {enableDelete && location &&
                        <Tooltip title={<FormattedMessage id={"DELETE"} />} arrow>
                            <IconButton color="error" sx={{ float: 'right' }} onClick={() => setOpenConfirmDialog(true)} > <DeleteOutlined /> </IconButton>
                        </Tooltip>
                    }
                    {!successMsg ? <Typography variant='caption' sx={{ display: "block", color: theme.palette.warning.main }}><FormattedMessage id="LOCATION_SUBTITLE" /></Typography> : null}
                    
                    <Divider />
                </DialogTitle>

                {successMsg ?
                    <DialogContent>
                        <DialogContentText sx={{ textAlign: 'center', color: theme.palette.text.primary }}>{successMsg}</DialogContentText>
                    </DialogContent>
                    :
                    <>
                        <DialogContent sx={fullScreen ? null : { px: 8 }}>
                            {errorMsg ? <DialogContentText sx={{ textAlign: 'center', color: theme.palette.error.main }}>{errorMsg}</DialogContentText> : null}

                            <LocationDetailsForm
                                locationId={locationId}
                                location={location}
                                locationGroup={locationGroup}
                                locationGroupId={locationGroupId}
                                superGroupId={superGroupId}
                                setUpdatedLocation={setUpdatedLocation}
                                setErrorMsg={setErrorMsg}
                                counties={counties}
                                waterTypes={waterTypes}
                                waterPumps={waterPumps}
                                zones={zones}
                            />

                        </DialogContent>
                        <Divider variant="middle" sx={{ mt: 1, mx: 3 }} />
                        <DialogActions sx={{ mx: 2 }}>
                            <Button color="warning" onClick={handleCloseForm}><FormattedMessage id="CANCEL" /></Button>
                            <Button disabled={!!errorMsg} onClick={handleSubmit}><FormattedMessage id="SUBMIT" /></Button>
                        </DialogActions>
                    </>
                }
            </Dialog>
        </>
    );
}