import instance, { setAuthHeader } from './axiosEnv';
import { localISOString } from 'utils';

const getAllThresholds = (token, dateFrom, dateTo, derogation, locationIds) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    queryParams.append('derogation', derogation);
    if (derogation) {
        if (dateFrom) queryParams.append('dateFrom', localISOString(dateFrom));
        if (dateTo) queryParams.append('dateTo', localISOString(dateTo));

        for (let el in locationIds) {
            queryParams.append('locationId', locationIds[el])
        }
    }

    return instance.get('/thresholds?' + queryParams.toString(), config);
}

const getOneThreshold = (token, thresholdId) => {
    const config = setAuthHeader(token);
    return instance.get(`/thresholds/${thresholdId}`, config)
}

const getDerogation = (token, locationId, resourceTypeKey) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('resourceType', resourceTypeKey);
    return instance.get(`/thresholds/${locationId}/derogation?` + queryParams.toString(), config);
}

const getLocationThresholds = (locationId) => {
    return instance.get(`/thresholds/${locationId}/thresholds`)
}

const updateThreshold = (token, thresholdId, newThreshold) => {
    const config = setAuthHeader(token);
    return instance.put(`thresholds/${thresholdId}`, newThreshold, config)
}

const updateDerogation = (token, thresholdId, newThreshold) => {
    const config = setAuthHeader(token);
    return instance.put(`thresholds/${thresholdId}/derogation`, newThreshold, config)
}

const postThreshold = (token, newThreshold) => {
    const config = setAuthHeader(token);
    return instance.post('thresholds', newThreshold, config)
}

const postDerogation = (token, newThreshold) => {
    const config = setAuthHeader(token);
    return instance.post('thresholds/derogation', newThreshold, config)
}

const deleteThreshold = (token, thresholdId) => {
    const config = setAuthHeader(token);
    return instance.delete(`thresholds/${thresholdId}`, config)
}

const deleteDerogation = (token, thresholdId) => {
    const config = setAuthHeader(token);
    return instance.delete(`thresholds/${thresholdId}/derogation`, config)
}

const thresholdsAPI = {
    getAllThresholds,
    getDerogation,
    getOneThreshold,
    getLocationThresholds,
    updateThreshold,
    postThreshold,
    updateDerogation,
    postDerogation,
    deleteThreshold,
    deleteDerogation,
}
export default thresholdsAPI;