import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { Box, Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { QueryStatsOutlined, ContentPasteOutlined, BarChartOutlined, ShowChartOutlined, AssessmentOutlined, AddchartOutlined, NoteAddOutlined, MapsUgcOutlined, ContentPasteSearchOutlined } from '@mui/icons-material';
import { NewNoteDialog } from 'components';
import { useSelector } from 'react-redux';
import { permissionSelector } from 'redux/userSlice';

const DateRangeSelector = (props) => {
    const { column, locationId, removeSampleButtons } = props;
    const navigate = useNavigate();
    const [anchorElRep, setAnchorElRep] = useState(null);
    const [anchorElSam, setAnchorElSam] = useState(null);
    const [anchorElNot, setAnchorElNot] = useState(null);
    const openRep = Boolean(anchorElRep);
    const openSam = Boolean(anchorElSam);
    const openNot = Boolean(anchorElNot);
    const [open, setOpen] = useState(false);
    const samplerRights = useSelector((state) => permissionSelector(state, 'create-sample'));
    const noterRights = useSelector((state) => permissionSelector(state, 'create-notes'));
    const viewNotesPermission = useSelector((state) => permissionSelector(state, 'view-notes'));
    const viewMeasurementsPermission = useSelector((state) => permissionSelector(state, 'view-measurements'));
    const viewSamplePermission = useSelector((state) => permissionSelector(state, 'view-sample'));

    const handleClickSam = (event) => {
        setAnchorElSam(event.currentTarget);
    };
    const handleClickRep = (event) => {
        setAnchorElRep(event.currentTarget);
    };

    const handleClickNote = (event) => {
        if (!noterRights || !noterRights.includes(locationId)) navigate('/notes/' + locationId);
        else setAnchorElNot(event.currentTarget);
    };

    const handleCloseSam = () => {
        setAnchorElSam(null);
    };
    const handleCloseRep = () => {
        setAnchorElRep(null);
    };
    const handleCloseNot = () => {
        setAnchorElNot(null);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: column ? 'column' : 'row', gap: '8px', pt: 1 }}>
            <Box>
                {(viewMeasurementsPermission || viewSamplePermission) && <Button
                    startIcon={<QueryStatsOutlined />}
                    onClick={handleClickRep}
                    name='reports' key="reports"
                >
                    <FormattedMessage id="REPORTS" />
                </Button>}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElRep}
                    open={openRep}
                    onClose={handleCloseRep}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {viewMeasurementsPermission && <MenuItem onClick={() => navigate('/measurements?locationId=' + locationId)}>
                        <ListItemIcon>
                            <ShowChartOutlined fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="MEASUREMENTS" />
                        </ListItemText>
                    </MenuItem>}

                    {viewSamplePermission && <MenuItem onClick={() => navigate('/samples?locationId=' + locationId)}>
                        <ListItemIcon>
                            <BarChartOutlined fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="SAMPLES" />
                        </ListItemText>
                    </MenuItem>}
                </Menu>
            </Box>
            {!removeSampleButtons && samplerRights && samplerRights.includes(locationId) && <Box>
                <Button
                    startIcon={<AssessmentOutlined />}
                    onClick={handleClickSam}
                    name='reports' key="reports"
                >
                    <FormattedMessage id="SAMPLES" />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElSam}
                    open={openSam}
                    onClose={handleCloseSam}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => navigate('/sampleEntry?locationId=' + locationId)}>
                        <ListItemIcon>
                            <AddchartOutlined fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="ADD_SAMPLE" />
                        </ListItemText>
                    </MenuItem>

                    <MenuItem onClick={() => navigate('/sampleEntry/history?locationId=' + locationId)}>
                        <ListItemIcon>
                            <NoteAddOutlined fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="ADD_SAMPLE_FILE" />
                        </ListItemText>
                    </MenuItem>
                </Menu>
            </Box>}
            <Box>
                {viewNotesPermission && <Button
                    startIcon={<ContentPasteOutlined />}
                    onClick={handleClickNote}
                    name='notes' key="notes"
                >
                    <FormattedMessage id="NOTES" />
                </Button>}
                <Menu
                    id="basic-menu"
                    anchorEl={anchorElNot}
                    open={openNot}
                    onClose={handleCloseNot}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {<MenuItem onClick={() => setOpen(true)}>
                        <ListItemIcon>
                            <MapsUgcOutlined fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="ADD_NOTE" />
                        </ListItemText>
                    </MenuItem>}

                    <MenuItem onClick={() => navigate('/notes?locationId=' + locationId)}>
                        <ListItemIcon>
                            <ContentPasteSearchOutlined fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="OPEN_NOTES" />
                        </ListItemText>
                    </MenuItem>
                    <NewNoteDialog open={open} handleDialogClose={() => setOpen(false)} locationId={locationId} lockLocation />
                </Menu>
            </Box>
        </Box>
    );
}

export default DateRangeSelector;