import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { AlarmsProfileView, SingleAlarmProfileView, LoginRequired } from 'components';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';
import { FormattedMessage } from 'react-intl';


function AlarmsProfilePage(props) {
    const alarmProfileId = useParams().alarmProfileId;
    const [searchparams] = useSearchParams();
    const editFlag = searchparams.get("edit");

    if (!alarmProfileId) return <EmptyState component={<AlarmsProfileView />} />;
    else if (alarmProfileId) return <EmptyState component={<SingleAlarmProfileView alarmId={alarmProfileId} editFlag={editFlag} />} />;
}

export default function AlarmsProfilePageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewAlarmProfilePermission = useSelector((state) => permissionSelector(state, 'view-alarmProfiles'));


    if (loggedIn && viewAlarmProfilePermission) return <DefaultLayout page={<AlarmsProfilePage />} />;
    else if (loggedIn) return <DefaultLayout page={<EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />} />;
    else return <PublicLayout page={<LoginRequired />} />;
};