import instance, { setAuthHeader } from './axiosEnv';

const getSingleIncident = (token, incidentId) => {
    const config = setAuthHeader(token);
    return instance.get('/nonComplianceRegistry/' + incidentId, config);
}

const getAllIncidents = (token, archived) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();

    queryParams.append('archived', archived);
    return instance.get('/nonComplianceRegistry?' + queryParams.toString(), config);
}

const archiveIncidents = (token, incidentIds) => {
    const config = setAuthHeader(token);
    const body = {
        "nonComplianceRegistryIdArray": incidentIds
    };
    return instance.put('/nonComplianceRegistry/archive', body, config);
}

const unarchiveIncidents = (token, incidentIds) => {
    const config = setAuthHeader(token);
    const body = {
        "nonComplianceRegistryIdArray": incidentIds
    };
    return instance.put('/nonComplianceRegistry/unarchive', body, config);
}

const deleteIncident = (token, incidentId) => {
    const config = setAuthHeader(token);
    return instance.delete('/nonComplianceRegistry/' + incidentId, config);
}

const postIncident = (token, newIncident) => {
    const config = setAuthHeader(token);
    return instance.post('nonComplianceRegistry', newIncident, config)
}
const putIncident = (token, incident) => {
    const config = setAuthHeader(token);
    return instance.put('nonComplianceRegistry/' + incident.id, incident, config)
}


const incidentsAPI = {
    getSingleIncident,
    getAllIncidents,
    deleteIncident,
    postIncident,
    putIncident,
    archiveIncidents,
    unarchiveIncidents,
}

export default incidentsAPI;
