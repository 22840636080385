import React from 'react';
import { useSelector } from 'react-redux';
import { selectSuperGroups, selectLocations } from 'redux/locationsSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { Autocomplete, Box, Button, ButtonGroup, Card, CardContent, CardHeader, Stack, TextField, useMediaQuery } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB, hr } from 'date-fns/locale';

const ThresholdFilters = ({ derogation, setDerogation, selectedLocationGroups, setSelectedLocationGroups, dateFrom, setDateFrom, dateTo, setDateTo, action }) => {
    const locationGroups = useSelector(selectLocations);
    const superGroups = useSelector(selectSuperGroups);
    const mediumScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
    const intl = useIntl();

    return <Card>
        <CardHeader title={<FormattedMessage id="THRESHOLDS" />} action={action} />
        <CardContent>
            <Stack direction={mediumScreen ? "column" : "row"} spacing={2}>
                <ButtonGroup size="small" sx={{ alignSelf: 'center', minWidth: mediumScreen ? "50%" : "20rem" }}>
                    <Button variant={!derogation ? 'contained' : 'outlined'} onClick={() => setDerogation(false)}><FormattedMessage id="LEGALLY_STIPULATED" /></Button>
                    <Button variant={derogation ? 'contained' : 'outlined'} onClick={() => setDerogation(true)}><FormattedMessage id="DEROGATIONS" /></Button>
                </ButtonGroup>
                {derogation && <Box sx={{ width: "100%" }}>
                    <Autocomplete
                        multiple
                        size="small"
                        limitTags={3}
                        id="zone"
                        options={locationGroups}
                        getOptionLabel={(locationGroup) => `${locationGroup.name} (${superGroups.find(el => el._id === locationGroup.superGroupId)?.name}- ${superGroups.find(el => el._id === locationGroup.superGroupId)?.typeId} ) `}
                        renderInput={(params) => <TextField key={locationGroups.map(locationGroup => locationGroup._id)} {...params} label={<FormattedMessage id="INCIDENT.ZONE" />} />}
                        value={selectedLocationGroups}
                        onChange={(e, value) => setSelectedLocationGroups(value)}
                        filterSelectedOptions
                        fullWidth
                    />
                </Box>}
                {derogation && <Box sx={{ width: "100%" }}>
                    <Stack direction={mediumScreen ? "column" : "row"} spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={intl.locale === "hr" ? hr : enGB}>
                            <DatePicker
                                label={intl.formatMessage({ id: "DATE_FROM" })}
                                value={dateFrom ? new Date(dateFrom) : null}
                                onChange={(date) => setDateFrom(date)}
                                renderInput={(params) => <TextField {...params} size='small' fullWidth />}
                            />
                            <DatePicker
                                label={intl.formatMessage({ id: "DATE_TO" })}
                                value={dateTo ? new Date(dateTo) : null}
                                onChange={(date) => setDateTo(date)}
                                renderInput={(params) => <TextField {...params} size='small' fullWidth />}
                            />
                        </LocalizationProvider>
                    </Stack>
                </Box>}
            </Stack>
        </CardContent>
    </Card >
}

export default ThresholdFilters;