import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  initDone: true,
  darkTheme: JSON.parse(localStorage.getItem('darkTheme')) ? JSON.parse(localStorage.getItem('darkTheme')) : false,
  fontSize: JSON.parse(localStorage.getItem('fontSize')) ? JSON.parse(localStorage.getItem('fontSize')) : 14,
  fontWeight: JSON.parse(localStorage.getItem('fontWeight')) ? JSON.parse(localStorage.getItem('fontWeight')) : 0,
  lang: JSON.parse(localStorage.getItem('lang')) ? JSON.parse(localStorage.getItem('lang')) : navigator.language.split(/[-_]/)[0],
  incidentsTablePage: 0,
  incidentsTablePageSize: 25,
};


export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDarkTheme: (state, action) => {
      state.darkTheme = action.payload;
      localStorage.setItem('darkTheme', JSON.stringify(action.payload));
    },

    setLanguage: (state, action) => {
      state.lang = action.payload;
      localStorage.setItem('lang', JSON.stringify(action.payload));
    },

    setFontSize: (state, action) => {
      state.fontSize = action.payload;
      localStorage.setItem('fontSize', JSON.stringify(action.payload));
    },

    setFontWeight: (state, action) => {
      state.fontWeight = action.payload;
      localStorage.setItem('fontWeight', JSON.stringify(action.payload));
    },

    setIncidentsTablePage: (state, action) => {
      state.incidentsTablePage = action.payload;
    },

    setIncidentsTablePageSize: (state, action) => {
      state.incidentsTablePageSize = action.payload;
    },
  },

});

export const { setDarkTheme, setLanguage, setFontSize, setFontWeight, setIncidentsTablePage, setIncidentsTablePageSize, closeAlert } = appSlice.actions;

// SELECTORS

/** Ensure that Redux is ready */
export const selectInitDone = (state) => {
  if (state.locations.status === 'failed' || state.configuration.status === 'failed' || state.user.isError) return 'failed';
  return (state.configuration.status === 'complete' && state.locations.status === 'complete' && state.user.isDone);
}
/** Ensure that Redux is ready */
export const selectAuthDone = (state) => {
  if (state.user.isError) return 'failed';
  return state.user.isDone;
}

export const selectThemeMode = (state) => {
  return state.app.darkTheme ? "dark" : "light";
}

export const selectFontSize = (state) => {
  return state.app.fontSize;
}

export const selectFontWeight = (state) => {
  return state.app.fontWeight;
}

export const selectLanguage = (state) => {
  return state.app.lang;
}

export const selectIncidentsTablePage = (state) => {
  return state.app.incidentsTablePage;
}

export const selectIncidentsTablePageSize = (state) => {
  return state.app.incidentsTablePageSize;
}


export default appSlice.reducer;
