import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from 'api';

const initialState = {
  data: {
    superGroups: [],
    locationGroups: [],
    locations: []
  },
  status: 'idle',
  error: null,
  filters: {
    // filter for quality types (key in array are FILTERED_OUT!)
    statusCodes: [],
    // filter for location/measure point types (key in array are FILTERED_OUT!)
    locationTypes: []
  }
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchLocations = createAsyncThunk('locations/fetchLocations', async (token) => {
  const { getAllLocationData, getAllLocations } = API.locations;
  const getLocations = token === null ? getAllLocations : getAllLocationData;
  const response = await getLocations(token);
  // The value we return becomes the `fulfilled` action payload
  return response.data;
}
);

export const locationSlice = createSlice({
  name: 'locations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    addLocation: (state, action) => {
      switch (action.payload.type) {
        case "location":
          state.data.locations.push(action.payload.data);
          break;
        case "locationGroup":
          state.data.locationGroups.push(action.payload.data);
          break;
        case "superGroup":
          state.data.superGroups.push(action.payload.data);
          break;
        default:
          break;
      }
    },

    deleteLocation: (state, action) => {
      switch (action.payload.type) {
        case "location":
          const locationIndex = state.data.locations.findIndex(el => el._id === action.payload.data._id);
          if (locationIndex >= 0) state.data.locations.splice(locationIndex, 1);
          break;
        case "locationGroup":
          const groupIndex = state.data.locationGroups.findIndex(el => el._id === action.payload.data._id);
          if (groupIndex >= 0) state.data.locationGroups.splice(groupIndex, 1);
          break;
        case "superGroup":
          const superGroupIndex = state.data.superGroups.findIndex(el => el._id === action.payload.data._id);
          if (superGroupIndex >= 0) state.data.superGroups.splice(superGroupIndex, 1);
          break;
        default:
          break;
      }
    },

    updateLocationState: (state, action) => {
      // state.data is current state that we will modify
      // action.payload is our data object returned from db after update

      // 1. Update locationGroup info for its MP
      const locationGroupIndex = state.data.locationGroups.findIndex(el => el._id === action.payload._id);
      const locationIndex = state.data.locations.findIndex(el => el._id === action.payload._id);
      const supergroupIndex = state.data.superGroups.findIndex(el => el._id === action.payload._id);

      // if locationGroup existed before - we are updating location so this must be true
      if (locationGroupIndex > -1) {
        state.data.locationGroups[locationGroupIndex] = { ...action.payload }
      }
      else if (locationIndex > -1) {
        state.data.locations[locationIndex] = { ...action.payload };
      }
      else if (supergroupIndex > -1) {
        state.data.superGroups[supergroupIndex] = { ...action.payload };
      }

      // // if location existed in data
      // if (locationIndex > -1) {
      //   state.data[locationIndex] = {
      //     ...action.payload,
      //     connection: [
      //       [...state.data[locationGroupIndex].position],
      //       [...action.payload.position]
      //     ]
      //   };
      // }
      // else {
      //   state.data.push({
      //     ...action.payload,
      //     connection: [
      //       [...state.data[locationGroupIndex].position],
      //       [...action.payload.position]
      //     ]
      //   });
      // }
    },


    // updateLocationGroupState: (state, action) => {
    //   const locationGroupIndex = state.data.findIndex(el => el._id === action.payload._id);
    //   // if location group already existed
    //   if (locationGroupIndex > -1) {
    //     // check if position changed 
    //     if (JSON.stringify(state.data[locationGroupIndex].position) !== JSON.stringify(action.payload.position)) {
    //       // update connections array
    //       for (const locationElem of state.data) {
    //         if (locationElem.locationGroupId === action.payload._id)
    //           locationElem.connection[0] = action.payload.position;
    //       }
    //     }
    //     state.data[locationGroupIndex] = {
    //       description: action.payload.description,
    //       picture: action.payload.picture,
    //       statusCode: action.payload.statusCode,
    //       position: action.payload.position,
    //       _id: action.payload._id,
    //       name: action.payload.name,
    //       measurePoints: state.data[locationGroupIndex].measurePoints
    //     }
    //   }
    //   else {
    //     state.data.push({
    //       ...action.payload,
    //       measurePoints: []
    //     })
    //   }
    // },

    updateLocationStatusCode: (state, action) => {
      // { locationId, locationGroupId, groupStatusCode, locationStatusCode }
      // 1. Update locationGroup info for its MP
      const locationGroupIndex = state.data.locationGroups.findIndex(el => el._id === action.payload.locationGroupId);

      let locationStatusCode = action.payload.locationStatusCode
      if (locationGroupIndex > -1) {
        // update locationGroup's status code
        let groupStatusCode = {
          TSI: action.payload.groupStatusCode
        }
        state.data.locationGroups[locationGroupIndex].statusCode = groupStatusCode;
      }
      // 2. Update location
      const locationIndex = state.data.locations.findIndex(el => el._id === action.payload.locationId);
      if (locationIndex > -1) state.data.locations[locationIndex].statusCode = locationStatusCode;

    },
    /** Set date range by invididual dates  */
    toggleFilter: (state, action) => {
      const { filterType, filterValue } = action.payload;
      if (typeof filterValue !== 'object') {
        // find value in filter arrays
        const index = state.filters[filterType].indexOf(filterValue);
        // if index is found - remove it, if not found - add it
        (index > -1) ? state.filters[filterType].splice(index, 1) : state.filters[filterType].push(filterValue);
      }
      else {
        const foundIndex = state.filters[filterType].findIndex(el => el.key === filterValue.key && el.value === filterValue.value);
        (foundIndex > -1) ?
          state.filters[filterType].splice(state.filters[filterType].findIndex(el => el.key === filterValue.key && el.value === filterValue.value), 1)
          : state.filters[filterType].push(filterValue)
      }
    },
    clearFilters: (state, action) => {
      const { type } = action.payload ? action.payload : {};
      if (!type) state.filters = initialState.filters;
      else state.filters[type] = initialState.filters[type]
      return state;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLocations.rejected, (state) => {
        console.error("Failed to load locations...");
        state.status = 'failed';
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = 'complete';
        state.data = action.payload || initialState.data;
      });
  },
});

export const { toggleFilter, clearFilters, addLocation, updateLocationState, updateLocationStatusCode, deleteLocation } = locationSlice.actions;

// Selectors

// get locations group data
export const selectGroup = (state, locationId) => {
  const filterRef = (location) => locationId ? location.superGroupId === locationId : true;
  return state.locations.data.locationGroups?.filter(filterRef);
}

// get locations super group data
export const selectSuperGroups = (state, locationId) => {
  const filterRef = (location) => locationId !== undefined ? location._id === locationId : true;
  const result = state.locations.data.superGroups?.filter(filterRef);

  return locationId === undefined ? result : result?.at(0);
}

// get locations data root element (parent locations)
export const selectLocations = (state, locationId) => {
  const filterRef = (location) => !locationId ? location.locationGroupId === undefined : location._id === locationId && location.locationGroupId === undefined;
  return state.locations.data.locationGroups?.filter(filterRef);
}

export const selectMeasurePoints = (state, locationId) => {
  const filterRef = (location) => !locationId ? location.locationGroupId !== undefined : location._id === locationId && location.locationGroupId !== undefined;
  return state.locations.data.locations?.filter(filterRef);
}

export const selectGroupsMeasurePoints = (state, locationId) => {
  return state.locations.data.locations?.filter(location => location.locationGroupId === locationId);
}

// get locations and measure points data
export const selectAllLocations = (state) => state.locations.data.locations.concat(state.locations.data.locationGroups);

// get locations and measure points data for specific location group
export const selectAllLogoLocations = (state) => state.locations.data.filter(location => location.logo !== undefined);

// get locations data filters
export const selectLocationFilters = (state) => state.locations.filters;

// get location matching active filters (beware: it is a negative match - locations with keys present in filter array will be ommited)
export const selectFilteredLocations = (state) => (
  state.locations.data.filter(location => !state.locations.filters.locationTypes.includes(location.type) && !state.locations.filters.statusCodes.includes(location.statusCode))
);

// get single location from it's ID
export const selectLocationDetails = (state, locationId) => {
  const superGroup = state.locations.data.superGroups.find(location => location._id === locationId)
  const locationGroup = state.locations.data.locationGroups.find(location => location._id === locationId);
  const location = state.locations.data.locations.find(location => location._id === locationId);

  if (superGroup) return superGroup;
  if (locationGroup) return locationGroup;
  else return location;
};

// get single compliance location from it's ID
export const selectLocationCompliance = (state, locationId) => {
  let selectedLocation = state.locations.data.locations.find(location => location._id === locationId)
  if (!selectedLocation || !selectedLocation.statusCode || !selectedLocation.statusCode.COMPLIANCE) return null
  return selectedLocation.statusCode.COMPLIANCE
};

export const selectLocationGroup = (state, locationId) => {
  return state.locations.data.locations.find(measurePoints => measurePoints.locationGroupId === locationId)
};

// find parent location (if exist) of location with provided ID
export const selectLocationParent = (state, locationId) => {
  //return state.locations.data.find( location => location.measurePoints && location.measurePoints.length);
  const locationGroupId = state.locations.data.locations.find(location => location._id === locationId);
  return state.locations.data.locationGroups.find(el => el._id === locationGroupId);
};


// select supergroup
export const selectSuperGroup = (state, superGroupId) => {
  let superGroup = state.locations.data.superGroups.find(superGroup => superGroup._id === superGroupId)
  return { name: superGroup?.name, typeId: superGroup?.typeId ? superGroup?.typeId : null }
};

// Updated function to include parent information
export const selectZoneOrWaterPumpGroups = (state, type) => {
  const { locationGroups } = state.locations.data;
  const resultArray = [];
  for (const group of locationGroups) {
    if (group.type === type) {
      const parentInfo = selectSuperGroup(state, group.superGroupId);
      if (parentInfo) {
        resultArray.push({
          locationGroupId: group._id,
          locationGroupName: group.name,
          parentName: parentInfo.name,
          parentTypeId: parentInfo.typeId
        });
      }
    }
  }
  return resultArray;
};


export default locationSlice.reducer;