import { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { permissionSelector } from "redux/userSlice";
import { FormattedMessage, useIntl } from "react-intl";
import { LocationDetailsDialogForm } from "components";
import { Avatar, Chip, Card, CardContent, CardHeader, CardMedia, IconButton, Tooltip, Typography, Stack, CardActions, Button } from "@mui/material";
import { EditOutlined, ShareLocationOutlined, SensorsOutlined, LocationCityOutlined, LocalDrinkOutlined } from "@mui/icons-material";
import { selectGroupsMeasurePoints } from "redux/locationsSlice";

export default function LocationGroupCard(props) {
    const { location } = props;
    const navigate = useNavigate();
    const locale = useIntl().locale;

    const measurePoints = useSelector((state) => selectGroupsMeasurePoints(state, location._id))
    const groupAdmin = useSelector((state) => permissionSelector(state, 'update-group'));
    const viewSettlementRights = useSelector((state) => permissionSelector(state, 'view-settlement'));
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [locationId, setLocationId] = useState("");

    const handleClickOpenForm = (id) => {
        setLocationId(id);
        setOpenFormDialog(true);
        setSuccessMsg("");
    };

    const handleCloseForm = () => {
        setOpenFormDialog(false);
        setLocationId("");
    };

    return (
        <Card sx={{ width: '100%' }}>
            <CardMedia
                component="img"
                height="250"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate("/locations/group/" + location._id)}
                image={process.env.REACT_APP_API_BASE_URL + "/" + location.picture}
                alt="card-media"
            />
            <CardHeader
                title={<Typography variant={location.logo ? "h6" : "h5"}>{location.name}</Typography>}
                subheader={<Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0.5, sm: 1 }} justifyContent="flex-start" alignItems="flex-start">
                    <Chip size="small" icon={location.type === "zone" ? <ShareLocationOutlined /> : <LocalDrinkOutlined />} label={<FormattedMessage id={location.type === "zone" ? "ZONE" : "PUMPING_AREA"} />} />
                    <Chip size="small" icon={<SensorsOutlined />} label={<span>{measurePoints.length} <FormattedMessage id="MEASURE_POINTS" /></span>} />
                </Stack>}
                avatar={location.logo && <Avatar src={process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + location.logo} />}
                action={
                    [
                        groupAdmin && groupAdmin.includes(location._id) ?
                            <Tooltip key="editLocationGroupTooltip" title={<FormattedMessage id="UPDATE" />} arrow>
                                <IconButton key="editLocationGroup" onClick={() => handleClickOpenForm(location._id)} ><EditOutlined /></IconButton>
                            </Tooltip> : null
                    ]
                }
            />
            {location.description[locale]?.length > 0 && <CardContent>
                <Typography variant="body2">
                    {location.description[locale]}
                </Typography>
            </CardContent>}
            {Boolean(viewSettlementRights && location.type === 'zone') && <CardActions>
                <Button
                    startIcon={<LocationCityOutlined />}
                    onClick={() => navigate('/settlements?groupId=' + location._id)}
                    name='settlements' key="settlements"
                >
                    <FormattedMessage id="SETTLEMENTS" />
                </Button>
            </CardActions>}
            <LocationDetailsDialogForm
                openFormDialog={openFormDialog}
                handleCloseForm={handleCloseForm}
                successMsg={successMsg}
                setSuccessMsg={setSuccessMsg}
                locationId={locationId}
                superGroupId={location.superGroupId}
            />
        </Card >

    );
}