import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { userSelector } from "redux/userSlice";
import { selectLocations, selectSuperGroups } from 'redux/locationsSlice';
import { CustomDataGrid, LoadingData, AlertSnackbar, LocationInputField } from 'components';
import { Typography, useMediaQuery, Alert, CardContent, Card, CardHeader, Stack } from "@mui/material";
import { useIntl, FormattedMessage } from 'react-intl';
import API from "api";


const NoDataCell = ({ none }) => <Typography component="span" color="text.disabled"><FormattedMessage id={none ? "NONE" : "NO_DATA"} /></Typography>

const SettlementsView = (props) => {
    const allSuperGroups = useSelector(selectSuperGroups)
    const allLocationGroups = useSelector(selectLocations);
    const [locationGroupsOptions, setlocationGroupsOptions] = useState([]);

    const [settlements, setSettlements] = useState([]);

    const [selectedSuperGroups, setSelectedSuperGroups] = useState(props?.superGroupId ? [allSuperGroups.find(el => el._id === props.superGroupId)] : []);
    const [selectedLocationGroups, setSelectedLocationGroups] = useState(props?.group ? props.group : []);
    const [selectedGroupIds, setSelectedGroupIds] = useState([])

    const { token } = useSelector(userSelector);
    const [loading, setLoading] = useState(false);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const intl = useIntl();
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (selectedGroupIds.length > 0) {
            setLoading(true);

            API.settlements.getAllSettlements(token, selectedGroupIds).then((response) => {
                if (response.data) {
                    const data = response.data.map((item) => {
                        return {
                            id: item._id,
                            name: item.name,
                            population: item.population,
                            municipality: item.cityMunicipality?.name,
                            county: item.county?.name,
                            populationConnections: item.populationConnections,
                            settlementIndex: item.settlementIndex,
                            communalNeeds: item.deliveredWaterQuantity?.economy?.communalNeeds ? (item.deliveredWaterQuantity?.economy?.communalNeeds / 365).toFixed(2) : null,
                            institutions: item.deliveredWaterQuantity?.economy?.institution ? (item.deliveredWaterQuantity?.economy?.institution / 365).toFixed(2) : null,
                            irrigation: item.deliveredWaterQuantity?.economy?.irrigation ? (item.deliveredWaterQuantity?.economy?.irrigation / 365).toFixed(2) : null,
                            manufacturing: item.deliveredWaterQuantity?.economy?.manufacturing ? (item.deliveredWaterQuantity?.economy?.manufacturing / 365).toFixed(2) : null,
                            services: item.deliveredWaterQuantity?.economy?.services ? (item.deliveredWaterQuantity?.economy?.services / 365).toFixed(2) : null,
                            tourism: item.deliveredWaterQuantity?.economy?.tourism ? (item.deliveredWaterQuantity?.economy?.tourism / 365).toFixed(2) : null,
                            economyDay: item.deliveredWaterQuantity?.economyDay?.toFixed(2),
                            waterQuantityPopulation: item.deliveredWaterQuantity?.population ? (item.deliveredWaterQuantity?.population / 365).toFixed(2) : null,
                            populationDay: item.deliveredWaterQuantity?.populationDay?.toFixed(2),
                            populationEconomyDay: item.deliveredWaterQuantity?.populationEconomyDay?.toFixed(2)
                        }
                    });
                    setSettlements(data);
                    setLoading(false);
                } else {
                    setSettlements([])
                }
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                console.error('Settlements error', error);
                setSettlements(null);
                setLoading(false);
            })
        } else setSettlements([]);

    }, [token, selectedGroupIds]);

    useEffect(() => {
        const groups = [];
        if (selectedSuperGroups.length > 0) {
            selectedSuperGroups.forEach((superGroup) => {
                const filteredGroups = allLocationGroups.filter(el => el.superGroupId === superGroup._id);
                if (!!filteredGroups?.length) {
                    filteredGroups.forEach(group => groups.push(group))
                };
            })
            setlocationGroupsOptions(groups)
        } else {
            setSettlements([]);
            setSelectedLocationGroups([]);
            setlocationGroupsOptions([]);
            setSelectedGroupIds([]);
        }

    }, [allLocationGroups, selectedSuperGroups])

    useEffect(() => {
        const ids = [];

        selectedLocationGroups.forEach((locationGroup) => ids.push(locationGroup._id));
        setSelectedGroupIds(ids);

    }, [selectedLocationGroups])

    const renderTable = useCallback(() => {
        const headers = [
            {
                field: 'settlementIndex',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.SETTLEMENT_INDEX" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.settlementIndex} </Typography>,
                minWidth: 150
            },
            {
                field: 'name',
                sortable: false,
                headerName: intl.formatMessage({ id: "NAME" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.name} </Typography>,
                minWidth: 200
            },
            {
                field: 'population',
                sortable: false,
                headerName: intl.formatMessage({ id: "POPULATION_NUMBER" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.population} </Typography>,
                minWidth: 200
            },
            {
                field: 'municipality',
                sortable: false,
                headerName: intl.formatMessage({ id: "MUNICIPALITY" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.municipality} </Typography>,
                minWidth: 200
            },
            {
                field: 'county',
                sortable: false,
                headerName: intl.formatMessage({ id: "COUNTY" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.county} </Typography>,
                minWidth: 200
            },
            {
                field: 'populationConnections',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.POPULATION_CONNECTIONS" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.populationConnections ?? <NoDataCell />} </Typography>,
                minWidth: 200
            },
            {
                field: 'communalNeeds',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.COMMUNAL_NEEDS" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.communalNeeds ?? <NoDataCell />}  </Typography>,
                minWidth: 260
            },
            {
                field: 'institutions',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.INSTITUTIONS" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.institutions ?? <NoDataCell />}  </Typography>,
                minWidth: 260
            },
            {
                field: 'irrigation',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.IRRIGATION" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.irrigation ?? <NoDataCell />} </Typography>,
                minWidth: 260
            },
            {
                field: 'manufacturing',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.MANUFACTURING" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.manufacturing ?? <NoDataCell />} </Typography>,
                minWidth: 260
            },
            {
                field: 'services',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.SERVICES" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.services ?? <NoDataCell />} </Typography>,
                minWidth: 260
            },
            {
                field: 'tourism',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.TOURISM" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.tourism ?? <NoDataCell />} </Typography>,
                minWidth: 260
            },
            {
                field: 'economyDay',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.ECONOMY_DAY" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.economyDay ?? <NoDataCell />} </Typography>,
                minWidth: 260
            },
            {
                field: 'populationDay',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.POPULATION_DAY" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.populationDay ?? <NoDataCell />} </Typography>,
                minWidth: 260
            },
            {
                field: 'populationEconomyDay',
                sortable: false,
                headerName: intl.formatMessage({ id: "SETTLEMENTS.POPULATION_ECONOMY" }),
                ...(!smallScreen && { flex: 1 }),
                renderCell: (row) => <Typography> {row.row.populationEconomyDay ?? <NoDataCell />} </Typography>,
                minWidth: 250
            },
        ]

        if (loading) return <LoadingData />;
        else if (settlements === null) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>
        else if (settlements.length === 0) return <Alert severity="warning"><FormattedMessage id='NO_DATA' /></Alert>
        else if (settlements.length > 0) {
            return <CustomDataGrid rows={settlements} columns={headers} />
        }
    }, [intl, settlements, smallScreen, loading])


    return (
        <>
            <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
            <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                <Card >
                    <CardHeader title={<FormattedMessage id="SUPPLY_ZONES_DATA" />} subheader={<FormattedMessage id="SETTLEMENTS.SUBTITLE" />} />
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <LocationInputField multiple disableCloseOnSelect filterSelectedOptions key="water-suppliers" simpleLabels options={allSuperGroups} selectedLocation={selectedSuperGroups} setSelectedLocation={setSelectedSuperGroups} size="small" label={<FormattedMessage id="WATER_SUPPLY_SYSTEM" />} />
                        <LocationInputField multiple disableCloseOnSelect filterSelectedOptions key="zones" disabled={!selectedSuperGroups?.length} simpleLabels={selectedSuperGroups?.length <= 1} options={locationGroupsOptions} selectedLocation={selectedLocationGroups} setSelectedLocation={setSelectedLocationGroups} size="small" label={<FormattedMessage id="ZONE" />} />
                    </CardContent>
                </Card>
                {selectedLocationGroups?.length > 0 &&
                    <Card>
                        <CardHeader title={<FormattedMessage id="SETTLEMENTS" />} />
                        <CardContent>
                            {renderTable()}
                        </CardContent>
                    </Card>
                }
            </Stack>
        </>
    )
}
export default SettlementsView;