import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card, CardContent, CardHeader, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { FileDownloadOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { formatDateLocale, exportData } from 'utils';
import { selectStatusCodesConf, selectSamplingTypesConf, selectResourcesTypesConf } from 'redux/configurationSlice';
import { selectDateRange } from 'redux/dateRangeSlice';
import { selectLocationDetails } from 'redux/locationsSlice';
import { CustomDataGrid, DisplayResourceName, LoadingData, SamplingLogDetails, SideDrawer, AlertSnackbar, ComplianceTag } from 'components';

import API from 'api';

function SamplesTableContainer(props) {
    const { locations, qualityTypes, sampleTypes, pictureSample } = props;
    const { token } = useSelector(userSelector);
    const selectedDateRange = useSelector(selectDateRange);
    const samplesSampleTypes = useSelector(selectSamplingTypesConf);
    const statusCodes = useSelector(selectStatusCodesConf).COMPLIANCE;
    const resourcesTypes = useSelector(selectResourcesTypesConf);
    const singleLocation = useSelector(state => selectLocationDetails(state, locations[0]));
    const intl = useIntl();
    const [sampleData, setSampleData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [details, setDetails] = useState({});
    const [detailsDrawer, setDetailsDrawer] = useState(false);
    const [openDrawer, setDrawer] = useState(false);
    const [alert, setAlert] = useState({ open: false });
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(25);
    const [total, setTotal] = useState();
    const [loading, setLoading] = useState(false);
    const [reset, setReset] = useState(0);

    const onAlertClose = () => setAlert({ ...alert, open: false });

    const exportHeaders = [
        intl.formatMessage({ id: 'SAMPLING_LOG_REPORT.LOCATION_NAME' }),
        intl.formatMessage({ id: 'DATE' }),
        intl.formatMessage({ id: 'SAMPLING_LOG_REPORT.SAMPLE_TYPE' }),
        intl.formatMessage({ id: 'SAMPLING_LOG_REPORT.STATUS' }),
        intl.formatMessage({ id: 'NOTES' })
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExportChange = (option, print) => {
        setAlert({ open: true, messageId: "SPLASH.WAIT", severity: "info" });
        API.samplingLogs.getSamplingValues(token, locations, selectedDateRange.dateFrom, selectedDateRange.dateTo, qualityTypes, sampleTypes, pictureSample, 0, total)
            .then(items => {
                if (items.data) {
                    const data = items.data.docs.map(collection => {
                        const sampleType = samplesSampleTypes.find(el => el.key === collection.source.type).value;
                        const status = statusCodes.find(el => el.key === collection.statusCode.COMPLIANCE);
                        return {
                            id: collection._id,
                            locationName: collection.locationId.name,
                            date: formatDateLocale(collection.timestamp),
                            sampleType: intl.formatMessage({ id: 'SAMPLE_LOG.TYPE_' + sampleType }),
                            quality: intl.formatMessage({ id: status ? `SAMPLE_LOG.STATUS.COMPLIANCE.${status.value}` : 'SAMPLE_LOG.STATUS.NONE' }),
                            sampleNotes: collection.notes ? collection.notes : ''
                        }
                    }).filter(el => el !== null);

                    const dataToExport = data !== null && data.length ? data.map(el => [el.locationName, el.date, el.sampleType, el.quality, el.sampleNotes]) : null;
                    let locationName = locations.length === 1 ?
                        singleLocation.name :
                        intl.formatMessage({ id: 'ALL_LOCATIONS' });
                    const chart = props.chartRef.current;
                    const base64Image = chart ? chart.toBase64Image() : undefined;
                    exportData({ [option]: true }, locationName, intl.formatMessage({ id: 'SAMPLES' }), dataToExport, exportHeaders, base64Image, print);
                    setAlert({ open: false, messageId: "APP.SUCCESS", severity: "success" });
                    handleClose();
                }
            }).catch(error => {
                setAlert({ open: true, messageId: "TRY_AGAIN", severity: "error" });
            });
    }

    useEffect(() => {
        setLoading(true);
        handleClose();
        API.samplingLogs.getSamplingValues(token, locations, selectedDateRange.dateFrom, selectedDateRange.dateTo, qualityTypes, sampleTypes, pictureSample, page, size)
            .then(items => {
                if (items.data) {
                    const data = items.data.docs.map(collection => {
                        const sampleType = samplesSampleTypes.find(el => el.key === collection.source.type).value;
                        const status = statusCodes.find(el => el.key === collection.statusCode.COMPLIANCE);
                        return {
                            id: collection._id,
                            locationName: collection.locationId.name,
                            date: formatDateLocale(collection.timestamp),
                            sampleType: intl.formatMessage({ id: 'SAMPLE_LOG.TYPE_' + sampleType }),
                            quality: intl.formatMessage({ id: status ? `SAMPLE_LOG.STATUS.COMPLIANCE.${status.value}` : 'SAMPLE_LOG.STATUS.NONE' }),
                            sampleNotes: collection.notes ? collection.notes : ''
                        }
                    }).filter(el => el !== null);

                    setTotal(items.data.totalDocs)
                    setSampleData(data);
                }
                else setSampleData([]);
                setLoading(false);
            }).catch(error => {
                setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                setSampleData({ error });
                setLoading(false);
            });
    }, [token, locations, qualityTypes, sampleTypes, samplesSampleTypes, selectedDateRange, statusCodes, intl, pictureSample, page, size, reset]);

    useEffect(() => {
        if (detailsDrawer) {
            try {
                setDetails({
                    title: <FormattedMessage id='SAMPLE_LOG' />,
                    subtitle: <ComplianceTag status={detailsDrawer.statusCode.COMPLIANCE} />,
                    display: <SamplingLogDetails reset={reset} setReset={setReset} timestampForDialog={detailsDrawer.timestamp} timestamp={formatDateLocale(detailsDrawer.timestamp)} locationName={detailsDrawer.locationName} locationId={detailsDrawer.locationId} logId={detailsDrawer._id} sampleType={detailsDrawer.sampleType} values={detailsDrawer.valuesArray} notes={detailsDrawer.notes} pictures={detailsDrawer.pictures} collector={detailsDrawer.source.collector} labels={detailsDrawer.labels} toggleDrawer={() => setDrawer(false)} setAlert={setAlert} sampleData={sampleData} setSampleData={setSampleData} />
                });
            } catch (err) {
                setAlert({ open: true, messageId: "ERROR.ERROR" });
                setDetails({
                    title: <FormattedMessage id='SAMPLING_LOG_REPORT.ERROR' />,
                    subtitle: '',
                    display: <div />
                });
            }
        }
    }, [sampleData, detailsDrawer, reset])

    useEffect(() => {
        setPage(0)
    }, [selectedDateRange, sampleTypes])

    const render = useCallback(() => {

        const handleData = (row) => {
            setDrawer(true);
            setDetails({
                title: <FormattedMessage id={'LOADING_DATA'} />,
                subtitle: <FormattedMessage id='WAIT' />,
                display: <LoadingData noText />
            });
            API.samplingLogs.getSamplingDetails(token, row.id)
                .then(({ data }) => {
                    const sampleType = samplesSampleTypes.find(el => el.key === data.source.type).value;
                    const valuesArray = [];
                    for (let index = 0; index < data.measurements.length; index++) {
                        const displayData = data.measurements[index];
                        const resource = resourcesTypes.find(el => el.key === displayData.resourceTypeKey);
                        valuesArray.push({
                            id: resource.key,
                            resource: <DisplayResourceName resource={resource} />,
                            value: displayData.value,
                            unit: resource.unit,
                            resourceTypeKey: resource.key,
                            complianceIndex: displayData.complianceIndex,
                        });
                    }
                    setDetailsDrawer({ ...data, locationName: row.row.locationName, sampleType: sampleType, valuesArray: valuesArray })
                })
                .catch((error) => {
                    setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
                    setDetails({
                        title: <FormattedMessage id='SAMPLING_LOG_REPORT.ERROR' />,
                        subtitle: '',
                        display: <div />
                    });
                });
        }
        const tableHeaders = [
            {
                field: 'locationName',
                headerName: intl.formatMessage({ id: "SAMPLING_LOG_REPORT.LOCATION_NAME" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'date',
                headerName: intl.formatMessage({ id: "SAMPLING_LOG_REPORT.DATE" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 150 }),
            },
            {
                field: 'sampleType',
                headerName: intl.formatMessage({ id: "SAMPLING_LOG_REPORT.SAMPLE_TYPE" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            },
            {
                field: 'quality',
                headerName: intl.formatMessage({ id: "SAMPLING_LOG_REPORT.STATUS" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            },
            {
                field: 'sampleNotes',
                headerName: intl.formatMessage({ id: "NOTES" }),
                ...(!smallScreen && { flex: 1 }),
                ...(smallScreen && { width: 100 }),
            },
        ];

        if (loading) return <LoadingData />;
        else if (sampleData !== null && sampleData.length)
            return <CustomDataGrid
                rows={sampleData}
                columns={tableHeaders}
                handleData={handleData}
                rowCount={total}
                page={page}
                onPageChange={(newPage) => { setPage(newPage) }}
                size={size}
                onPageSizeChange={(newPageSize) => setSize(newPageSize)}
                paginationMode='server'
            />
        else if (sampleData?.error) return <Alert severity="error"><FormattedMessage id='NO_DATA' /></Alert>;
        else return <Alert severity="warning"><FormattedMessage id='NO_DATA_TIME_PERIOD' /></Alert>;
    }, [loading, sampleData, intl, resourcesTypes, samplesSampleTypes, token, smallScreen, page, size, total]);

    const tableData = sampleData !== null && sampleData.length ? sampleData.map(el => [el.locationName, el.date, el.sampleType, el.quality, el.sampleNotes]) : null;

    return <>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        <Card>
            <CardHeader title={<FormattedMessage id="DATA_TABLE" />}
                action={tableData !== null && !!tableData.length ? [
                    <Button
                        id="export-button"
                        key="export-button"
                        size='small'
                        aria-controls={open ? 'export-data-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        startIcon={smallScreen ? null : <FileDownloadOutlined />}
                        endIcon={open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
                    >
                        {intl.formatMessage({ id: 'EXPORT' })}
                    </Button>,
                    <Menu
                        id="export-data-menu"
                        key='export-data-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={(e) => handleExportChange('getJsonData')} ><FormattedMessage id="EXPORT_JSON" /></MenuItem>
                        <MenuItem onClick={(e) => handleExportChange('getCsvData')} ><FormattedMessage id="EXPORT_CSV" /></MenuItem>
                        <MenuItem onClick={(e) => handleExportChange('getPdfData')} ><FormattedMessage id="EXPORT_PDF" /></MenuItem>
                        <MenuItem onClick={(e) => handleExportChange('getPdfData', true)} > <FormattedMessage id="PRINT_PDF" /></MenuItem>
                    </Menu>
                ] : null} />
            <CardContent>
                {render()}
                <SideDrawer state={details} open={openDrawer} toggleDrawer={() => setDrawer(false)} />
            </CardContent>
        </Card>
    </>;
}

export default SamplesTableContainer;