import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Autocomplete, Chip, FormControl, FormHelperText, TextField, Typography } from '@mui/material';


const FreeMultipleForm = ({ label, value, recommendedValues, onChange, ...props }) => {
    const [inputValue, setInputValue] = useState('');
    const intl = useIntl();
    const [focused, setFocused] = useState(false);

    return <FormControl fullWidth>
        <Autocomplete
            id="free-form-input"
            open={false}
            freeSolo
            multiple
            options={recommendedValues}
            value={value}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onInputChange={(e, value, reason) => {
                if (reason === "clear") onChange([])
                else setInputValue(value.trim());
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={props.placeholder || ""}
                    label={label}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && inputValue.trim() !== '') {
                            e.preventDefault();
                            if (!recommendedValues.includes(inputValue.trim()) && !value.includes(inputValue.trim())) {
                                onChange(oldValues => ([...oldValues, inputValue.trim()]))
                                setInputValue('');
                            }
                        }
                        else if (e.key === 'Backspace' && inputValue === '') {
                            const lastChipIndex = value.length - 1;
                            if (lastChipIndex >= 0) {
                                const updatedOptions = [...value];
                                updatedOptions.splice(lastChipIndex, 1);
                                onChange(updatedOptions);
                            }
                        }
                    }}
                />
            )}
            renderTags={(options, getTagProps) => {
                return options.map((option, index) => (
                    <Chip
                        size='small'
                        label={option}
                        {...getTagProps({ index })}
                        onDelete={() => {
                            const updatedOptions = [...value];
                            updatedOptions.splice(index, 1);
                            onChange(updatedOptions);
                            options.splice(index, 1)
                        }}
                    />
                ))
            }
            }
            size="small"
            fullWidth
            {...props}
        />
        {focused && <FormHelperText>
            <Typography variant='caption' color='primary'>{intl.formatMessage({ id: "INPUT.ENTER" })}</Typography>
        </FormHelperText>}
    </FormControl>


}

export default FreeMultipleForm;