import { useSelector } from "react-redux";
import { useParams } from 'react-router';
import { FormattedMessage } from "react-intl";
import { selectLocationDetails, selectSuperGroups } from 'redux/locationsSlice';
import { userSelector } from "redux/userSlice";
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { LocationGroupsCardView, LocationDetailsView, LocationMeasurePointCardView, LocationSuperGroupCard, LocationDetailsDialogForm, SearchBar } from 'components';
import { Box, Button, Grid, IconButton, Pagination, Tooltip, Typography } from "@mui/material";
import { permissionSelector } from "redux/userSlice";
import { AddOutlined, ArrowUpwardOutlined } from "@mui/icons-material";
import { useCallback, useEffect, useRef, useState } from "react";
import { LoadingData } from "components";

const SuperLocationCardView = () => {
    const superGroups = useSelector(selectSuperGroups);
    const orgAdmin = useSelector((state) => permissionSelector(state, 'create-supergroup'));
    const [openForm, setOpenForm] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [search, setSearch] = useState("");
    const [groups, setGroups] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (groups.length === 0) setLoading(true);
        else setLoading(false);
    }, [groups]);

    useEffect(() => {
        if (page === -1) setGroups(superGroups);
        else setGroups(superGroups.slice(page * 50, (page * 50) + 50));
    }, [superGroups, page]);

    const render = useCallback(() => {
        const LocationGrid = function ({ location }) {
            return <Grid item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex' }}>
                <LocationSuperGroupCard location={location} />
            </Grid>
        }
        if (loading) return <LoadingData sx={{ mt: 2, ml: 1 }} />;
        if (search?.length > 0) return superGroups.filter(el => el.name?.toLowerCase()?.includes(search?.toLowerCase())).map(location => <LocationGrid key={location._id} location={location} />)
        else return groups.map(location => <LocationGrid key={location._id} location={location} />)
    }, [superGroups, groups, search, loading]);

    const scrollToTop = () => {
        if (ref !== null) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    const PaginationLabel = () => {
        const current = (page * 50) + 1;
        const last = current + 49;
        return <span>
            {current} - {last > superGroups.length ? superGroups.length : last} <FormattedMessage id="OUT_OF" /> {superGroups.length}
        </span>
    }

    return <Grid container spacing={2} alignContent="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box visibility="none" ref={ref} />
            <SearchBar search={search} setSearch={setSearch} action={orgAdmin ? <Tooltip title={<FormattedMessage id="ADD_SUPER_GROUP" />} arrow placement="bottom-start">
                <IconButton color="primary" onClick={() => setOpenForm(true)}><AddOutlined fontSize="large" /></IconButton>
            </Tooltip> : null} />
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} sx={(search?.length > 0 || loading) ? { display: 'none' } : undefined}>
            {page >= 0 && <Pagination size="small" color="secondary" variant="outlined" count={Math.ceil(superGroups.length / 50)} page={page + 1} onChange={(e, value) => setPage(value - 1)} />}
            {page >= 0 && <Typography sx={{ alignSelf: 'center', mx: 2 }} component="span" color="text.disabled"><PaginationLabel /></Typography>}
            <Button color="secondary" size="small" onClick={() => { setLoading(true); setPage(page => page >= 0 ? -1 : 0) }}><FormattedMessage id={page >= 0 ? 'SHOW_ALL' : 'VIEW_PAGED'} /></Button>
        </Grid>

        {render()}
        <LocationDetailsDialogForm
            openFormDialog={openForm}
            handleCloseForm={() => setOpenForm(false)}
            successMsg={successMsg}
            setSuccessMsg={setSuccessMsg}
            addSuperGroup={true}
        />
        <Grid container item xs={12} sm={12} md={12} lg={12} sx={(search?.length > 0 || loading) ? { display: 'none' } : undefined}>
            {page >= 0 && <Pagination color="secondary" variant="outlined" count={Math.ceil(superGroups.length / 50)} page={page + 1} onChange={(e, value) => setPage(value - 1)} />}
            {page >= 0 && <Typography sx={{ alignSelf: 'center', mx: 2 }} component="span" color="text.disabled"><PaginationLabel /></Typography>}
            {page < 0 && <Button color="secondary" size="small" onClick={scrollToTop} startIcon={<ArrowUpwardOutlined />}><FormattedMessage id="SCROLL_TO_TOP" /></Button>}
        </Grid>
    </Grid>
};

function LocationsPage(props) {
    const { superGroup, group, details, loggedIn } = props;
    const locationId = useParams().locationId;
    const location = useSelector((state) => selectLocationDetails(state, locationId));

    const viewSuperGroupPermission = useSelector((state) => permissionSelector(state, 'view-supergroup'));
    const viewGroupPermission = useSelector((state) => permissionSelector(state, 'view-group'));
    const viewLocationPermission = useSelector((state) => permissionSelector(state, 'view-location'));

    if (!locationId && viewSuperGroupPermission) return <EmptyState component={<SuperLocationCardView />} />;
    else if (location && superGroup && viewSuperGroupPermission) return <EmptyState component={<LocationGroupsCardView locationId={locationId} />} />;
    else if (location && group && viewGroupPermission) return <EmptyState component={<LocationMeasurePointCardView locationGroupId={locationId} />} />;
    else if (location && details && viewLocationPermission) return <EmptyState component={<LocationDetailsView location={location} publicView={!loggedIn} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

export default function LocationsPageComponent(props) {
    const { superGroup, group, details } = props;
    const { isSuccess: loggedIn } = useSelector(userSelector);

    if (loggedIn) return <DefaultLayout page={<LocationsPage loggedIn group={group} details={details} superGroup={superGroup} />} />;
    else return <PublicLayout page={<LocationsPage group={group} details={details} superGroup={superGroup} />} />;
};