import { Autocomplete, TextField } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectMeasurePoints, selectGroup, selectSuperGroups } from "redux/locationsSlice";


export default function LocationInputField({ selectedLocation, setSelectedLocation, renderOptions, options, simpleLabels, ...props }) {
    const locations = useSelector(selectMeasurePoints);
    const groups = useSelector(selectGroup);
    const superGroups = useSelector(selectSuperGroups);
    const intl = useIntl();

    const optionsArray = options ? options : (typeof renderOptions === 'function' ? renderOptions(locations) : locations);

    const getOptionLabel = option => {
        const group = groups.find(el => el._id === option.locationGroupId);
        const supergroup = superGroups.find(el => el._id === option.superGroupId);

        if (simpleLabels) return option.name;
        else if (group) return `${option.name} (${group.name})`;
        else if (supergroup) return `${option.name} (${supergroup.name})`;
        else return option.name;
    }

    return <Autocomplete
        id="selectLocation"
        options={optionsArray}
        getOptionLabel={(location) => getOptionLabel(location)}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderOption={(props, option) => <li {...props} key={option._id}>{getOptionLabel(option)}</li>}
        value={selectedLocation}
        onChange={(e, location) => setSelectedLocation(location)}
        renderInput={(params) => (
            <TextField helperText={props.helpertext ? props.helpertext : undefined} required={Boolean(props.required)} {...params} key={optionsArray.map(el => el._id)} placeholder={selectedLocation?.length ? "" : (props.placeholder || intl.formatMessage({ id: "NONE_SELECTED" }))} label={props.label ?? <FormattedMessage id="ALL_LOCATIONS" />} />
        )}
        sx={{ width: '100%' }}
        size="small"
        fullWidth
        {...props}
    />
}