import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

export default function CustomRadioGroup({ value, onChange, options, disabled }) {

    if (!options?.length) {
        console.error("Property 'options' array missing from CustomRadioGroup component!");
        return null;
    }

    return <RadioGroup
        value={value}
        onChange={(e, value) => onChange(value)}
    >
        {options.map((opt, index) => <FormControlLabel disabled={disabled} key={index + (value ?? 0)} value={opt.value} control={<Radio />} sx={{ color: value === opt.value ? 'text.primary' : 'text.secondary' }} label={opt.label} />)}
    </RadioGroup>
}