import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { DefaultLayout, PublicLayout, EmptyState } from 'layouts';
import { AlarmsView, SingleAlarmView, LoginRequired } from 'components';
import { useSelector } from 'react-redux';
import { userSelector, permissionSelector } from 'redux/userSlice';



function AlarmsPage(props) {
    const alarmId = useParams().alarmId;

    if (!alarmId) return <EmptyState component={<AlarmsView />} />;
    else if (alarmId) return <EmptyState component={<SingleAlarmView alarmId={alarmId} />} />;
    else return <EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />;
}

export default function AlarmsPageComponent(props) {
    const { isSuccess: loggedIn } = useSelector(userSelector);
    const viewAlarmPermission = useSelector((state) => permissionSelector(state, 'view-alarms'));

    if (loggedIn && viewAlarmPermission) return <DefaultLayout page={<AlarmsPage />} />;
    else if (loggedIn) return <DefaultLayout page={<EmptyState message={<FormattedMessage id="SPLASH.UNAVAILABLE" />} />} />;
    else return <PublicLayout page={<LoginRequired />} />;
};