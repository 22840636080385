import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectGroup, selectSuperGroups } from 'redux/locationsSlice';
import { permissionSelector } from "redux/userSlice";
import { FormattedMessage } from "react-intl";
import { LocationDetailsDialogForm, LocationGroupCard, SearchBar } from "components";
import { Grid, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { AddOutlined, ArrowBack, } from "@mui/icons-material";
import { useNavigate } from "react-router";

export default function LocationGroupsCardView({ locationId }) {
    const locations = useSelector((state) => selectGroup(state, locationId));
    const superGroup = useSelector((state) => selectSuperGroups(state, locationId));
    const orgAdmin = useSelector((state) => permissionSelector(state, 'create-group'));
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [search, setSearch] = useState("");
    const [filters, setFilters] = useState([]);
    const navigate = useNavigate();
    const smallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

    const handleClickOpenForm = () => {
        setOpenFormDialog(true);
        setSuccessMsg("");
    };

    const handleCloseForm = () => {
        setOpenFormDialog(false);
    };

    const filterLocations = () => {
        if (filters.length === 2 || !filters.length) return locations;
        else return locations.filter(el => filters.includes("zone") ? el.type === "zone" : true)
            .filter(el => filters.includes("waterPump") ? el.type === "waterPump" : true)
    }

    return (
        <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <SearchBar search={search} additionalFilters={true} filters={filters} setFilters={setFilters} setSearch={setSearch} noSubmit icon={<IconButton onClick={() => navigate('/locations')}>
                    <ArrowBack color="primary" />
                </IconButton >} action={orgAdmin ? <Tooltip title={<FormattedMessage id="ADD_LOCATION_GROUP" />} arrow placement="bottom-start">
                            <IconButton color="primary" sx={{ m: 'auto' }} key="addLocationGroup" onClick={handleClickOpenForm}><AddOutlined fontSize="large" /></IconButton>
                        </Tooltip> : null} />
                <Typography variant="h5" sx={{ pt: smallScreen ? 6 : 2 }} color="text.secondary" > {superGroup?.name}</Typography >
            </Grid>
            {
                filterLocations().filter(el => search?.length ? el.name?.toLowerCase()?.includes(search?.toLowerCase()) : true).map((location, i) => (
                    <Grid key={i} item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex' }}>
                        <LocationGroupCard location={location} />
                    </Grid>
                ))
            }
            <LocationDetailsDialogForm
                openFormDialog={openFormDialog}
                handleCloseForm={handleCloseForm}
                successMsg={successMsg}
                setSuccessMsg={setSuccessMsg}
                superGroupId={superGroup._id}
            />
        </Grid >
    );
}