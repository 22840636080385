import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { permissionSelector } from "redux/userSlice";
import { selectGroupsMeasurePoints, selectLocations, selectSuperGroups } from 'redux/locationsSlice';
import { LocationMeasurePointCard, LocationDetailsDialogForm, SearchBar, SideDrawer, DisplayBox, SimpleList } from "components";
import { Grid, Card, Tooltip, IconButton, Typography, Box, Stack } from "@mui/material";
import { AddOutlined, ArrowBack, InfoOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";


const CustomText = (props) => <Stack direction="column">
    <Typography color='primary.main' variant='body2'><FormattedMessage id={props.messageId} /></Typography>
    {props.component ? props.component : <Typography variant='body1'>{props.text}</Typography>}
</Stack>;


const LocationGroupAttributes = ({ locationName, attributes, locationType }) => {
    const intl = useIntl();

    return (
        <Stack spacing={1}>
            {locationName && <DisplayBox label={locationType === "waterPump" ? <FormattedMessage id="WELLFIELD" /> : <FormattedMessage id="ZONE" />} value={locationName} />}
            {attributes.county && <DisplayBox label={<FormattedMessage id="COUNTY" />} value={attributes?.county} />}
            {attributes.settlement && <DisplayBox label={<FormattedMessage id="SETTLEMENT" />} value={attributes?.settlement} />}
            {attributes.waterPumps.length > 0 && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.WELLFIELDS" />} value={<SimpleList data={attributes.waterPumps} />} />}
            {attributes.zones.length > 0 && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ZONES" />} value={<SimpleList data={attributes.zones} />} />}
            {attributes.note &&
                <CustomText messageId="LOCATIONGROUP.NOTE" component={
                    <Card variant='outlined' sx={{ p: 1, mt: 1, fontStyle: 'italic' }}>
                        <Typography variant='body1'>{attributes.note}</Typography>
                    </Card>
                } />
            }
            {attributes.processingTechnologyType && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.PROCESSING_TECHNOLOGY_TYPE" />} value={attributes.processingTechnologyType} />}
            {attributes.disinfectionType && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.DISINFECTION_TYPE" />} value={attributes.disinfectionType} />}
            {attributes.processingTechnologyChemicalsType && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.PROCESSING_TECHNOLOGY_CHEMICALS_TYPE" />} value={attributes.processingTechnologyChemicalsType} />}
            {attributes.systemType && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.SYSTEM_TYPE" />} value={attributes.systemType} />}
            {attributes.distributionNetworkLength !== null && attributes.distributionNetworkLength !== undefined && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.DISTRIBUTION_NETWORK_LENGTH" />} value={attributes.distributionNetworkLength} />}
            {attributes.distributionNetworkMaterial && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.DISTRIBUTION_NETWORK_MATERIAL" />} value={attributes.distributionNetworkMaterial} />}
            {attributes.additionalDisinfectionType && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.ADDITIONAL_DISINFECTION_TYPE" />} value={attributes.additionalDisinfectionType} />}
            {attributes.waterTankQuantity !== null && attributes.waterTankQuantity !== undefined && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.WATER_TANK_QUANTITY" />} value={attributes.waterTankQuantity} />}
            {attributes.waterTankDisinfectionType && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.WATER_TANK_DISINFECTION_TYPE" />} value={attributes.waterTankDisinfectionType} />}
            {attributes.householdConnections !== null && attributes.householdConnections !== undefined && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.HOUSEHOLD_CONNECTIONS" />} value={attributes.householdConnections} />}
            {attributes.consumers !== null && attributes.consumers !== undefined && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.CONSUMERS" />} value={attributes.consumers} />}
            {attributes.deliveredWater !== null && attributes.deliveredWater !== undefined && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.DELIVERED_WATER" />} value={attributes.deliveredWater} />}
            {attributes.publicOrLocal !== undefined && <DisplayBox label={<FormattedMessage id="LOCATIONGROUP.ATTRIBUTES.PUBLIC_OR_LOCAL" />} value={attributes.publicOrLocal === 'PUBLIC' ? intl.formatMessage({ id: "LOCATIONGROUP.ATTRIBUTES.PUBLIC" }) : intl.formatMessage({ id: "LOCATIONGROUP.ATTRIBUTES.LOCAL" })} />}
        </Stack>
    )
}

export default function LocationGroupsCardView(props) {
    const { locationGroupId } = props;
    const navigate = useNavigate();
    const groupAdmin = useSelector((state) => permissionSelector(state, 'create-location'));
    const locationGroup = useSelector((state) => selectLocations(state, locationGroupId))?.at(0);
    const superGroup = useSelector((state) => selectSuperGroups(state, locationGroup?.superGroupId));
    const locations = useSelector((state) => selectGroupsMeasurePoints(state, locationGroup._id));
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [search, setSearch] = useState("");
    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerDetails, setDrawerDetails] = useState({});

    const handleClickOpenForm = () => {
        setOpenFormDialog(true);
        setSuccessMsg("");
    };

    const handleCloseForm = () => {
        setOpenFormDialog(false);
    };

    const handleDrawerData = () => {
        setOpenDrawer(true);
        setDrawerDetails({
            title: <Box ml={2}> <FormattedMessage id={locationGroup?.type === "zone" ? "ZONE.DETAILS" : "WELLFIELD.DETAILS"} /> </Box>,
            display: <LocationGroupAttributes
                locationName={locationGroup?.name}
                attributes={locationGroup?.attributes}
                locationType={locationGroup?.type}
            />
        })
    }

    return (
        <>
            <SideDrawer state={drawerDetails} open={openDrawer} toggleDrawer={() => setOpenDrawer(false)} />
            <Grid container spacing={2} alignContent="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <SearchBar search={search} setSearch={setSearch} noSubmit icon={<IconButton onClick={() => navigate('/locations/superGroup/' + locationGroup?.superGroupId || "")}>
                        <ArrowBack color="primary" />
                    </IconButton>} />
                    <>
                        <Typography sx={{ float: 'left', mr: 2, pt: 2 }} variant="h5" color="text.secondary">{superGroup.name}{superGroup ? " — " : ""}{locationGroup.name}</Typography>
                        <Tooltip title={<FormattedMessage id={"DETAILS"} />} key="locationDetails" placement="right" arrow>
                            <IconButton sx={{ float: 'left', mt: 1.8 }} onClick={handleDrawerData}>
                                <InfoOutlined color="primary" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                </Grid>
                {
                    locations.filter(el => search?.length ? el.name?.toLowerCase()?.includes(search?.toLowerCase()) : true).map((location, i) => (
                        <Grid key={i} item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex' }}>
                            <LocationMeasurePointCard location={location} />
                        </Grid>
                    ))
                }
                {groupAdmin && groupAdmin.includes(locationGroup._id) ? <Grid key="addLocationIcon" item xs={12} sm={12} md={6} lg={4} sx={{ display: 'flex' }}>
                    <Card sx={{ width: '100%', minHeight: 250, display: 'flex', justifyContent: 'center' }}>
                        <Tooltip title={<FormattedMessage id="ADD_LOCATION" />} arrow placement="bottom">
                            <IconButton color="primary" sx={{ m: 'auto' }} key="addLocationGroup" onClick={handleClickOpenForm}><AddOutlined fontSize="large" /></IconButton>
                        </Tooltip>
                    </Card>
                </Grid> : null}

                <LocationDetailsDialogForm
                    openFormDialog={openFormDialog}
                    handleCloseForm={handleCloseForm}
                    successMsg={successMsg}
                    setSuccessMsg={setSuccessMsg}
                    locationGroupId={locationGroup._id}
                />
            </Grid>
        </>
    );
}