import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { FormattedMessage } from 'react-intl';
import { LoadingData, IncidentCard, AlertSnackbar } from 'components';
import { Alert, Card, CardContent, CardHeader, Container, Typography } from '@mui/material';
import API from 'api';
import { useLocation } from 'react-router';

const CardView = ({ children }) => <Card sx={{ height: '100%' }}>
    <CardHeader title={<FormattedMessage id="INCIDENT.DETAILS" />} />
    <CardContent>
        {children}
    </CardContent>
</Card>

const SingleIncidentView = ({ incidentId }) => {

    const navigateState = useLocation().state;
    const [incidentData, setIncidentData] = useState(null);
    const { token } = useSelector(userSelector);
    const [alert, setAlert] = useState({ open: false });

    const onAlertClose = () => setAlert({ ...alert, open: false });

    useEffect(() => {
        if (navigateState !== null) {
            const { open, messageId, severity } = navigateState;
            if (open && messageId && severity) setAlert(navigateState);
            else setAlert({ open: false });
        }
        else setAlert({ open: false })
    }, [navigateState])

    useEffect(() => {
        setIncidentData(null);
        API.incidents.getSingleIncident(token, incidentId).then(response => {
            if (response.data) setIncidentData(response.data);
            else setIncidentData(null)
        }).catch(error => {
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
            setIncidentData({ error });
        })
    }, [token, incidentId]);

    const render = useCallback(() => {
        if (incidentData === null) return <CardView>
            <LoadingData />
        </CardView>
        else if (incidentData.error) return <CardView>
            <Alert severity="error"><Typography variant='body2'><FormattedMessage id="NO_DATA" /></Typography></Alert>
        </CardView>
        else return <IncidentCard incident={incidentData} />;
    }, [incidentData])

    return <Container>
        <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
        {render()}
    </Container>
}

export default SingleIncidentView;