import React, { useState } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { paletteColors, paletteColorsWithOpacity } from '../utils/chartsCollorPalette';
import defaultOptions from 'charts/utils/defaultOptions';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectResourcesTypesConf, selectSamplingTypesConf } from "redux/configurationSlice";
import { selectLocationDetails } from 'redux/locationsSlice';
import { selectFontSize, selectFontWeight, selectLanguage } from 'redux/appSlice';
import { deepMerge, formatDateLocale, timeObjectAggregation } from 'utils';
import { useMediaQuery, useTheme, Typography, Grid, Box, Card } from '@mui/material';
import { SamplingLogDetails, SideDrawer, LoadingData, AlertSnackbar, ComplianceTag, DisplayResourceName } from 'components';
import { parse } from 'date-fns';
import { enGB, hr } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';
import API from 'api';


Chart.register(...registerables);
const ScatterChart = (props) => {
  const location = useSelector((state) => selectLocationDetails(state, props.locationId));
  const { token } = useSelector(userSelector);
  const sampleTypesConfig = useSelector(selectSamplingTypesConf);
  const resourceTypesConfig = useSelector(selectResourcesTypesConf);
  const fontSize = useSelector(selectFontSize);
  const fontWeight = useSelector(selectFontWeight);
  const lang = useSelector(selectLanguage);
  const intl = useIntl();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [details, setDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ open: false });
  const currentLocale = lang === "hr" ? hr : enGB;

  const onAlertClose = () => setAlert({ ...alert, open: false });

  // // inject palette colors to each serie
  if (props.data && props.data.datasets) {
    props.data.datasets.forEach((dataset, index) => {
      // get new color from palette
      const dsColor = paletteColors(index);
      const dsColorWithOpacity = paletteColorsWithOpacity(dsColor, 0.4);
      dataset.cubicInterpolationMode = 'monotone';
      dataset.tension = 0.4;
      dataset.backgroundColor = dsColorWithOpacity;
      dataset.borderColor = dsColor;
      dataset.pointStyle = 'circle';
      dataset.pointRadius = 6;
    })
  }

  const toggleDrawer = () => {
    setOpen(!open);
  }

  const propKey = props.data.datasets[0].data ? props.data.datasets[0].data.length : 0;
  const timeAggregation = timeObjectAggregation(props.aggregation || null);

  const chartOptions = {
    plugins: {
      tooltip: {
        titleFont: {
          size: smallScreen ? 10 : fontSize
        },
        bodyFont: {
          size: smallScreen ? 10 : fontSize
        },
        footerFont: {
          size: smallScreen ? 10 : fontSize
        },
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            const resource = resourceTypesConfig.find(type => type.key === context.dataset.key);
            const label = ` ${intl.formatMessage({ id: resource.name })}`;
            const value = ` ${context.formattedValue}`;
            const unit = resource.unit;
            return [label, [value, unit].join(' ')];
          }
        }
      },
      legend: {
        labels: {
          color: theme.palette.text.primary,
          font: {
            family: 'Roboto Condensed',
            size: smallScreen ? 8 : fontSize,
            weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
          }
        }
      }
    },
    scales: {
      y: {
        grid: {
          display: true,
          color: theme.palette.scale.primary,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
        ticks: {
          color: theme.palette.text.primary,
          font: {
            size: smallScreen ? 10 : fontSize,
            weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
          }
        }
      },
      x: {
        grid: {
          display: true,
          color: theme.palette.scale.primary
        },
        type: 'time',
        time: {
          parser: (date) => {
            if (props.aggregation === 'w') {
              // if date is real date format ('2022-12-12T10:05:53.800Z', 1670839544689) - parsing is not needed, 
              // we parse because DB prepared week aggregation that is not recognised format in date-fns library
              if (date.includes('T') || typeof date === 'number') return date;
              else return parse(date.split('-')[1], 'ww', new Date().setFullYear(date.split('-')[0]), { weekStartsOn: 1, locale: currentLocale });
            }
            else return date
          },
          ...timeAggregation
        },
        adapters: {
          date: {
            locale: currentLocale,
          }
        },
        ticks: {
          source: 'auto',
          // Disabled rotation for performance
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: window.innerWidth < 420 ? 3 : timeAggregation.unit === 'hour' ? 5 : fontSize > 16 ? 10 : 15,
          color: theme.palette.text.primary,
          font: {
            size: smallScreen ? 10 : fontSize,
            weight: fontWeight < 0 ? 'lighter' : fontWeight < 100 ? 'normal' : 'bolder'
          }
        }
      }
    },
    onClick: (event, elements) => {
      let date, displayArray = [];
      const point = elements[0];
      toggleDrawer();
      if (point === undefined) return;
      const { datasetIndex, index } = point;
      const dataset = props.data.datasets[datasetIndex];
      const pointElement = dataset.data[index];

      if (pointElement.id) {
        setDetails({
          title: <FormattedMessage id='LOADING_DATA' />,
          subtitle: <FormattedMessage id='WAIT' />,
          display: <LoadingData noText />
        });
        API.samplingLogs.getSamplingDetails(token, pointElement.id)
          .then(({ data }) => {
            const dataArray = [];
            const sampleType = sampleTypesConfig.find(el => el.key === data.source.type).value;
            for (let index = 0; index < data.measurements.length; index++) {
              const displayData = data.measurements[index];
              const resource = resourceTypesConfig.find(el => el.key === displayData.resourceTypeKey);
              dataArray.push({
                id: resource.key,
                resource: <DisplayResourceName resource={resource} />,
                value: displayData.value,
                unit: resource.unit,
                resourceTypeKey: resource.key,
                complianceIndex: displayData.complianceIndex,
              });
            }
            setDetails({
              title: <FormattedMessage id='SAMPLE_LOG' />,
              subtitle: <ComplianceTag status={data.statusCode.COMPLIANCE} />,
              display: <SamplingLogDetails timestampForDialog={pointElement.x} timestamp={formatDateLocale(pointElement.x)} locationName={location.name} locationId={location._id} logId={data._id} sampleType={sampleType} values={dataArray} notes={data.notes} pictures={data.pictures} collector={data.source.collector} labels={data.labels} setAlert={setAlert} toggleDrawer={() => setOpen(false)} reset={props.reset} setReset={props.setReset} />
            });
          })
          .catch(error => {
            console.error("An error has occured: ", error);
            setAlert({ open: true, messageId: (error.data && error.data.id) || "APP.ERROR", severity: "error" });
          });
      }

      else if (!pointElement.id) {
        setDetails({
          title: <FormattedMessage id='LOADING_DATA' />,
          subtitle: <FormattedMessage id='WAIT' />,
          display: <LoadingData noText />
        });
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          const { datasetIndex, index } = element;
          const dataset = props.data.datasets[datasetIndex];

          const resource = resourceTypesConfig.find(el => el.key === dataset.key);
          const pointData = dataset.data[index];
          date = pointData.x;
          displayArray.push(
            <Grid item key={resource._id} xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card variant='outlined' sx={{ p: 1, borderColor: 'primary.main', height: "100%" }} >
                <Typography variant='subtitle2'> <FormattedMessage id={resource.name} /></Typography>
                <Box sx={{ pt: 1, display: 'inline-block' }}>
                  <Typography color="primary.main" variant='h6' display='inline' >{parseFloat(pointData.y).toFixed(2)}</Typography>
                  <Typography variant='body1' display="inline">{resource.unit}</Typography>
                </Box>
              </Card>
            </Grid>
          );
        }
        setDetails({
          title: <FormattedMessage id='MEASUREMENT_LOG' />,
          subtitle: formatDateLocale(date, { aggregationMode: props.aggregation }),
          display: <Grid container alignItems="stretch" maxWidth={340} spacing={1}>{displayArray}</Grid>
        })
      }
      else return;
    },
  };

  const options = deepMerge(deepMerge({}, props.options), defaultOptions);
  return (<>
    <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
    <Scatter className='chart' key={propKey} data={props.data} options={deepMerge(options, chartOptions)} ref={props.chartRef} />
    <SideDrawer open={open} state={details} toggleDrawer={toggleDrawer} />
  </>
  );
}

export default ScatterChart;