
import { FormattedMessage } from 'react-intl';
import { Backdrop, Typography, Box, useTheme } from '@mui/material';
import { LoadingDrop } from 'components';

export default function PageLoading(props) {
    const { messageId, noDrop } = props;
    const theme = useTheme();

    const renderMessage = () => {
        if (typeof messageId === 'string') return <FormattedMessage id={messageId} />;
        else if (typeof messageId === 'object' && messageId.length > 0) return messageId.map(id => <div key={id}><FormattedMessage id={id} /></div>);
        else return null;
    }

    return (
        <Backdrop open sx={{ color: theme.palette.background.paper, zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: theme.palette.primary.main }} >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {!noDrop ? <LoadingDrop /> : <div className='drop' style={{ visibility: 'hidden' }} />}
                {messageId && <Typography className={!noDrop ? 'jello-horizontal' : null} component="span" variant="body1" color="inherit" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {renderMessage()}
                </Typography>}
            </Box>
        </Backdrop>
    );
}