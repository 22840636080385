import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectResourcesTypesConf, selectPermissionsByKey } from "redux/configurationSlice";
import { FormattedMessage } from "react-intl";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { AddchartOutlined, EditOutlined, OpenInNewOutlined } from "@mui/icons-material";
import { AddSampleForm, AddSampleReport, CustomCardReport, ComplianceTag, EditSampleForm, AlertSnackbar, LocationInputField } from "components"



export default function AddSampleView(props) {
  // if opened from locationDetailsP, location prop will be sent
  // otherwise user must choose location
  const { location, editFlag } = props
  const navigate = useNavigate();
  const resourceTypesConf = useSelector(selectResourcesTypesConf);
  const [selectedLocation, setSelectedLocation] = useState(location ? location : null);
  const [resourceTypes, setResourceTypes] = useState(selectedLocation ?
    resourceTypesConf.filter(resource => selectedLocation.resourceTypes.includes(resource.key)) :
    []);
  const [reportData, setReportData] = useState(null);

  const permissionList = useSelector(selectPermissionsByKey);
  const updateSample = permissionList['update-sample']?.includes(reportData?.locationId);
  const [alert, setAlert] = useState({ open: false });

  useEffect(() => {
    if (editFlag !== null && reportData === null) navigate('/sampleEntry')
  }, [editFlag, reportData, navigate]);

  useEffect(() => {
    // update resourceTypes when location changes
    setResourceTypes(selectedLocation ?
      resourceTypesConf.filter(resource => selectedLocation.resourceTypes.includes(resource.key)) :
      []);
  }, [selectedLocation, resourceTypesConf]);
  const renderView = useCallback(() => {
    const onAlertClose = () => setAlert({ ...alert, open: false });
    if (reportData) return <>
      <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
      <CustomCardReport
        title={<div>
          <FormattedMessage id={editFlag !== null ? "FORM.EDIT_SAMPLE" : "REPORT"} />
          <br />
          <ComplianceTag status={reportData.complianceIndex} />
        </div>}
        headerAction={[
          <Tooltip title={<FormattedMessage id={"FORM.ADD_SAMPLE"} />} key="addSample" placement="top-start" arrow>
            <IconButton name="addSampleButton" onClick={() => setReportData(null)}>
              <AddchartOutlined />
            </IconButton>
          </Tooltip>,
          (editFlag === null && updateSample && <Tooltip title={<FormattedMessage id={"FORM.EDIT_SAMPLE"} />} key="editSample" placement="top-start" arrow>
            <IconButton name="editSampleButton" onClick={() => navigate('?edit')}>
              <EditOutlined />
            </IconButton>
          </Tooltip>),
          <Tooltip title={<FormattedMessage id={"VIEW_LOCATION"} />} key="locationDetails" placement="top-start" arrow>
            <IconButton name="locationDetailsButton" onClick={() => navigate('/locations/details/' + reportData.locationId)}>
              <OpenInNewOutlined />
            </IconButton>
          </Tooltip>
        ]}
      >
        {editFlag !== null ? <EditSampleForm location={location} existingSamplingLog={reportData} setReportData={setReportData}
          setAlert={setAlert} /> : <AddSampleReport reportData={reportData} />}

      </CustomCardReport>
    </>
    else return <>
      <AlertSnackbar open={alert.open} onClose={onAlertClose} severity={alert.severity} messageId={alert.messageId} />
      <CustomCardReport
        title={<FormattedMessage id="FORM.ADD_SAMPLE" />}
        avatar={<AddchartOutlined fontSize="small" color="primary" />}
        subtitle={
          location ? <Typography variant="h6"><FormattedMessage id="LOCATION" />: {location.name}</Typography> :
            <LocationInputField selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} />
        }
      >
        {selectedLocation ?
          <AddSampleForm
            location={selectedLocation}
            resourceTypes={resourceTypes}
            setReportData={setReportData}
            setSelectedLocation={setSelectedLocation}
            setAlert={setAlert}
          /> :
          null}
      </CustomCardReport >
    </>

  }, [location, navigate, resourceTypes, selectedLocation, reportData, editFlag, updateSample, alert])

  return renderView();
};