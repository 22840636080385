import { MapComponent } from "components";


export default function LocationMapTab(props) {
    const { location } = props;

    // useEffect(() => {
    //     if (poiViewer) API.poi.getLocationPoints(token, location._id).then(items => {
    //         if (items.data) setPointsOfInterest(items.data);
    //         else setPointsOfInterest([]);
    //     }).catch((e) => {
    //         console.error("Get Location Points of Interest has failed, reason: ", e);
    //         setPointsOfInterest([]);
    //     })
    // }, [newPoIdialog, poiViewer, token, location]);

    // useEffect(() => {
    //     if (!newPoIdialog) setPosition([]);
    // }, [newPoIdialog, setPosition]);


    // const renderMap = useCallback(() => {
    //     const togglePoIinput = () => {
    //         if (!poiInput) setToggleControl(false);
    //         setPoiInput(!poiInput);
    //     }

    //     const toggleMapControl = () => {
    //         setToggleControl(!toggleControl);
    //     }

    //     const onClick = (e) => {
    //         setHideGeoData(true);
    //         setPoiInput(false);
    //         setPosition(toLonLat(e.coordinate));
    //         setNewPoIdialog(true);
    //     };

    //     const toggleGeoData = () => {
    //         setHideGeoData(!hideGeoData);
    //     }

    //     const poiFeatures = pointsOfInterest.map((poi) => {
    //         return new Feature({
    //             geometry: new Point(fromLonLat(poi.position)),
    //             name: poi.name,
    //             type: "POI",
    //             object: poi
    //         });
    //     });
    //     poiFeatures.forEach((feature, index) => feature.setStyle(MARKERS.pointOfInterest(theme.palette.mode, pointsOfInterest[index].type)));

    //     return <MapComponent className={poiInput ? 'pin-input' : null} key={poiFeatures.length} disableInteraction={!toggleControl} disableShapes={hideGeoData} onClick={poiInput ? onClick : null} position={location.position} zoom={15} customFeatures={poiFeatures} >
    //         <div className="map-control" style={{ display: 'inherit', position: 'relative' }}>
    //             {poiInput !== undefined && <SpeedDial sx={{ position: 'absolute', top: 8, right: 8 }} ariaLabel="MapControls" direction="down" icon={<SpeedDialIcon openIcon={<Settings />} icon={<SettingsOutlined />} />}>
    //                 {poiAdmin && poiAdmin.includes(location._id) ? <SpeedDialAction
    //                     onClick={togglePoIinput}
    //                     key={"new-poi"}
    //                     icon={!poiInput ? <AddLocationAltOutlined /> : <CancelOutlined />}
    //                     tooltipTitle={poiInput ? <FormattedMessage id="CANCEL" /> : <FormattedMessage id="ADD_POI" />}
    //                     tooltipPlacement="right"
    //                 /> : null}
    //                 <SpeedDialAction
    //                     onClick={toggleMapControl}
    //                     key={"popups-toggle-map"}
    //                     icon={toggleControl ? <ExploreOffOutlined /> : <ExploreOutlined />}
    //                     tooltipTitle={toggleControl ? <FormattedMessage id="MAP_CONTROL.OFF" /> : <FormattedMessage id="MAP_CONTROL.ON" />}
    //                     tooltipPlacement="right"
    //                 />
    //                 <SpeedDialAction
    //                     onClick={toggleGeoData}
    //                     key={"popups-toggle-geoData"}
    //                     icon={hideGeoData ? <LayersOutlined /> : <LayersClearOutlined />}
    //                     tooltipTitle={hideGeoData ? <FormattedMessage id="GEO_DATA.ON" /> : <FormattedMessage id="GEO_DATA.OFF" />}
    //                     tooltipPlacement="right"
    //                 />
    //             </SpeedDial>}
    //         </div>
    //         <div className="map-control-info" style={{ display: toggleControl ? 'inherit' : 'none', position: 'relative', cursor: 'help' }}>
    //             <Tooltip title={<FormattedMessage id="MAP_CONTROL" />} placement="right" arrow>
    //                 <Avatar variant="rounded" sx={{ position: 'absolute', top: 8, left: 8, zIndex: 9, width: 32, height: 32, bgcolor: theme.palette.secondary.dark }} >
    //                     <ExploreOutlined color={theme.palette.secondary.light} />
    //                 </Avatar>
    //             </Tooltip>
    //         </div>
    //     </MapComponent>
    // }, [pointsOfInterest, hideGeoData, poiInput, location, toggleControl, setPoiInput, poiAdmin, theme]);

    return <div style={{ height: '410px' }} >
        <MapComponent showPins disableInteraction disableShapes position={location.position} zoom={15} />

        {/* {!!position.length && <NewPoIDialog open={newPoIdialog} onClose={() => setNewPoIdialog(false)} location={location} pinnedPosition={position} />} */}
    </div>;
}