import instance, { setAuthHeader } from './axiosEnv';

const getAllSettlements = (token, locationIds) => {
    const config = setAuthHeader(token);
    const queryParams = new URLSearchParams();
    
    for (let el in locationIds) {
        queryParams.append('locationId', locationIds[el]);
    }
 
    return instance.get('/settlements?' + queryParams.toString(), config);
}


const settlementsAPI = {
    getAllSettlements,
}

export default settlementsAPI;