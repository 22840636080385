import React, { useEffect, useState, useRef } from "react";
import { Autocomplete, Box, Button, Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, FormLabel, Stack, TextField, Grid, Tooltip, MenuItem, Chip, FormHelperText, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
    selectLocalizationConf,
    selectResourcesTypesConf,
    selectResourceTypeGroupsConf,
    selectStatusCodesTypesConf,
    selectTypologyConf
} from "redux/configurationSlice";
import { selectMapProps } from "redux/mapSlice";
import { DisplayResourceName, PictureUploadButton, MapComponent, MapElementNew } from "components";
import { ExpandLessOutlined, ExpandMoreOutlined, InfoOutlined, MyLocationOutlined } from "@mui/icons-material";
import { Vector } from 'ol/source';
import { transform } from "ol/proj";
import { selectSuperGroups, selectLocations } from "redux/locationsSlice";
import { userSelector } from "redux/userSlice";
import proj4 from "proj4";
import { register } from "ol/proj/proj4"
import API from "api";

proj4.defs("EPSG:3765", "+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
register(proj4);

export default function LocationDetailsForm(props) {
    const { locationId, location, locationGroup, locationGroupId, superGroupId, setUpdatedLocation, setErrorMsg, counties, waterTypes, zones, waterPumps } = props;
    const intl = useIntl();
    const mapProps = useSelector(selectMapProps);
    const typology = useSelector(selectTypologyConf);
    const resourceTypes = useSelector(selectResourcesTypesConf).filter(el => el.resourceTypeGroupId?.name !== "SENSOR");
    const resourceTypeGroups = useSelector(selectResourceTypeGroupsConf).filter(el => el.name !== "SENSOR");
    const statusTypes = useSelector(selectStatusCodesTypesConf);
    const localization = useSelector(selectLocalizationConf);
    const superGroup = useSelector((state) => selectSuperGroups(state, superGroupId || ""));
    const [name, setName] = useState(locationId && location ? location.name : '');
    const [jivu, setJIVU] = useState(locationId && location ? location.typeId : '')
    const [zoneId, setZoneId] = useState(locationId && location ? location.typeId : 0)
    const [waterPumpId, setWaterPumpId] = useState(locationId && location ? location.typeId : 0)
    const [county, setCounty] = useState(locationId && location ? location.attributes?.county : null)
    const [waterType, setWaterType] = useState(locationId && location && location?.attributes?.waterType ? waterTypes.find(type => type.key === location.attributes.waterType) : null)
    const [selectType, setSelectType] = useState(locationId && location ? location.type : '')
    const [zone, setZone] = useState(() => {
        if (locationId && location && location.attributes?.zones) {
            return location.attributes.zones.map(zone => ({
                locationGroupId: zone._id,
                locationGroupName: zone.name,
                parentName: zone.superGroupId.name,
                parentTypeId: zone.superGroupId.typeId
            }));
        } else {
            return [];
        }
    });
    const [waterPump, setWaterPump] = useState(() => {
        if (locationId && location && location.attributes?.waterPumps) {
            return location.attributes.waterPumps.map(waterPump => ({
                locationGroupId: waterPump._id,
                locationGroupName: waterPump.name,
                parentName: waterPump.superGroupId.name,
                parentTypeId: waterPump.superGroupId.typeId
            }));
        } else {
            return [];
        }
    });
    const [note, setNote] = useState(locationId && location ? location.attributes?.note || '' : '');
    const [description, setDescription] = useState(locationId && location ? location.description[intl.locale] : '');
    const [measurePointType, setMeasurePointType] = useState(locationId && location &&  location.measurePointType ? location.measurePointType : 0);
    const defaultHTRSPosition = transform([parseFloat(mapProps.defaultY), parseFloat(mapProps.defaultX)], "EPSG:4326", "EPSG:3765");
    const [position, setPosition] = useState(locationId && location ? transform(location.position, "EPSG:4326", "EPSG:3765") : defaultHTRSPosition);
    const [htrsProj, setHtrsProj] = useState(true);
    const [resources, setResources] = useState(Object.fromEntries(resourceTypes.map(el => (
        [el.key, {
            name: el.name,
            group: el.resourceTypeGroupId.name,
            checked: locationId && location && (location.resourceTypes || location.serviceResourceTypes) ?
                (location.resourceTypes.includes(parseInt(el.key)) || location?.serviceResourceTypes.includes(parseInt(el.key))) : false,
            resource: el
        }]
    ))));
    const [statusCodeTypes, setStatusCodeTypes] = useState(Object.fromEntries(statusTypes.map(el => (
        [el.key, {
            name: el.key,
            status: locationId && location ? location.statusCode.find(status => Object.keys(status)[0] === el.key) ? Object.values(location.statusCode.find(status => Object.keys(status)[0] === el.key))[0] : 0 : 0,
            checked: locationId && location ? location.statusCode.find(status => Object.keys(status)[0] === el.key) ? true : false : false
        }]
    ))));
    const locationImageUrl = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_IMG_PATH + (locationId && location ? location.picture : process.env.REACT_APP_DEFAULT_IMAGE);
    const [image, setImage] = useState(null);
    const [expandedResourceGroups, setExpandedResourceGroups] = useState(Object.fromEntries(resourceTypeGroups.map(el => [el.name, true])));
    // initialize new VectorSource for VectorLayer
    const markerSource = useRef(new Vector());
    const { token } = useSelector(userSelector);
    const locationGroups = useSelector(selectLocations);
    const [settlementOptions, setSettlementOptions] = useState([]);
    const [selectedSettlement, setSelectedSettlement] = useState(locationId && location ? location.attributes?.settlement : null);

    useEffect(() => {
        if (!locationId && locationGroupId) setCounty(locationGroups.find(el => el._id === locationGroupId)?.attributes.county);
    }, [locationId, locationGroupId, locationGroups])

    useEffect(() => {
        const locationDescription = location ? location.description : { en: "", hr: "" }

        const changedLocationInfo = {
            name: name,
            description: Object.fromEntries(new Map(localization.map(el => {
                if (locationId) {
                    if (el.value === intl.locale) return [el.value, description];
                    else return [el.value, locationDescription[el.value] || '']
                }
                else {
                    if (el.value === intl.locale) return [el.value, description];
                    else return [el.value, ''];
                }
            }))),
            ...(!locationGroup) && { measurePointType: measurePointType },
            position: htrsProj ? transform(position, "EPSG:3765", "EPSG:4326") : position,
            ...(superGroupId) && { superGroupId: superGroupId },
            ...(!locationGroup) && { resourceTypes: Object.entries(resources).map(([key, value]) => value.checked && value.group !== "SERVICE" ? parseInt(key) : null).filter(el => el !== null) },
            ...(!locationGroup) && { serviceResourceTypes: Object.entries(resources).map(([key, value]) => value.checked && value.group === "SERVICE" ? parseInt(key) : null).filter(el => el !== null) },
            statusCode: Object.entries(statusCodeTypes).map(([key, value]) => value.checked ? { [key]: value.status } : null).filter(el => el !== null),
            ...image && { picture: image },

            ...(jivu) && { typeId: jivu },
            ...(county) && { county: county },
            ...(selectedSettlement) && (selectType === "waterPump") && { settlement: selectedSettlement },
            ...(selectType) && { selectType: selectType },
            ...(zoneId) && (selectType === "zone") && { typeId: zoneId },
            ...(waterPumpId) && (selectType === "waterPump") && { typeId: waterPumpId },
            ...(waterType) && (selectType === "zone") && { waterType: waterType.key },
            ...(waterPump) && (selectType === "zone") && { waterPump: waterPump.map(waterPumpItem => waterPumpItem?.locationGroupId) },
            ...(zone) && (selectType === "waterPump") && { zone: zone.map(zoneItem => zoneItem?.locationGroupId) },
            ...(note) && { note: note }
        };
        setUpdatedLocation(changedLocationInfo);
        setErrorMsg("")

    }, [jivu, county, selectedSettlement, selectType, zoneId, waterPumpId, waterType, zone, note, waterPump, setErrorMsg, setUpdatedLocation, locationId, locationGroup, superGroupId, name, description, measurePointType, position, htrsProj, resources, statusCodeTypes, image, intl, localization, location])

    useEffect(() => {
        if (superGroupId) {
            API.settlements.getAllSettlements(token, locationGroups.filter(item => item.superGroupId === superGroupId).map(item => item._id)).then(response => {
                if (response.data) setSettlementOptions(response.data)
            }).catch(error => {
                console.error('Settlements error', error);
            })
        }
    }, [locationGroups, superGroupId, token])

    const handleExpandResourceGroupClick = (rtGroup, value) => {
        const expandResGroups = { ...expandedResourceGroups }
        if (rtGroup === "all") {
            for (const rtg in expandResGroups) {
                expandResGroups[rtg] = value;
            }
        }
        else {
            expandResGroups[rtGroup] = !expandResGroups[rtGroup]
        }

        setExpandedResourceGroups(expandResGroups);
    };

    const handleResourcesCheck = (checkFlag, key) => {
        const res = { ...resources };
        res[key].checked = checkFlag;
        setResources(res);
    }

    const handleResourcesGroupCheck = (checkFlag, rtGroup) => {
        const res = { ...resources };
        for (const rtKey in res) {
            if (res[rtKey].group === rtGroup) res[rtKey].checked = checkFlag
        }
        setResources(res);

    }

    const handleStatusCheck = (checkFlag, key) => {
        const status = { ...statusCodeTypes };
        status[key].checked = checkFlag;
        setStatusCodeTypes(status);
    }

    const handlePositionChange = (lng, lat) => {
        const newPosition = [];
        parseFloat(lng) ? newPosition.push(parseFloat(lng)) : newPosition.push(0);
        parseFloat(lat) ? newPosition.push(parseFloat(lat)) : newPosition.push(0);
        setPosition(newPosition);

        if (markerSource.current.getFeatures().length !== 1) {
            // shouldn't happen
            return;
        }

        const pinFeature = markerSource.current.getFeatures()[0];
        // change coordinates for the existing Feature on the map
        pinFeature.getGeometry().setCoordinates(transform(newPosition, htrsProj ? "EPSG:3765" : "EPSG:4326", "EPSG:3857"));
    }

    const handleProjChange = () => {
        if (htrsProj) {
            setPosition(p => transform(p, "EPSG:3765", "EPSG:4326"));
            setHtrsProj(false);
        } else {
            setPosition(p => transform(p, "EPSG:4326", "EPSG:3765"));
            setHtrsProj(true);
        }
    }

    const resourceTypesCheckboxes = Object.entries(resources).map(([key, el]) => {
        return <FormControlLabel
            key={key}
            control={
                <Checkbox
                    sx={{ px: '9px', py: '5px' }}
                    checked={el.checked}
                    onChange={(e) => handleResourcesCheck(e.target.checked, key)}
                />
            }
            label={<DisplayResourceName resource={el.resource} />}
            name={el.group}
        />
    });


    const statusCodeTypesCheckboxes = Object.entries(statusCodeTypes).map(([key, el]) => {
        return <FormControlLabel
            key={key}
            control={
                <Checkbox
                    sx={{ px: '9px', py: '5px' }}
                    checked={el.checked}
                    onChange={(e) => handleStatusCheck(e.target.checked, key)}
                />
            }
            label={<FormattedMessage id={key} />}
        />
    });

    return (
        <Box component="form" >
            <Box sx={{ py: 1 }}>
                <Grid container spacing={2}>
                    {superGroup && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField disabled defaultValue={superGroup.name} size="small" fullWidth variant="standard" />
                    </Grid>}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormLabel><FormattedMessage id="NAME" />*</FormLabel>
                        <TextField
                            fullWidth
                            disabled={Boolean(locationId)}
                            variant="outlined"
                            id="NAME"
                            placeholder={intl.formatMessage({ id: "NAME" })}
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            size="small"
                        />
                    </Grid>

                    {!superGroupId && locationGroup && <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormLabel><FormattedMessage id="JIVU/IVU" /></FormLabel>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="JIVU/IVU"
                                placeholder={intl.formatMessage({ id: "JIVU/IVU" })}
                                type="number"
                                value={jivu}
                                onChange={(e) => setJIVU(e.target.value)}
                                size="small"
                            />
                        </Grid>
                    </>}

                    {(!!superGroupId || !locationGroup) && <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                filterSelectedOptions
                                onChange={(e, value) => { setCounty(value) }}
                                id="multiple-limit-tags"
                                value={county}
                                size="small"
                                options={counties}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField {...params} label={<FormattedMessage id="COUNTY" />} />
                                )}
                            />
                        </Grid>
                    </>}

                    {!!superGroupId && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            label={<FormattedMessage id="TYPE" />}
                            select
                            size="small"
                            fullWidth
                            value={selectType}
                            onChange={(e) => setSelectType(e.target.value)}
                        >
                            <MenuItem value="waterPump" key="waterPump"><FormattedMessage id="WELLFIELD" /></MenuItem>
                            <MenuItem value="zone" key="zone"><FormattedMessage id="ZONE" /></MenuItem>
                        </TextField>
                    </Grid>}

                    {!!superGroupId && selectType === 'waterPump' && <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                filterSelectedOptions
                                onChange={(e, value) => { setSelectedSettlement(value) }}
                                id="multiple-limit-tags"
                                value={selectedSettlement}
                                size="small"
                                options={settlementOptions.map(item => item.name)}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField {...params} label={<FormattedMessage id="LOCATIONGROUP.SETTLEMENT" />} />
                                )}
                            />
                        </Grid>
                    </>}

                    {selectType === 'zone' && <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormLabel><FormattedMessage id="ZONE_ID" /></FormLabel>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="ZONE_ID"
                                placeholder={intl.formatMessage({ id: "ZONE_ID" })}
                                type="number"
                                value={zoneId}
                                onChange={(e) => setZoneId(e.target.value)}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                onChange={(e, value) => { setWaterPump(value) }}
                                id="multiple-limit-tags"
                                value={waterPump}
                                size="small"
                                options={waterPumps}
                                getOptionLabel={(option) => `${option.locationGroupName} (${option.parentName})`}
                                isOptionEqualToValue={(option, value) => option.locationGroupId === value.locationGroupId}
                                renderInput={(params) => (
                                    <TextField {...params} label={<FormattedMessage id="LOCATIONGROUP.WELLFIELDS" />} />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                filterSelectedOptions
                                onChange={(e, value) => { setWaterType(value) }}
                                id="multiple-limit-tags"
                                value={waterType}
                                size="small"
                                options={waterTypes}
                                getOptionLabel={(option) => intl.formatMessage({ id: option.value })}
                                renderInput={(params) => (
                                    <TextField {...params} label={<FormattedMessage id="WATER_TYPE" />} />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormLabel><FormattedMessage id="LOCATIONGROUP.NOTE" /></FormLabel>
                            <TextField
                                fullWidth
                                multiline
                                rows="2"
                                id="LOCATIONGROUP.NOTE"
                                placeholder={intl.formatMessage({ id: "LOCATIONGROUP.NOTE" })}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                size="small"
                            />
                        </Grid>
                    </>
                    }

                    {selectType === 'waterPump' && <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormLabel><FormattedMessage id="WELLFIELD_ID" /></FormLabel>
                            <TextField
                                fullWidth
                                variant="outlined"
                                id="WELLFIELD_ID"
                                placeholder={intl.formatMessage({ id: "WELLFIELD_ID" })}
                                type="number"
                                value={waterPumpId}
                                onChange={(e) => setWaterPumpId(e.target.value)}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                multiple
                                filterSelectedOptions
                                onChange={(e, value) => { setZone(value) }}
                                id="multiple-limit-tags"
                                value={zone}
                                size="small"
                                options={zones}
                                getOptionLabel={(option) => `${option.locationGroupName} (${option.parentName} - ${option.parentTypeId})`}
                                isOptionEqualToValue={(option, value) => option.locationGroupId === value.locationGroupId}
                                renderInput={(params) => (
                                    <TextField {...params} label={<FormattedMessage id="LOCATIONGROUP.ZONES" />} />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormLabel><FormattedMessage id="LOCATIONGROUP.NOTE" /></FormLabel>
                            <TextField
                                fullWidth
                                multiline
                                rows="2"
                                id="LOCATIONGROUP.NOTE"
                                placeholder={intl.formatMessage({ id: "LOCATIONGROUP.NOTE" })}
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                                size="small"
                            />
                        </Grid>
                    </>
                    }

                    {!locationGroup && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormLabel><FormattedMessage id="LOCATION.TYPE" /></FormLabel>
                        <Autocomplete
                            id="LOCATION.TYPE"
                            disableClearable
                            size="small"
                            options={typology.map(type => type.key)}
                            getOptionLabel={(type) => intl.formatMessage({ id: "LOCATION_TYPE." + typology.find(mpType => mpType.key === type).value })}
                            value={measurePointType}
                            onChange={(e, value) => { setMeasurePointType(value) }}
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                            sx={{ width: '100%' }}
                        />
                    </Grid>}
                </Grid>
            </Box>


            <Box sx={{ py: 1 }}>
                <FormLabel>
                    <FormattedMessage id="DESCRIPTION" /> (
                    {intl.locale}
                    <Tooltip placement="right" title={<FormattedMessage id="SAVE_LOCALE_TOOLTIP" />} arrow>
                        <InfoOutlined color="disabled" sx={{ width: '16px', height: '16px' }} />
                    </Tooltip>
                    )
                </FormLabel>
                <TextField
                    fullWidth
                    multiline
                    rows="5"
                    id="DESCRIPTION"
                    placeholder={intl.formatMessage({ id: "DESCRIPTION" })}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    size="small"
                />
            </Box>

            <Box sx={{ py: 1 }} className="location-details-form-map">

                <FormLabel sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <FormattedMessage id="POSITION" />
                    <span>
                        <Typography component="span" variant="caption" color="text.disabled"><FormattedMessage id="CLICK_TO_CHANGE" />: </Typography>
                        <Chip size="small" sx={{ mb: 0.3, ml: 0.3 }} icon={<MyLocationOutlined fontSize="small" />} color={htrsProj ? "info" : "secondary"} label={htrsProj ? "HTRS96" : "WGS84"} variant="outlined" onClick={handleProjChange} />
                    </span>
                </FormLabel>
                <div style={{ height: '200px' }}>
                    <MapComponent className="pin-input" zoom={locationId ? 12 : 10} position={htrsProj ? transform(position, "EPSG:3765", "EPSG:4326") : position} disableShapes>
                        <MapElementNew
                            key="map-elements"
                            markerSource={markerSource.current}
                            positionInput={htrsProj ? transform(position, "EPSG:3765", "EPSG:4326") : position}
                            positionChange={(p) => htrsProj ? setPosition(transform(p, "EPSG:4326", "EPSG:3765")) : setPosition(p)}
                        />
                    </MapComponent>
                </div>
                <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                    <TextField
                        key="lng"
                        fullWidth
                        id="LONGITUDE"
                        type="number"
                        label={<FormattedMessage id="LONGITUDE" />}
                        inputProps={{ inputMode: 'decimal', step: 0.000001 }}
                        value={position[0]}
                        onChange={(e) => handlePositionChange(e.target.value, position[1])}
                        variant="outlined"
                        size="small"
                    />
                    <TextField
                        key="lat"
                        fullWidth
                        id="LATITUDE"
                        type="number"
                        label={<FormattedMessage id="LATITUDE" />}
                        inputProps={{ inputMode: 'decimal', step: 0.000001 }}
                        value={position[1]}
                        onChange={(e) => handlePositionChange(position[0], e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </Stack>
                <FormHelperText><FormattedMessage id={"USING_" + (htrsProj ? "HTRS96" : "WGS84")} /></FormHelperText>
            </Box>

            {statusCodeTypesCheckboxes.length ?
                <Box sx={{ py: 1 }}>

                    <FormControl component="fieldset">
                        <FormLabel component="legend"><FormattedMessage id="QUALITY_INDEX" /></FormLabel>
                        <FormGroup>
                            {statusCodeTypesCheckboxes}
                        </FormGroup>
                    </FormControl>
                </Box>
                : null
            }


            {!locationGroup ?
                <Box sx={{ py: 1 }}>
                    <FormControl component="fieldset">
                        <Box>
                            <FormLabel><FormattedMessage id="MEASUREMENT_TYPES" /></FormLabel>
                            {Object.entries(expandedResourceGroups).every(([key, value]) => value) ?
                                <Button sx={{ mx: 2 }} variant="text" onClick={() => handleExpandResourceGroupClick("all", false)}><FormattedMessage id="COLLAPSE" /></Button> :
                                <Button sx={{ mx: 2 }} variant="text" onClick={() => handleExpandResourceGroupClick("all", true)}><FormattedMessage id="EXPAND" /></Button>
                            }
                        </Box>
                        {resourceTypeGroups.map(rtGroup =>
                            <Box sx={{ mb: '4px' }} key={rtGroup._id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Object.entries(resources).filter(([key, value]) => value.group === rtGroup.name).every(([key, value]) => value.checked)}
                                            indeterminate={Object.entries(resources).filter(([key, value]) => value.group === rtGroup.name).some(([key, value]) => value.checked)
                                                && Object.entries(resources).filter(([key, value]) => value.group === rtGroup.name).some(([key, value]) => !value.checked)}
                                            onChange={(e) => handleResourcesGroupCheck(e.target.checked, rtGroup.name)}
                                        />
                                    }
                                    label={<FormattedMessage id={"RESOURCE_TYPE.GROUP." + rtGroup.name} />}
                                />
                                {expandedResourceGroups[rtGroup.name] ?
                                    <ExpandLessOutlined sx={{ position: 'relative', top: '8px' }} onClick={() => handleExpandResourceGroupClick(rtGroup.name)} /> :
                                    <ExpandMoreOutlined sx={{ position: 'relative', top: '8px' }} onClick={() => handleExpandResourceGroupClick(rtGroup.name)} />
                                }
                                <Collapse in={expandedResourceGroups[rtGroup.name]} timeout="auto" unmountOnExit>
                                    <FormGroup sx={{ ml: 2 }}>
                                        {resourceTypesCheckboxes.filter(el => el.props.name === rtGroup.name)}
                                    </FormGroup>
                                </Collapse>
                            </Box>
                        )}
                    </FormControl>
                </Box>
                : null
            }


            <Box sx={{ py: 1 }}>
                <PictureUploadButton
                    defaultImage={{ name: locationId && location ? location.name : process.env.REACT_APP_DEFAULT_IMAGE, url: locationImageUrl }}
                    setFiles={setImage}
                    single
                />
            </Box>
        </Box>
    );
}