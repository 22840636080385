import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { DateRangeSelector, DisplayResourceName, LocationInputField } from 'components';
import { useSelector } from 'react-redux';
import { selectSamplingTypesConf, selectTypologyConf } from "redux/configurationSlice";
import { selectMeasurePoints, selectLocations, selectSuperGroups } from 'redux/locationsSlice';

import { Autocomplete, Avatar, Box, Card, CardContent, Chip, Grid, Stack, Switch, TextField, Typography, FormControlLabel, useMediaQuery, Tooltip } from "@mui/material";
import { TimelineOutlined, TableRowsOutlined, SplitscreenOutlined } from '@mui/icons-material';

function SwitchIcon(props) {
    const { chart, table, check, uncheck } = props;
    const getColor = () => {
        if (check) return 'info.dark';
        if (uncheck) return 'info.main';
        if (chart) return 'info.main';
        if (table) return 'info.dark';
    }
    return <Avatar sx={{ width: 24, height: 24, bgcolor: getColor() }}>
        {(() => {
            if (check) return <SplitscreenOutlined sx={{ p: 0.1 }} fontSize='small' />;
            if (uncheck) return <SplitscreenOutlined sx={{ transform: 'rotate(90deg)', p: 0.1 }} fontSize='small' />;
            if (chart) return <TimelineOutlined fontSize='small' />;
            if (table) return <TableRowsOutlined sx={{ p: 0.1 }} fontSize='small' />;
            return null;
        })()}
    </Avatar>;
}

function MeasurementReportingFilters(props) {
    const {
        tablesView,
        fullRowView,
        selectedLocations,
        filterHandler,
        locationResources,
        sampleTypesSelected,
        measurementTypesSelected,
        types,
        aggregation,
        attribution,
        selectedMeasurePointTypes,
        selectedWaterSuppliers
    } = props;

    const [resourceTypes, setResourceTypes] = useState([]);
    const typology = useSelector(selectTypologyConf);
    const intl = useIntl();
    const biggerScreen = useMediaQuery(theme => theme.breakpoints.up("lg"));
    const samplingTypesConfig = useSelector(selectSamplingTypesConf);
    const superGroups = useSelector(selectSuperGroups);
    const locationGroups = useSelector(selectLocations);
    const locations = useSelector(selectMeasurePoints);
    const chartAggregation = [
        {
            group: 'NO_GROUP',
            time: null
        },
        {
            group: 'PER_HOUR',
            time: 'h'
        },
        {
            group: 'PER_DAY',
            time: 'd'
        },
        {
            group: 'PER_WEEK',
            time: 'w'
        }
    ]

    useEffect(() => {
        if (locationResources !== undefined && locationResources.length >= 0) setResourceTypes(types.filter(el => locationResources.includes(el.key)));
        else setResourceTypes(types);
    }, [locationResources, types, setResourceTypes]);

    return <Card>
        <CardContent>
            <Grid container className="filter-tile" justifyContent="space-between" spacing={2}>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={12} >
                    <Typography sx={{ pb: 1 }} variant="h6"><FormattedMessage id="DATE_SELECTED" /></Typography>
                    <DateRangeSelector column />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={12} className='filter-item location-select' sx={{ alignItems: 'space-between' }}>
                    <Typography sx={{ pb: 2 }} variant="h6"><FormattedMessage id="LOCATIONS" />:</Typography>

                    <Autocomplete
                        multiple
                        size="small"
                        limitTags={3}
                        disableCloseOnSelect
                        id="zone"
                        options={locationGroups}
                        getOptionLabel={(locationGroup) => `${locationGroup.name} (${superGroups.find(el => el._id === locationGroup.superGroupId)?.name}- ${superGroups.find(el => el._id === locationGroup.superGroupId)?.typeId} ) `}
                        renderInput={(params) => <TextField key={locationGroups.map(locationGroup => locationGroup._id)} {...params} label={<FormattedMessage id="ZONE" />} />}
                        value={selectedWaterSuppliers}
                        onChange={(e, value) => filterHandler(value, 'selectedWaterSuppliers')}
                        sx={{ mb: 2 }}
                        filterSelectedOptions
                        fullWidth
                    />

                    <LocationInputField
                        renderOptions={(options) => options.filter(el => selectedWaterSuppliers?.length ? selectedWaterSuppliers.map(el => el._id).includes(el.locationGroupId) : true)}
                        selectedLocation={selectedLocations}
                        setSelectedLocation={(location) => {
                            filterHandler(location.filter(mapedLocation => locations.map(filteredLocation => filteredLocation === mapedLocation)), 'locations')
                            // filterHandler([...new Set(location.map(loc => loc.measurePointType))], 'measurePointType')
                        }}
                        filterSelectedOptions
                        limitTags={3}
                        multiple
                    />

                    <Box className='filter-item' sx={{ pt: 2 }}>
                        <Typography variant="h6"><FormattedMessage id="SELECT_VIEW" /></Typography>
                        <Stack direction="column">
                            <FormControlLabel
                                control={
                                    <Tooltip
                                        title={<span>
                                            <FormattedMessage id="TOGGLE" />
                                            {tablesView ? <FormattedMessage id="GRAPH_VIEW" /> : <FormattedMessage id="TABLE_VIEW" />}
                                        </span>}
                                        arrow
                                    >
                                        <Switch
                                            icon={<SwitchIcon chart />}
                                            checkedIcon={<SwitchIcon table />}
                                            checked={tablesView}
                                            onChange={() => filterHandler(null, 'tables')}
                                            sx={{ height: 42 }}
                                        />
                                    </Tooltip>
                                }
                                label={<Typography variant="subtitle1" >
                                    {!tablesView ? <FormattedMessage id="GRAPH_VIEW" /> : <FormattedMessage id="TABLE_VIEW" />}
                                </Typography>}
                            />
                            {biggerScreen ?
                                <FormControlLabel
                                    control={
                                        <Switch
                                            icon={<SwitchIcon uncheck />}
                                            checkedIcon={<SwitchIcon check />}
                                            checked={fullRowView} onChange={() => filterHandler(false, 'fullRow')}
                                            sx={{ height: 42 }}
                                        />
                                    }
                                    label={<Typography variant="subtitle1"><FormattedMessage id={fullRowView ? "FULL_ROW" : "HALF_ROW"} /></Typography>}
                                /> : null
                            }
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                    <Typography sx={{ pb: 2 }} variant="h6"><FormattedMessage id="FILTERS" />:</Typography>
                    <Box className="aditional-filter-items">
                        <Box className="filter-item">
                            <Autocomplete
                                multiple
                                size="small"
                                limitTags={3}
                                disableCloseOnSelect
                                id="multiple-samples"
                                options={samplingTypesConfig}
                                getOptionLabel={(sample) => intl.formatMessage({ id: `SAMPLE_LOG.TYPE_${sample.value}` })}
                                value={sampleTypesSelected}
                                onChange={(e, sample) => filterHandler(sample.filter(mapedSamples => locations.map(filteredSample => filteredSample === mapedSamples)), 'samples')}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField {...params} key={samplingTypesConfig.map(type => type.key)} label={<FormattedMessage id="SAMPLE_LOG.TYPES" />} />
                                )}
                                fullWidth
                            />
                        </Box>
                        <Box className="filter-item">
                            <Autocomplete
                                multiple
                                size="small"
                                limitTags={3}
                                disableCloseOnSelect
                                id="multiple-limit-tags"
                                options={resourceTypes}
                                getOptionLabel={(resource) => `${intl.formatMessage({ id: resource.name })} (${intl.formatMessage({ id: "RESOURCE_TYPE.GROUP." + resource.resourceTypeGroupId.name })})`}
                                value={measurementTypesSelected}
                                onChange={(e, measurementType) => filterHandler(measurementType.filter(mapedMeasurementType => locations.map(filteredMeasurementType => filteredMeasurementType === mapedMeasurementType)), 'measurements')}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField {...params} key={types.map(type => type.name + type._id)} label={<FormattedMessage id="TYPE_OF_MEASUREMENTS" />} />
                                )}
                                renderTags={(options, getTagProps) => options.map((opt, index) => (<Chip key={index} {...getTagProps({ index })} size="small" label={<Box><DisplayResourceName resource={opt} />{` (${intl.formatMessage({ id: "RESOURCE_TYPE.GROUP." + opt.resourceTypeGroupId.name })})`}</Box>} />))}
                                renderOption={(props, option) => (<Box sx={{ display: 'flex', flexWrap: 'wrap' }}{...props}><DisplayResourceName resource={option} />&nbsp;{`(${intl.formatMessage({ id: "RESOURCE_TYPE.GROUP." + option.resourceTypeGroupId.name })})`}</Box>)}
                                sx={{ pt: 2 }}
                                fullWidth
                            />
                        </Box>
                        <Box>
                            <Autocomplete
                                multiple
                                size="small"
                                limitTags={3}
                                disableCloseOnSelect
                                id="measurePointType"
                                options={typology.map(type => type.key)}
                                getOptionLabel={(type) => intl.formatMessage({ id: "LOCATION_TYPE." + typology.find(mpType => mpType.key === type).value })}
                                renderInput={(params) => <TextField {...params} label={<FormattedMessage id="LOCATION.TYPE" />} />}
                                value={selectedMeasurePointTypes}
                                onChange={(e, value) => filterHandler(value, 'selectedMeasurePointTypes')}
                                sx={{ mt: 2 }}
                                filterSelectedOptions
                            />
                        </Box>
                        {!tablesView && <Box className="filter-item">
                            <Autocomplete
                                size="small"
                                id="single-limit-tags"
                                disableClearable
                                options={chartAggregation}
                                getOptionLabel={(aggreg) => intl.formatMessage({ id: "GROUPING.AGGREGATION." + aggreg.group })}
                                value={aggregation}
                                onChange={(e, value) => filterHandler(value, 'aggregation')}
                                isOptionEqualToValue={(option, value) => option.group === value.group}
                                renderInput={(params) => (
                                    <TextField {...params} key={aggregation.group} label={<FormattedMessage id="GROUPING.GROUP_BY" />} />
                                )}
                                sx={{ pt: 2 }}
                                fullWidth
                            />
                        </Box>}
                    </Box>
                </Grid>
            </Grid >
        </CardContent >
        {attribution && <Box sx={{ px: 2, pb: 1 }}>
            {attribution}
        </Box>}
    </Card>
}

export default MeasurementReportingFilters;