import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { IncidentDetails } from 'components'
import { Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Divider } from '@mui/material';
import { EditOutlined, PageviewOutlined, PreviewOutlined } from '@mui/icons-material';

const IncidentCard = ({ incident, mini, noEdit }) => {
    const navigate = useNavigate();

    return mini ? <>
        <IncidentDetails incident={incident} mini />
        <ButtonGroup sx={{ mt: 2 }}>
            <Button size="small" onClick={() => navigate("/incidents/details/" + incident._id)} startIcon={<PreviewOutlined />}><FormattedMessage id="INCIDENT.VIEW_DETAILS" /></Button>
            {(!noEdit || !incident.archived) && <Button size="small" onClick={() => navigate("/incidents/edit/" + incident._id)} startIcon={<EditOutlined />}><FormattedMessage id="EDIT" /></Button>}
        </ButtonGroup>
    </> :
        <Card>
            <CardHeader title={<FormattedMessage id="INCIDENT.DETAILS" />} />
            <CardContent>
                <IncidentDetails incident={incident} mini={mini} />
            </CardContent>
            <Divider />
            <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {!incident.archived && <Button size="small" startIcon={<EditOutlined />} onClick={() => navigate("/incidents/edit/" + incident._id)}><FormattedMessage id="EDIT" /></Button>}
                <Button size="small" startIcon={<PageviewOutlined />} onClick={() => navigate("/incidents")}><FormattedMessage id="VIEW_ALL" /></Button>
            </CardActions>
        </Card>
}

export default IncidentCard;