import { Card, IconButton, Stack, InputBase, ToggleButtonGroup, ToggleButton, Tooltip, Box } from "@mui/material";
import { LocalDrinkOutlined, Search, ShareLocationOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "@emotion/react";



export default function SearchBar(props) {
    const { search, setSearch, icon, noSubmit, additionalFilters, filters, setFilters, action } = props;
    const [localValue, setLocalValue] = useState(search);
    const intl = useIntl();
    const theme = useTheme();

    const handleSearch = (value) => {
        if (typeof setSearch === 'function') setSearch(value || localValue);
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSearch();
        if (e.key === 'Escape') {
            setLocalValue("");
            handleSearch("");
        }
    }
    const handleChange = (e) => {
        setLocalValue(e.target.value);
        if (noSubmit) handleSearch(e.target.value)
    }

    useEffect(() => {
        if (!localValue?.length && typeof setSearch === 'function') setSearch(null);
    }, [localValue, setSearch])


    return <Card sx={{ pl: 1 }}>
        <Stack direction="row">
            <Box alignSelf="center">
                {icon}
            </Box>
            <InputBase
                value={localValue}
                sx={{ flex: 1, m: (!icon || noSubmit ? 0.5 : 0), ml: (icon ? 1 : 0.5) }}
                onKeyDown={handleKeyPress}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: "SEARCH_PLACEHOLDER." + (noSubmit ? "NO_SUBMIT" : "SUBMIT") })}
                inputProps={{ 'aria-label': 'search' }}
            />
            {additionalFilters && <ToggleButtonGroup
                exclusive
                size="medium"
                aria-label="text formatting"
                value={filters}
                onChange={(e, data) => setFilters(data || [])}
            >
                <ToggleButton key="zone" value="zone" style={{ borderRadius: 0 }} sx={filters.includes("zone") ? { bgcolor: theme.palette.primary.main } : null}>
                    <Tooltip title={<FormattedMessage id="ZONE" />} arrow><ShareLocationOutlined sx={filters.includes("zone") ? { color: theme.palette.primary.contrastText } : null} /></Tooltip>
                </ToggleButton>
                <ToggleButton key="waterPump" value="waterPump" style={{ borderRadius: 0 }} sx={filters.includes("waterPump") ? { bgcolor: theme.palette.primary.main } : null}>
                    <Tooltip title={<FormattedMessage id="PUMPING_AREA" />} arrow><LocalDrinkOutlined sx={filters.includes("waterPump") ? { color: theme.palette.primary.contrastText } : null} /></Tooltip>
                </ToggleButton>
            </ToggleButtonGroup>}
            {!noSubmit && <IconButton sx={{ alignSelf: 'center' }} onClick={handleSearch} type="submit" aria-label="search">
                <Search color="primary" />
            </IconButton>}
            {Boolean(action) && action}
        </Stack>
    </Card>
}