import { useMediaQuery, Divider, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DialogFormTitle } from 'components';
import ThresholdFormContent from './ThresholdFormContent';
import { useSelector } from 'react-redux';
import { selectResourcesTypesConf, selectDerogationTypesConf, selectPermissionsByKey } from 'redux/configurationSlice';
import { selectLocations } from 'redux/locationsSlice';
import { localISOString } from 'utils';
import API from 'api';
import { userSelector } from 'redux/userSlice';

const ThresholdForm = ({ open, onClose, existingThreshold, setAlert, setReset }) => {
    const getLastYearDate = () => {
        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() - 1);
        return currentDate.getTime();
    };

    const smallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [selectedMeasurementType, setSelectedMeasurementType] = useState(null);
    const [waterSupplier, setWaterSupplier] = useState(null);
    const [minimum, setMinimum] = useState("");
    const [maximum, setMaximum] = useState("");
    const [description, setDescription] = useState("");
    const [note, setNote] = useState('');
    const [dateFrom, setDateFrom] = useState(getLastYearDate());
    const [dateTo, setDateTo] = useState(Date.now());
    const [derogation, setDerogation] = useState(true);
    const resourceTypes = useSelector(selectResourcesTypesConf);
    const locationGroups = useSelector(selectLocations);
    const [derogationType, setDerogationType] = useState(null);
    const derogationTypes = useSelector(selectDerogationTypesConf);
    const { token } = useSelector(userSelector);
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);

    const permissionList = useSelector(selectPermissionsByKey);
    const createThreshold = permissionList && permissionList['create-threshold']?.length > 0;
    const updateThreshold = permissionList && permissionList['update-threshold']?.length > 0;
    const createDerogation = permissionList && permissionList['create-derogation']?.length > 0;
    const updateDerogation = permissionList && permissionList['update-derogation']?.length > 0;

    const handleClose = () => {
        setSelectedMeasurementType(null);
        setWaterSupplier(null);
        setMinimum("");
        setMaximum("");
        setDescription("");
        setNote("");
        setDerogation(true);
        setDerogationType(null);
        setDateFrom(getLastYearDate());
        setDateTo(Date.now());
        onClose();
    }

    const handleSubmit = () => {
        setDisabledSubmitButton(true);
        const newThresholdObject = {
            resourceTypeKey: selectedMeasurementType?.key,
            qLevel: 3,
            type: "COMPLIANCE",
            ...(minimum !== "" && { min: minimum }),
            ...(maximum !== "" && { max: maximum }),
            ...(derogation && {
                derogation: {
                    dateRange: {
                        startDate: localISOString(dateFrom),
                        endDate: localISOString(dateTo),
                    },
                    locationId: waterSupplier?._id,
                    description: description,
                    note: note,
                    type: derogationType.key,
                }
            })
        }
        
        if (existingThreshold) {
            if (updateThreshold) {
                API.thresholds.updateThreshold(token, existingThreshold._id, newThresholdObject).then(response => {
                    if (response.data) {
                        handleClose();
                        setAlert({ open: true, messageId: "APP.SUCCESS", severity: "success" });
                        setReset(reset => reset + 1);
                    }
                })
                    .catch(error => {
                        setAlert({ open: true, messageId: error?.data?.id || "APP.ERROR", severity: "error" });
                        console.error(error);
                        setDisabledSubmitButton(false);
                    })
            }
            else if (updateDerogation) {
                API.thresholds.updateDerogation(token, existingThreshold._id, newThresholdObject).then(response => {
                    if (response.data) {
                        handleClose();
                        setAlert({ open: true, messageId: "APP.SUCCESS", severity: "success" });
                        setReset(reset => reset + 1);
                    }
                })
                    .catch(error => {
                        setAlert({ open: true, messageId: error?.data?.id || "APP.ERROR", severity: "error" });
                        console.error(error);
                        setDisabledSubmitButton(false);
                    })
            }
        }
        else {
            if (createThreshold) {
                API.thresholds.postThreshold(token, newThresholdObject).then(response => {
                    if (response.data) {
                        handleClose();
                        setAlert({ open: true, messageId: "APP.SUCCESS", severity: "success" });
                        setReset(reset => reset + 1);
                    }
                })
                    .catch(error => {
                        setAlert({ open: true, messageId: error?.data?.id || "APP.ERROR", severity: "error" });
                        console.error(error);
                        setDisabledSubmitButton(false);
                    })
            }
            else if (createDerogation) {
                API.thresholds.postDerogation(token, newThresholdObject).then(response => {
                    if (response.data) {
                        handleClose();
                        setAlert({ open: true, messageId: "APP.SUCCESS", severity: "success" });
                        setReset(reset => reset + 1);
                    }
                })
                    .catch(error => {
                        setAlert({ open: true, messageId: error?.data?.id || "APP.ERROR", severity: "error" });
                        console.error(error);
                        setDisabledSubmitButton(false);
                    })
            }
        }
    }

    useEffect(() => {
        if (existingThreshold) {
            setSelectedMeasurementType(resourceTypes.find(resType => resType.key === existingThreshold.resourceTypeKey));
            setMinimum(existingThreshold.minimumAsNumber !== undefined ? existingThreshold.minimumAsNumber : "");
            setMaximum(existingThreshold.maximumAsNumber !== undefined ? existingThreshold.maximumAsNumber : "");
            setDerogation(!!existingThreshold.derogation);
            if (existingThreshold.derogation) {
                setDerogationType(derogationTypes.find(type => type.key === existingThreshold.derogation.type) || null);
                setDescription(existingThreshold.derogation.description);
                setWaterSupplier(locationGroups.find(loc => loc._id === existingThreshold.derogation.locationId) || null);
                setDateFrom(new Date(existingThreshold.derogation.dateRange.startDate).getTime());
                setDateTo(new Date(existingThreshold.derogation.dateRange.endDate).getTime());
                setNote(existingThreshold.derogation.note);
            }
            else {
                setDerogationType(null);
                setDescription("");
                setNote("");
                setWaterSupplier(null);
                setDateFrom(getLastYearDate());
                setDateTo(Date.now());
            }
        }
        else {
            setSelectedMeasurementType(null);
            setWaterSupplier(null);
            setMinimum("");
            setMaximum("");
            setDescription("");
            setNote("");
            setDateFrom(getLastYearDate());
            setDateTo(Date.now());
            setDerogation(true);
            setDerogationType(null);
        }
    }, [existingThreshold, open, locationGroups, resourceTypes, derogationTypes])

    useEffect(() => {
        const newThresholdObject = {
            resourceTypeKey: selectedMeasurementType?.key,
            ...(minimum !== "" && { min: minimum.toString() }),
            ...(maximum !== "" && { max: maximum.toString() }),
            ...(derogation && {
                derogation: {
                    dateRange: {
                        startDate: new Date(dateFrom).getTime(),
                        endDate: new Date(dateTo).getTime(),
                    },
                    locationId: waterSupplier?._id,
                    description: description,
                    note: note,
                    type: derogationType?.key
                }
            })
        }
        const oldThresholdObject = {
            resourceTypeKey: resourceTypes.find(resType => resType.key === existingThreshold?.resourceTypeKey)?.key,
            ...(existingThreshold?.minimumAsNumber !== undefined && { min: existingThreshold?.minimumAsNumber.toString() }),
            ...(existingThreshold?.maximumAsNumber !== undefined && { max: existingThreshold?.maximumAsNumber.toString() }),
            ...(existingThreshold?.derogation && {
                derogation: {
                    dateRange: {
                        startDate: new Date(existingThreshold?.derogation?.dateRange?.startDate)?.getTime(),
                        endDate: new Date(existingThreshold?.derogation?.dateRange?.endDate)?.getTime(),
                    },
                    locationId: locationGroups.find(loc => loc._id === existingThreshold?.derogation?.locationId)?._id,
                    description: existingThreshold?.derogation?.description,
                    note: existingThreshold?.derogation?.note,
                    type: derogationTypes.find(type => type.key === existingThreshold?.derogation?.type)?.key
                }
            })
        }

        if (!selectedMeasurementType ||
            (minimum === "" && maximum === "") || (minimum !== "" && maximum !== "" && (Number(minimum) > Number(maximum))) ||
            (derogation && (!derogationType || !waterSupplier)) ||
            (derogation && (new Date(dateFrom).toString() === 'Invalid Date' || new Date(dateTo).toString() === 'Invalid Date')) ||
            (existingThreshold && JSON.stringify(oldThresholdObject) === JSON.stringify(newThresholdObject))) setDisabledSubmitButton(true);
        else setDisabledSubmitButton(false);

    }, [derogation, derogationType, minimum, maximum, selectedMeasurementType, waterSupplier, dateFrom, dateTo, description, existingThreshold, derogationTypes, locationGroups, resourceTypes, note])

    return <>
        <Dialog open={open} maxWidth="md" fullWidth fullScreen={smallScreen} >
            <DialogFormTitle
                mainTitle={<FormattedMessage id={existingThreshold ? "THRESHOLD.EDIT" : "THRESHOLD.ADD"} />}
            />
            <Divider />
            <DialogContent>
                <ThresholdFormContent minimum={minimum} setMinimum={setMinimum} maximum={maximum} setMaximum={setMaximum}
                    selectedMeasurementType={selectedMeasurementType} setSelectedMeasurementType={setSelectedMeasurementType}
                    description={description} setDescription={setDescription} waterSupplier={waterSupplier} setWaterSupplier={setWaterSupplier}
                    derogation={derogation} setDerogation={setDerogation} dateFrom={dateFrom} setDateFrom={setDateFrom}
                    dateTo={dateTo} setDateTo={setDateTo} derogationType={derogationType} setDerogationType={setDerogationType} 
                    note={note} setNote={setNote}
                />
            
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={() => handleClose()} color="warning"><FormattedMessage id="CANCEL" /></Button>
                <Button disabled={disabledSubmitButton || (existingThreshold && !updateThreshold && !updateDerogation) || (!existingThreshold && !createThreshold && !createDerogation) || (!derogation && existingThreshold && !updateThreshold) || (!derogation && !existingThreshold && !createThreshold)}
                    onClick={() => handleSubmit()}><FormattedMessage id={existingThreshold ? "EDIT" : "ADD"} /></Button>
            </DialogActions>

        </Dialog>
    </>


}

export default ThresholdForm;