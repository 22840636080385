import 'components/mapElements/mapElements.css';
import { useEffect, useRef, useState, useCallback } from 'react';
import Overlay from 'ol/Overlay';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/userSlice';
import { selectMapProps } from 'redux/mapSlice';
import { ComplianceTag } from 'components';
import { Avatar, Box, Stack, Chip, Collapse, Card, CardActions, CardMedia, CardHeader, CardContent, Typography, useTheme, IconButton, Button } from '@mui/material';
import { SensorsOutlined, Close, PushPin, Villa, HomeWork, AddHomeWork, DeleteOutlined, ImageOutlined, LayersOutlined, HideImageOutlined, DomainOutlined } from '@mui/icons-material';
import API from 'api';
import { formatDateLocale } from 'utils';


export default function MapPopup(props) {
    const { theMap, onDelete, disabled } = props;
    const mapProps = useSelector(selectMapProps);
    const { isSuccess: loggedIn, token } = useSelector(userSelector);
    const contentRef = useRef();
    const popupRef = useRef();
    const navigate = useNavigate();
    const [element, setElement] = useState({});
    const [expanded, setExpanded] = useState(false);
    const [popup, setPopup] = useState(new Overlay({}));
    const theme = useTheme();
    const intl = useIntl();

    const handleNavigate = () => {
        if (!element.hasLink) return null;

        const groupId = element.locationGroupId || element._id;
        const locationId = element.locationGroupId ? element._id : null;

        if (loggedIn && locationId) return navigate(`/locations/details/${locationId}`);
        else return navigate(`/locations/group/${groupId}`);
    }

    useEffect(() => {
        const clickCB = (event) => {
            if (!(event.originalEvent.srcElement instanceof HTMLCanvasElement) || disabled) return;

            const feature = theMap.forEachFeatureAtPixel(event.pixel, (feature) => {
                return feature;
            });

            // dismiss popup on click
            if (!feature && popup.getPosition() !== undefined) popup.setPosition(undefined);

            const defaultElementProps = {
                hasLink: true,
                hasDelete: false,
                viewPicture: false
                /*name?,
                  title,
                  subheader - formattedMessage,
                  subtitle?, 
                  type?,
                  picture?,
                  description? - {
                      en: '',
                      hr: ''
                  }*/
            }
            const zoomLevel = theMap.getView().getZoom() <= mapProps.zoomThreshold;

            // clicking on geoFeature, set to display basic properties, map centers on clicked point, zoom in if above map threshold
            if (feature && contentRef.current && (feature.getGeometry().getType() === 'Polygon' || feature.getGeometry().getType() === 'MultiPolygon')) {
                theMap.getView().animate({ center: event.coordinate, duration: 500 }, zoomLevel && { zoom: parseInt(mapProps.zoomThreshold), duration: 500 });
                theMap.getOverlayById('info').setPosition(event.coordinate);
                const properties = feature.getProperties();
                if (properties.NA_IME || properties.JLS_IME || properties.ZU_IME) {
                    setElement({ type: -2, ...defaultElementProps, hasLink: false, description: { en: properties.ZU_IME, hr: properties.ZU_IME }, subtitle: properties.JLS_IME, name: properties.NA_IME })
                }
                else setElement({ type: -1, ...defaultElementProps, hasLink: false, description: { en: properties.imenazivst, hr: properties.imenazivst }, subtitle: formatDateLocale(properties.godina), name: properties.zona })
            }
            // when location pins are displayed on map (above zoom threshold), and feature is point (not connection line), map centers on clicked point
            else if (feature && !zoomLevel && contentRef.current && feature.getGeometry().getType() === 'Point') {
                theMap.getOverlayById('info').setPosition(event.coordinate);
                theMap.getView().animate({ center: event.coordinate, duration: 300 });
                const poiElementProps = {
                    hasLink: false,
                    hasDelete: true,
                    viewPicture: true
                }
                const properties = feature.getProperties();
                setElement({ ...(properties.type === "POI" ? poiElementProps : defaultElementProps), subheader: properties.type, ...properties.object })
            }
            // clicking on cluster, map centers and zooms in on that cluster
            else if (feature && zoomLevel) {
                theMap.getView().animate({ center: event.coordinate, duration: 500 }, { zoom: parseInt(mapProps.zoomThreshold) + 1, duration: 500 });
            }
        }
        theMap.on('click', clickCB);
        return () => theMap.un('click', clickCB);
    }, [disabled, theMap, popup, mapProps])

    useEffect(() => {
        setPopup(new Overlay({
            id: 'info',
            element: popupRef.current,
            positioning: 'bottom-center',
            stopEvent: false,
            offset: [9, 9],
        }));

        return () => setPopup(new Overlay({}));
    }, [theMap, mapProps.zoomThreshold])

    useEffect(() => {
        theMap.addOverlay(popup);
        return () => theMap.removeOverlay(popup);
    }, [disabled, popup, theMap]);

    const renderAvatar = useCallback(() => {
        switch (Number(element.type)) {
            // geoFeature
            case -2: // settlements
                return <DomainOutlined />;
            case -1: // supply zone
                return <LayersOutlined fontSize="large" />;
            // points of interest
            case 1:
                return <Villa fontSize="large" />;
            case 2:
                return <HomeWork fontSize="large" />;
            case 3:
                return <AddHomeWork fontSize="large" />;
            case 0:
                return <PushPin fontSize="large" />;
            // locations
            default:
                return <SensorsOutlined fontSize="large" />;
        }

    }, [element]);

    const onDeleteHandler = () => {
        onClose();
        API.poi.deletePointOfInterest(token, element._id).then(() => {
            onDelete(element._id);
        }).catch((error) => {
            console.error("Error deleting point of interest, reason: ", error);
        })
    }

    const onClose = () => {
        setExpanded(false);
        popup.setPosition(undefined);
    }

    return <>
        <Card id="popup" className={window.innerHeight > window.innerWidth ? 'popup-vertical' : 'popup-horizontal'} ref={popupRef}
            style={{ ...(window.innerHeight > window.innerWidth ? { maxWidth: '68vw' } : { maxHeight: '68vh' }), display: disabled ? 'none' : 'inherit', cursor: 'default' }}>
            <CardHeader id="popup-content" ref={contentRef}
                avatar={
                    <Avatar
                        style={{ backgroundColor: theme.palette.primary.light, width: '42px', height: '42px' }}
                        alt={element.name || 'pin-avatar'}
                        src={element.picture ? process.env.REACT_APP_API_BASE_URL + '/' + element.picture : undefined}
                    >
                        {!element.picture && renderAvatar()}
                    </Avatar>
                }
                title={
                    <Box
                        sx={{
                            display: 'flex',
                            cursor: element.hasLink ? 'pointer' : 'inherit',
                            fontWeight: 'bold',
                            '&:hover': {
                                color: element.hasLink ? theme.palette.primary.main : "default"
                            },
                            mr: 4
                        }}
                    >
                        <span onClick={handleNavigate}>{element.name}</span>
                    </Box>
                }
                subheader={element.subheader ?
                    <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="flex-start" spacing={0.5} >
                        {element.locationGroupId && <ComplianceTag locationId={element._id} />}
                        <Chip size="small" label={<FormattedMessage id={element.subheader} />} />
                    </Stack>
                    : element.subtitle
                }
                action={[element.hasDelete ? <IconButton key="delete-poi" onClick={onDeleteHandler}>
                    <DeleteOutlined />
                </IconButton> : null,
                <IconButton key="close" onClick={onClose}>
                    <Close />
                </IconButton>]}
                titleTypographyProps={{ variant: 'h6' }} />
            {element.description && <CardContent sx={{ pt: 0, maxWidth: '380px', display: element.description[intl.locale] ? 'inherit' : 'none' }}>
                <Typography variant="caption" style={window.innerHeight < window.innerWidth && window.innerHeight < '415px' ? { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' } : null}>
                    {element.description[intl.locale]}
                </Typography>
            </CardContent>}
            {element.viewPicture && element.picture && <>
                <Collapse in={!expanded} timeout="auto" unmountOnExit>
                    <CardActions sx={{ mt: 0, pt: 0 }}>
                        <Button color="primary" onClick={() => setExpanded(true)} startIcon={<ImageOutlined fontSize="small" />}>
                            <FormattedMessage id="VIEW_PICTURE" />
                        </Button>
                    </CardActions>
                </Collapse>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardMedia
                        sx={{ minHeight: 180, mx: 1 }}
                        style={{ objectFit: "contain" }}
                        image={process.env.REACT_APP_API_BASE_URL + '/' + element.picture}
                        title={intl.formatMessage({ id: "PICTURE" })}
                    />
                    <CardActions sx={{ pt: 2 }}>
                        <Button color="primary" onClick={() => setExpanded(false)} startIcon={<HideImageOutlined fontSize="small" />}>
                            <FormattedMessage id="CLOSE" />
                        </Button>
                    </CardActions>
                </Collapse>
            </>}
        </Card>
    </>;
}